import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DarknetSearchOptions } from 'common/components/SearchComponent/data';

import ReadMore from 'common/components/ReadMore/ReadMore';
import UserIcon from 'common/Icons/UserIcon';
import ExportButton from 'common/components/Export/ExportButton';

import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import NoResult from 'common/components/NoResult/NoResult';
import Loading from 'common/components/Loading/Loading';

import { search as searchSocialNetwork } from 'store/slices/search/live/socialNetwork';

import SearchTipFeedback from '../../SearchTipFeedback';

const SocialNetwroks = ({ isResultView = false, showTip = true }) => {
  const dispatch = useDispatch();

  const optionIndex = 2;
  const searchOptionLabel = DarknetSearchOptions[optionIndex].label;

  const {
    query,
    progress,
    list,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
    pageLoading,
    error,
  } = useSelector((state) => state.search.live.socialNetwork);

  const setCurrentPage = (page) => {
    dispatch(searchSocialNetwork({ page: page - 1 }));
  };

  // useEffect(() => {
  //   if (error) {
  //     useErrorbar(error);
  //   }
  // }, [error]);

  const columns = [
    {
      Header: 'User',
      accessor: 'userName',
      render: (_, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <a href={record.userProfileLink} target="_blank">
              <span className="tw-whitespace-nowrap tw-text-[#00B6FF]">
                {record.userName || record.userAlias}
              </span>
            </a>
            <span>{record.createdAt}</span>
            {record.userImageLink === 'self' || !record.userImageLink ? (
              <UserIcon />
            ) : (
              <div>
                <img
                  src={record.userImageLink}
                  className="tw-rounded-[49px] tw-w-[40px] tw-h-[40px]"
                  alt=""
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Details',
      accessor: 'post',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-gap-1 tw-mb-[10px]">
              {record.type
                .toLowerCase()
                .split(',')
                .map((keyword, index) => {
                  return (
                    <span
                      className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]"
                      key={index}
                    >
                      {keyword}
                    </span>
                  );
                })}
            </div>
            <ReadMore text={record.post} size={200} />
            <div className="tw-whitespace-normal tw-break-all tw-mt-[10px] tw-text-[#00B6FF]">{record.link}</div>
          </>
        );
      },
    },
    {
      Header: 'Sources',
      accessor: 'type',
      render: (value) => <span className=" tw-text-[#44464A]">{value.toLowerCase()}</span>,
    },
    {
      Header: 'Languages',
      accessor: 'cvssScore',
      render: (value) => {
        if (value <= 2.5) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <p className="tw-text-[16px]">&#x1F642;</p> {/* &#x1F60A; */}
              <span className="tw-whitespace-nowrap tw-bg-green-50 tw-border tw-border-green-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-green-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        } else if (value <= 5.0) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <p className="tw-text-[16px]">&#x1F610;</p>
              <span className="tw-whitespace-nowrap tw-bg-amber-50 tw-border tw-border-amber-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-amber-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        } else if (value <= 7.5) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <p className="tw-text-[16px]">&#x1F641;</p>
              <span className="tw-whitespace-nowrap tw-bg-red-50 tw-border tw-border-red-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-red-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        }
      },
    },
  ];

  if (loading) {
    return (
      <Loading
        label="Please wait while the search is in progress..."
        size="large"
        showProgress={true}
        progress={progress}
      />
    );
  } else {
    if (!isResultView && list.length === 0) {
      return <NoResult />;
    }
  }

  return (
    <>
      {showTip && (
        <div className="tw-mb-4">
          <SearchTipFeedback optionIndex={optionIndex} />
        </div>
      )}

      {/* NORMAL Table */}
      <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-mt-6">
        <div
          className={'tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4 tw-col-span-4'}
        >
          <h1 className="tw-text-lg tw-font-bold">{`${searchOptionLabel} Search Result`}</h1>

          <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-z-20">
            <div className="tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">Search results</span>
                <span className="tw-text-sm tw-font-medium">{totalElements}</span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">Search term</span>
                <span className="tw-text-sm tw-font-medium">{query}</span>
              </div>
            </div>
            <ExportButton />
          </div>

          <div className="tw-relative">
            <Table
              data={list}
              columns={columns}
              selectable={true}
              // onRowSelection={handleRowSelection}
            />
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
              <div className={loading ? 'tw-invisible' : 'tw-visible'}>
                <p className="tw-my-4">
                  Showing mentions: {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                  {currentPage * size > totalElements ? totalElements : currentPage * size} of{' '}
                  {totalElements}
                </p>
              </div>

              {totalPages > 1 && (
                <Paginator
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              )}
            </div>
            {pageLoading && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialNetwroks;
