import React, { useState } from 'react';

export default function ReadMore({
  size = 150,
  text,
  textStyling = 'tw-whitespace-normal tw-text-sm tw-font-normal tw-leading-6 tw-text-[#44464A]',
  readStyling = 'tw-text-sm tw-font-medium tw-leading-6 tw-text-[#00B6FF]',
  maxLength = 1000,
}) {
  const [isReadMore, setIsReadMore] = useState(true);

  return (
    <div>
      {text.length <= size ? (
        <div className={textStyling}>{text}</div>
      ) : (
        <div className={textStyling}>
          {isReadMore ? text.slice(0, size) + '...' : text}{' '}
          {size < maxLength && (
            <span
              onClick={() => setIsReadMore(!isReadMore)}
              className={`tw-ms-1 tw-cursor-pointer ${readStyling}`}
            >
              {isReadMore ? 'Read more' : 'Read less'}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
