import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BellYellow from 'common/Icons/BellYellow';

import ProgressBar from 'common/components/ProgressBar/ProgressBar';
import CustomButton from 'common/components/Button/Button';

import { DatabaseSearchOptions } from 'common/components/SearchComponent/data';
import { searchActions } from 'store/actions';
import {
  setSelectedOption as setSelectedOptionId,
  setCurrentSearchParams,
  setLastSearchParams,
  setSearching,
} from 'store/slices/search/database/main';
import { search as searchDataLeaks } from 'store/slices/search/database/dataLeaks';
import { search as searchStealerLogs } from 'store/slices/search/database/stealerLogs';
import { search as searchURLShortener } from 'store/slices/search/database/urlShortener';
import { search as searchCloudStorage } from 'store/slices/search/database/cloudStorage';
import { search as searchSSLLogs } from 'store/slices/search/database/sslLogs';
import { search as searchDataPastes } from 'store/slices/search/database/dataPastes';
import { search as searchRansomware } from 'store/slices/search/database/ransomware';
import { search as searchDataBrokers } from 'store/slices/search/database/dataBrokers';
import { search as searchPhishingAttack } from 'store/slices/search/database/phishingAttack';
import { search as searchFakeApps } from 'store/slices/search/database/fakeApps';
import { search as searchCreditCard } from 'store/slices/search/database/creditCard';
import { search as searchCreditCardLeaks } from 'store/slices/search/database/creditCardLeaks';

import { useSnackbar as showToast } from 'utils/snackbar';

import SearchComponent from './SearchComponent';
import SearchResult from './SearchResult';

const DatabaseSearch = () => {
  const dispatch = useDispatch();
  const licenseInfo = useSelector((state) => state.user.license);
  const searchDatabase = useSelector((state) => state.search.database);
  const {
    selectedOptionId,
    currentOptionId,
    currentQuery,
    lastSearchOptionId,
    isSearching,
  } = searchDatabase.main;

  const features = [
    {
      id: 1,
      premium: false,
      label: 'Data Leaks',
      subLabel:
        'Monitor and track data leaks, including SQL dumps, documents, credentials, and more, that are shared for free on the dark web',
      licenseTitle: 'Leak & Credential Monitoring',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      get store() {
        return selectedOption && selectedOption.label === 'Credit Card'
          ? searchDatabase.creditCardLeaks
          : searchDatabase.dataLeaks;
      },
      get searchFunc() {
        const option = showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return option.label === 'Credit Card'
          ? searchCreditCardLeaks
          : searchDataLeaks;
      },
      progress: 0,
      except: [5],
    },
    {
      id: 2,
      premium: false,
      label: 'Stealer Logs',
      subLabel:
        'Analyze logs from stealer malware-infected systems to uncover browser histories, saved credentials, and session cookies',
      licenseTitle: 'Stealer & Botnet Monitoring',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.stealerLogs,
      searchFunc: searchStealerLogs,
      progress: 0,
      except: [2, 5, 6, 7, 8],
    },
    {
      id: 3,
      premium: false,
      label: 'URL Shorteners',
      subLabel:
        'Detect shortened URLs created by employees that could expose sensitive data, such as links to Dropbox or Google Drive',
      licenseTitle: 'URL Shortener Monitoring',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.urlShortener,
      searchFunc: searchURLShortener,
      progress: 0,
      except: [2, 3, 4, 5, 6, 7, 8],
    },
    {
      id: 4,
      premium: false,
      label: 'Open Cloud Storage',
      subLabel:
        'Scan public cloud storage services, such as S3 buckets and Azure containers, to detect publicly accessible and improperly secured sensitive data',
      licenseTitle: 'Open Cloud Container Exposure Detection',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.cloudStorage,
      searchFunc: searchCloudStorage,
      progress: 0,
      except: [2, 3, 4, 5, 6, 7],
    },
    {
      id: 5,
      premium: false,
      label: 'SSL Logs',
      subLabel:
        'Monitor SSL transparency logs to detect if your primary domain is being used in potentially malicious websites or unauthorized SSL certificates',
      licenseTitle: 'SSL Logs',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.sslLogs,
      searchFunc: searchSSLLogs,
      progress: 0,
      except: [2, 3, 4, 5, 7, 8, 9],
    },
    {
      id: 6,
      premium: true,
      label: 'Data Pastes',
      subLabel:
        'Search for mentions of your organization or sensitive data across popular paste sites, either directly or through Google scraping',
      licenseTitle: 'Paste Site Monitoring',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.dataPastes,
      searchFunc: searchDataPastes,
      progress: 0,
      except: [5],
    },
    {
      id: 7,
      premium: true,
      label: 'Threat Actor Publications',
      subLabel:
        'Our tool continuously scans ransomware group leak sites to detect mentions of your company name or domain',
      licenseTitle: 'Ransomware Tracking',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.ransomware,
      searchFunc: searchRansomware,
      progress: 0,
      except: [2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      id: 8,
      premium: true,
      label: 'Data Brokers',
      subLabel:
        'Track the sale and exchange of personally identifiable information (PII) and compromised credentials on dark web forums and marketplaces',
      licenseTitle: 'PII (Personally Identifiable Information) Monitoring',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.dataBrokers,
      searchFunc: searchDataBrokers,
      progress: 0,
      except: [5, 7, 8],
    },
    {
      id: 9,
      premium: true,
      label: 'Phishing Attacks',
      subLabel:
        'Uncover instances where your domain is listed in directories of webpages used in phishing campaigns to deceive users',
      licenseTitle: 'Phishtank Monitoring',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.phishingAttack,
      searchFunc: searchPhishingAttack,
      progress: 0,
      except: [2, 3, 4, 5, 7, 8, 9],
    },
    {
      id: 10,
      premium: true,
      label: 'Fake Apps',
      subLabel:
        'Identify fake apps with similar names to your organization or brand in official app stores like iTunes and Google Play',
      licenseTitle: 'App Store Spoofing Detection',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.fakeApps,
      searchFunc: searchFakeApps,
      progress: 0,
      except: [2, 3, 4, 5, 7, 8, 9],
    },
    {
      id: 11,
      premium: true,
      label: 'Credit Card Dumps',
      subLabel:
        'Identify free credit card dumps or stolen credit card data actively sold on darknet marketplaces',
      licenseTitle: 'Stolen Credit Card Tracking',
      get active() {
        const option = isSearching
          ? DatabaseSearchOptions[currentOptionId]
          : showResult
          ? DatabaseSearchOptions[lastSearchOptionId]
          : selectedOption;
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          option &&
          !this.except.includes(option.id)
        );
      },
      store: searchDatabase.creditCard,
      searchFunc: searchCreditCard,
      progress: 0,
      except: [1, 2, 4, 6, 7, 8, 9],
    },
  ];

  // const [autoSearch, setAutoSearch] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    DatabaseSearchOptions[selectedOptionId],
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [resultActiveTabId, setResultActiveTabId] = useState(null);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    // Parse the params
    // const type = searchParams.get('type');
    // const query = searchParams.get('query');
    // if (type && query) {
    //   dispatch(
    //     setSelectedOptionId(
    //       DatabaseSearchOptions.findIndex((option) => option.id == type),
    //     ),
    //   );
    //   dispatch(setQueryMain(query));

    //   setAutoSearch(true);
    // }

    if (isSearching) {
      setSearchTerm(currentQuery);
    } else {
      setSearchTerm('');
      dispatch(searchActions.resetProgress('Database'));
    }

    setShowResult(false);
  }, [dispatch]);

  useEffect(() => {
    setSelectedOption(DatabaseSearchOptions[selectedOptionId]);
  }, [selectedOptionId]);

  // useEffect(() => {
  //   if (autoSearch) {
  //     handleSearch(searchTerm);
  //   }
  // }, [autoSearch]);

  useEffect(() => {
    if (isSearching) {
      let finished = true;

      features.forEach((item) => {
        if (item.active && item.store) {
          finished &= !item.store.loading;
        }
      });

      if (finished) {
        showToast(
          `${DatabaseSearchOptions[currentOptionId].label} search has been successfully completed.`,
        );
        dispatch(setSearching(false));
        dispatch(searchActions.resetProgress('Database'));
        dispatch(setLastSearchParams());

        if (!showResult) {
          setResultActiveTabId(null);
          setShowResult(true);
        }
      }
    }
  }, [searchDatabase]);

  const handleOptionChange = (option) => {
    dispatch(
      setSelectedOptionId(
        DatabaseSearchOptions.findIndex((_option) => _option.id === option.id),
      ),
    );

    if (!isSearching) {
      setSearchTerm('');
      dispatch(searchActions.resetProgress('Database'));
    }
  };

  // Ref to store the AbortController instance
  const abortControllerRef = useRef(null);

  const handleSearch = (query, fileExtension) => {
    dispatch(searchActions.resetProgress('Database'));

    setSearchTerm(query);

    // Abort the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;

    // dispatch(
    //   searchDataLeaks({ page: 0, query, signal: newAbortController.signal }),
    // );

    features.forEach((feature) => {
      if (feature.active) {
        if (feature.searchFunc) {
          // Search
          dispatch(
            feature.searchFunc({
              page: 0,
              query,
              fileExt: fileExtension,
              signal: newAbortController.signal,
            }),
          );
        }
      }
    });

    dispatch(
      setCurrentSearchParams({
        optionId: selectedOptionId,
        query: searchTerm,
      }),
    );

    dispatch(setSearching(true));
  };

  const navigateSearchResult = (featureId) => {
    if (!isSearching) return;

    setResultActiveTabId(featureId);
    setShowResult(true);
  };

  const handleNewSearch = () => {
    if (!isSearching) {
      dispatch(searchActions.resetProgress('Database'));
    }
    setShowResult(false);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-p-2">
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between">
        <h1 className="tw-text-xl tw-font-bold tw-leading-8 tw-text-left">
          Search Across All Database Sources
        </h1>

        {!showResult && lastSearchOptionId > -1 && (
          <CustomButton
            bgColor="tw-bg-white"
            textColor="tw-text-primary"
            text="Go to previous search results"
            disabled={isSearching}
            onClick={() => {
              setResultActiveTabId(null);
              setShowResult(true);
            }}
            className="tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border !tw-border-solid tw-border-[#DFE0E3] tw-rounded-md tw-text-sm tw-font-semibold tw-leading-6 tw-text-center hover:tw-bg-[#F5F6F8]"
          />
        )}
      </div>
      {!showResult ? (
        <>
          <SearchComponent
            defaultOption={selectedOption}
            searchTerm={searchTerm}
            onSearchTermChange={setSearchTerm}
            onOptionChange={handleOptionChange}
            onSearch={handleSearch}
            loading={isSearching}
          />

          {isSearching && (
            <div className="tw-flex tw-items-center tw-bg-[#ffffff] tw-px-4 tw-py-3 tw-rounded-xl tw-min-h-[64px] tw-shadow-md">
              <div className="tw-flex tw-items-center tw-gap-[9.45px]">
                <div><BellYellow /></div>
                <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
                  You can already view partial results while the scans are
                  running. Please click on the scan area that is already 100%
                  finished to see its results or wait until all scans have
                  finished. The page will then refresh automatically. You can
                  also stop the running search and start a new search by
                  changing the search options and search terms and pressing the
                  search button again.
                </div>
              </div>
            </div>
          )}

          <div className="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6">
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                  Total Results
                </div>
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                  {''}
                </div>
              </div>
              <div className="tw-w-full tw-rounded-xl tw-bg-[#ffffff] tw-px-6 tw-pt-6">
                {features
                  .filter((item) => !item.premium)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={
                        'tw-flex tw-flex-col tw-mb-6 ' +
                        (isSearching ? 'tw-cursor-pointer' : '')
                      }
                      onClick={() => navigateSearchResult(item.id)}
                    >
                      <ProgressBar
                        premium={item.premium}
                        label={item.label}
                        disabled={!item.active}
                        height="tw-h-[6px]"
                        subLabel={item.subLabel}
                        current={
                          isSearching
                            ? item.store
                              ? item.store.progress
                              : 0
                            : 0
                        }
                        backgroundColor={
                          item.store
                            ? item.store.error
                              ? '#dc2626'
                              : '#ECC551'
                            : '#ECC551'
                        }
                        trackColor="tw-bg-[#EFF0F2]"
                        className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                        subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex">
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                  Only Premium
                </div>
              </div>
              <div className="tw-w-full tw-rounded-xl tw-bg-[#ffffff] tw-px-6 tw-pt-6">
                {features
                  .filter((item) => item.premium)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={
                        'tw-flex tw-flex-col tw-mb-6 ' +
                        (isSearching ? 'tw-cursor-pointer' : '')
                      }
                      onClick={() => navigateSearchResult(item.id)}
                    >
                      <ProgressBar
                        premium={item.premium}
                        label={item.label}
                        errorLabel={item.errorLabel}
                        disabled={!item.active}
                        height="tw-h-[6px]"
                        subLabel={item.subLabel}
                        current={
                          isSearching
                            ? item.store
                              ? item.store.progress
                              : 0
                            : 0
                        }
                        backgroundColor={
                          item.store
                            ? item.store.error
                              ? '#dc2626'
                              : '#ECC551'
                            : '#ECC551'
                        }
                        trackColor="tw-bg-[#EFF0F2]"
                        className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                        subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SearchResult
          newSearch={handleNewSearch}
          selectedOption={
            isSearching
              ? DatabaseSearchOptions[currentOptionId]
              : showResult
              ? DatabaseSearchOptions[lastSearchOptionId]
              : selectedOption
          }
          searchTerm={searchTerm}
          tabsInfo={features}
          activeTabId={resultActiveTabId}
        />
      )}
    </div>
  );
};

export default DatabaseSearch;
