import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DarknetSearchOptions } from 'common/components/SearchComponent/data';

import CircularProgress from 'common/components/CircularProgressBar/CircularProgressBar';
import ExpandIcon from 'common/Icons/ExpandIcon';
import ExportButton from 'common/components/Export/ExportButton';
import BellYellow from 'common/Icons/BellYellow';
import CustomButton from 'common/components/Button/Button';

import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import NoResult from 'common/components/NoResult/NoResult';

import { search as searchExternalSource } from 'store/slices/search/live/externalSource';

import SearchTipFeedback from '../../SearchTipFeedback';

const ExternalSources = ({ setSearch, isResultView = false, showTip = true }) => {
  const dispatch = useDispatch();

  const optionIndex = 3;
  const searchOptionLabel = DarknetSearchOptions[optionIndex].label;

  const {
    query,
    list,
    size,
    progress,
    currentPage,
    totalElements,
    totalPages,
    loading,
    pageLoading,
    searchStates,
    error,
  } = useSelector((state) => state.search.live.externalSource);

  const setCurrentPage = (page) => {
    dispatch(searchExternalSource({ page: page - 1 }));
  };

  // useEffect(() => {
  //   if (error) {
  //     useErrorbar(error);
  //   }
  // }, [error]);

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: (value) => <span className=" tw-text-[#44464A]">{value ?? 'N/A'}</span>,
    },
    {
      Header: 'Engine',
      accessor: 'engine',
      render: (value) => {
        return (
          <span className="tw-bg-[#F9FAFB] tw-border tw-border-[#EAECF0] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#344054]">
            {value}
          </span>
        );
      },
    },
    {
      Header: 'URL',
      accessor: 'url',
      render: (value) => {
        return (
          <a href={value} target="_blank">
            <span className="tw-text-[#00B6FF] tw-block tw-whitespace-normal tw-break-all">
              {value}
            </span>
          </a>
        );
      },
    },

    {
      Header: 'Description',
      accessor: 'description',
      render: (value) => <Description description={value} />,
    },
  ];

  if (!isResultView) {
    if (loading) {
      return (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1 tw-bg-white tw-rounded-lg tw-py-[200px]">
          <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#263238]">
            {'Please wait while the search is in progress...'}
          </div>

          <div className="tw-w-full tw-max-w-[300px] tw-flex tw-gap-3 tw-items-center">
            <div className={`tw-w-full tw-bg-[#EFF0F2] tw-rounded-lg tw-h-[6px]`}>
              <div
                className={`tw-rounded-lg tw-h-[6px]`}
                style={{
                  backgroundColor: '#ECC551',
                  width: `${progress}%`,
                }}
              ></div>
            </div>

            <div className="tw-text-xs tw-font-normal tw-leading-[14.06px] tw-text-[#797C84]">
              {Math.ceil(progress)}%
            </div>
          </div>

          <div className="tw-p-6 tw-flex tw-flex-col tw-gap-6 tw-mt-6">
            <div className="tw-flex tw-items-center tw-justify-center">
              <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-9 tw-gap-9">
                {searchStates?.map((state) => (
                  <CircularProgress
                    percentage={state.progress}
                    title={state.title}
                    count={state.resultCount}
                    strokeColor={state.progress == 100 ? '#22C55E' : '#ECC551'}
                    percentageColor="#111111"
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="tw-flex tw-items-center tw-bg-[#ffffff] tw-px-4 tw-py-3 tw-rounded-xl tw-min-h-[64px] tw-border tw-border-solid tw-border-secondary tw-mb-4">
            <div className="tw-flex tw-items-center tw-gap-[9.45px]">
              <div>
                <BellYellow />
              </div>
              <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
                It may take up to a few minutes to get all results, as we will be requesting
                multiple external resources over proxy servers, TOR and I2P networks, which may be
                very slow.
                <br />
                The search is still in progress, but you may already browse the results...
              </div>
            </div>
          </div>

          {searchStates?.length > 0 && (
            <CustomButton
              text="View the results"
              bgColor="tw-bg-secondary"
              textColor="tw-text-primary"
              hoverTextColor="white"
              className="!tw-w-60 tw-text-sm tw-font-semibold tw-leading-6"
              onClick={() => {
                setSearch && setSearch(true);
              }}
            />
          )}
        </div>
      );
    } else {
      if (list.length === 0) return <NoResult />;
    }
  }

  return (
    <>
      {showTip && (
        <div className="tw-mb-4">
          <SearchTipFeedback optionIndex={optionIndex} />
        </div>
      )}

      {/* NORMAL Table */}
      <div className="tw-grid tw-grid-cols-4 tw-gap-3">
        <div
          className={'tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4 tw-col-span-4'}
        >
          <h1 className="tw-text-lg tw-font-bold">{`${searchOptionLabel} Search Result`}</h1>

          <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-z-20">
            <div className="tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">Search results</span>
                <span className="tw-text-sm tw-font-medium">{totalElements}</span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">Search term</span>
                <span className="tw-text-sm tw-font-medium">{query}</span>
              </div>
            </div>
            <ExportButton disabled={loading} />
          </div>

          {loading && (
            <div className="tw-p-6 tw-flex tw-flex-col tw-gap-6">
              <div className="tw-flex tw-justify-between tw-items-center">
                <h2 className="tw-text-base tw-font-semibold tw-text-[#263238]">Progress</h2>
                <div className="tw-flex tw-gap-1 tw-items-center">
                  <p className="tw-text-sm tw-text-[#5B5D63] tw-font-normal">All Results:</p>
                  <p className="tw-text-base tw-text-[#00B6FF] tw-font-bold">{totalElements}</p>
                </div>
              </div>

              <div className="tw-flex tw-items-center tw-justify-center">
                <div className="tw-w-full tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-9 tw-gap-9">
                  {searchStates?.map((state) => (
                    <CircularProgress
                      percentage={state.progress}
                      title={state.title}
                      count={state.resultCount}
                      strokeColor={state.progress == 100 ? '#22C55E' : '#ECC551'}
                      percentageColor="#111111"
                    />
                  ))}
                </div>
              </div>

              {searchStates?.length > 0 && (
                <div className="tw-flex tw-items-center tw-bg-[#ffffff] tw-px-4 tw-py-3 tw-rounded-xl tw-min-h-[64px] tw-border tw-border-solid tw-border-secondary">
                  <div className="tw-flex tw-items-center tw-gap-[9.45px]">
                    <div>
                      <BellYellow />
                    </div>
                    <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
                      It may take up to a few minutes to get all results, as we will be requesting
                      multiple external resources over proxy servers, TOR and I2P networks, which
                      may be very slow.
                      <br />
                      {totalElements > 0 && (
                        <span>
                          The search is still in progress, but you may already browse the results...
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="tw-relative">
            <Table
              data={list}
              columns={columns}
              selectable={true}
              // onRowSelection={handleRowSelection}
            />
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
              <div className={loading ? 'tw-invisible' : 'tw-visible'}>
                <p className="tw-my-4">
                  Showing documents: {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                  {currentPage * size > totalElements ? totalElements : currentPage * size} of{' '}
                  {totalElements}
                </p>
              </div>

              {totalPages > 1 && (
                <Paginator
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              )}
            </div>
            {pageLoading && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalSources;

const Description = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="tw-relative tw-min-w-[200px]">
      <div className={`tw-overflow-hidden ${isExpanded ? '' : 'tw-max-h-10'}`}>
        <div
          className={`tw-line-clamp-2 tw-whitespace-normal tw-break-all ${
            isExpanded ? 'tw-line-clamp-none' : ''
          }`}
        >
          {description}
        </div>
      </div>
      {description.length > 150 && !isExpanded && (
        <button
          onClick={toggleExpand}
          className="tw-absolute  tw-right-[50%] tw-flex tw-justify-center tw-items-center tw-bg-white"
        >
          <ExpandIcon />
        </button>
      )}
      {isExpanded && (
        <button
          onClick={toggleExpand}
          className="tw-absolute  tw-right-[50%] tw-flex tw-justify-center tw-items-center tw-text-secondary tw-bg-white"
        >
          Hide
        </button>
      )}
    </div>
  );
};
