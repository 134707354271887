import React from 'react';

import Error from 'components/assets/svgs/Error';

const SearchResultError = ({ errorText }) => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1 tw-py-[60px] tw-bg-white tw-rounded-lg">
      <Error />
      <span className='tw-text-danger'>{errorText}</span>
    </div>
  );
};

export default SearchResultError;
