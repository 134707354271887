import React from 'react';

const Error = ({ size = 32, color = "#D72828" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g id="Error_1_">
          <g id="Error">
            <circle cx="16" cy="16" id="BG" r="16" fill={color} />
            <path
              d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
              id="Exclamatory_x5F_Sign"
              fill="#E6E6E6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Error;
