import React, {useState, useEffect} from "react";
import moment from "moment";

import api from 'store/api';
import CustomTable from "../../../common/table-no-paginator/table.component";
import { useNavigate } from "react-router-dom";
import table from "../../../assets/svgs/table.svg";
import download from "../../../assets/svgs/downlaod.svg";
import { useErrorbar, useSnackbar } from "utils/snackbar";
import Loader from "../../../../common/components/Loader/Loader";
import CustomModel from 'common/components/Model/Model';

function Report() {
  const [reportData, setReportData] = useState([]);
  const [isAPICalling, setIsAPICalling] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);

  const initReportData = async () => {
    setIsAPICalling(true);
    const result = await api.get("service/list_report_requests/");
    setIsAPICalling(false);
    if (result.status === 200) {
      const tempAry = [];
      for (let i = 0; i < result.data.length; i++) {
        const element = result.data[i];
        for (let j = 0; j < element.data.length; j++) {
          tempAry.push({...element.data[j], type: element.title, created_at: moment(element.data[j].created_at, "DD-MM-YYYY")});
        }
      }
      tempAry.sort((a, b) => b.created_at - a.created_at);
      setReportData(tempAry.slice(0, 7));
    }
  }

  const onHandleDelete = (record) => {
    setRecordToDelete(record);
    setModalOpen(true);
  };

  useEffect(() => {
    initReportData()
  }, []);

  const confirmDelete = async () => {
    setIsAPICalling(true);
    const result = await api.delete(`service/delete_report/${recordToDelete.id}/`);
    setIsAPICalling(false);
    setModalOpen(false);
  
    if (result.status === 200) {
      useSnackbar("Deleted the report successfully!");
      initReportData();
    } else {
      useErrorbar("Delete report failed!");
    }
  };

  const onHandleDownload = async (record) => {
    const result = await api.get(`service/download_report/${record.id}/`);
    if (result.status === 200) {
      useSnackbar("Download completed!");
    } else {
      useErrorbar("Download failed!")
    } 
  }
  
  const columns = [
    { Header: "Domain", accessor: "domain" },
    { Header: "Type", accessor: "type" },
    { 
      Header: "Date/Time",
      accessor: "created_at",
      render: (_, item) => {
        return (
          <span>{item.created_at.format("DD-MM-YYYY")}</span>
        )
      }
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Actions",
      accessor: "screenshot",
      render: (_, record) => {
        return (
          <div className="tw-flex tw-flex-row tw-gap-2">
            <div className="tw-cursor-pointer" onClick={() => onHandleDelete(record)}><img src={table} alt="" /></div>
            <div className="tw-cursor-pointer" onClick={() => onHandleDownload(record)}><img src={download} alt="" /></div>
          </div>
        );
      },
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="tw-p-4 tw-bg-white">
      <div className="tw-flex tw-flex-row tw-justify-between">
        <p className=" tw-font-bold tw-text-lg tw-text-[#263238] ">
          Darknet Reports
        </p>
        <p
          className="tw-font-medium tw-text-sm tw-cursor-pointer"
          onClick={() => navigate("/reports")}
        >
          Show All
        </p>
      </div>
      <div className="tw-mt-3">
        {isAPICalling ? <div className="tw-w-full tw-flex tw-justify-center tw-pt-20"><Loader /></div> : <CustomTable data={reportData} columns={columns} selectable={false}/>  }
      </div>
      <CustomModel isOpen={isModalOpen}>
      <div className="tw-flex tw-flex-col tw-items-center">
          <h2 className="tw-text-xl tw-font-bold tw-mb-4">Confirm Delete</h2>
          <p className="tw-mb-4">Are you sure you want to delete this report?</p>
          <div className="tw-flex tw-gap-4">
            <button
              onClick={confirmDelete}
              className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            >
              Yes
            </button>
            <button
              onClick={() => setModalOpen(false)}
              className="tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            >
              No
            </button>
          </div>
        </div>
      </CustomModel>
    </div>
  );
}

export default Report;
