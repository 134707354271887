import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Tooltip } from 'react-tooltip';

import CustomButton from 'common/components/Button/Button';
import CustomDropdown from 'common/components/DropDown/CustomDropdown';
import InputGroup from 'common/components/InputGroup/InputGroup';
import MultiSelect from 'common/components/MultiSelect/MultiSelect';

import { ArrowDownLight, SearchIcon, InfoIcon } from 'common/Icons';

import { DarknetSearchOptions } from 'common/components/SearchComponent/data';
import Checkbox from 'common/components/Checkbox/Checkbox';

const SearchComponent = ({
  searchTerm = '',
  onOptionChange,
  onSearch,
  defaultOption,
  defaultForums = [],
  loading,
}) => {
  const { crawlers: forums } = useSelector((state) => state.search.live.hackerForum);

  const [option, setOption] = useState({});
  const [selectedForums, setSelectedForums] = useState([]); // For Hacker Marketplaces
  const [validateResult, setValidateResult] = useState(false); // For Tor and I2P Network
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isEmptyForums, setIsEmptyForums] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    if (defaultForums.length > 0) setSelectedForums(defaultForums);
  }, [defaultForums]);

  useEffect(() => {
    setSearchInputValue(searchTerm);
  }, [searchTerm]);

  const handleSearch = async () => {
    setIsEmptyForums(false);
    if (option.id === 2) {
      // Hacker Marketplaces option
      if (selectedForums.length === 0) {
        setIsEmptyForums(true);
        return;
      }
    }

    Yup.object()
      .shape({
        searchInputValue: option.validation,
      })
      .validate({ searchInputValue })
      .then(() => {
        setErrorMessage('');

        onSearch &&
          onSearch({
            searchTerm: searchInputValue,
            selectedForums: option.id === 2 ? selectedForums : forums,
            isValidateResult: validateResult,
          });
      })
      .catch((err) => {
        console.error(err.errors);
        setErrorMessage(err.errors);
      });
  };

  return (
    <div className="tw-p-4 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex lg:tw-flex-row tw-flex-col tw-rounded-lg tw-gap-3">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-gap-1 md:tw-gap-0 tw-bg-[#F5F6F8] ">
          <div className="tw-w-full md:tw-max-w-[240px]">
            <CustomDropdown
              defaultOption={option}
              placeholder="Select"
              options={DarknetSearchOptions}
              name="host"
              background={true}
              border={true}
              className="tw-w-full tw-h-[45px] tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-[8px_0px_0px_8px]"
              onChange={(option) => {
                setOption(option);
                onOptionChange && onOptionChange(option);
              }}
            />
          </div>

          <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-px-4 tw-gap-3 md:tw-gap-[10px] tw-border-[0.5px] ">
            {option && option.id === 2 /* Hacker Marketplaces */ && (
              <div className="tw-relative">
                <MultiSelect
                  placeholder="Select Forum"
                  options={forums}
                  name="host"
                  background={true}
                  border={true}
                  dropdownArrow={<ArrowDownLight />}
                  className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-px-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                  onChange={(forums) => {
                    if (forums.length > 0) setIsEmptyForums(false);
                    setSelectedForums(forums);
                  }}
                  defaultOptions={selectedForums}
                />
                {isEmptyForums && (
                  <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-whitespace-nowrap tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                    You must select the forums you want to search
                  </div>
                )}
              </div>
            )}

            <div className="tw-w-full tw-flex tw-items-center tw-relative">
              <InputGroup
                placeholder={`${option ? option.value : 'Keyword...'}`}
                disabled={option ? false : true}
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                className="!tw-bg-gray-100 tw-w-full tw-h-full tw-pl-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-left tw-px-4 tw-py-2 !tw-border-none"
              />
              {errorMessage && (
                <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                  {errorMessage}
                </div>
              )}
            </div>

            {option && option.id === 4 /* Tor and I2P Network */ && (
              <div className="tw-relative tw-flex tw-items-center">
                <div className="tw-whitespace-nowrap">
                  <Checkbox
                    label="Validate Results"
                    checked={validateResult}
                    onChange={(value) => setValidateResult(value)}
                  />
                </div>
                <div className="my-anchor-element25">
                  <InfoIcon />
                </div>
                <Tooltip
                  anchorSelect=".my-anchor-element25"
                  place="top-end"
                  border="1px solid #eee"
                  opacity={1}
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: 10,
                    padding: 20,
                    width: 450,
                    boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <div>
                    If set to true, the system will verify each found result and check whether it
                    contains the exact search phrase. <br />
                    This option may be useful only when searching 1-word queries, otherwise search
                    results may be inaccurate.
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <div>
          <CustomButton
            text="Search"
            disabled={_.isEmpty(option) || (option.id === 1 && loading)}
            isLoading={loading}
            onClick={handleSearch}
            icon={<SearchIcon fill="#ffffff" />}
            className="tw-w-full tw-py-[10px] tw-rounded-md tw-px-3 tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-h-full tw-text-sm tw-font-semibold tw-leading-6 tw-text-left"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
