import React, {useState, useEffect} from "react";
import CustomTable from "../../common/table-no-paginator/table.component";
import Heading from "../../../common/components/heading/Heading";
import api from 'store/api';

function PasswordStrengthTable() {
  const columns = [
    { Header: "Password Strengths", accessor: "created" },
    { Header: "Statistics", accessor: "results" },
  ];

  const [analysisReport, setAnalysisReport] = useState([]);

  const passwordStrengthAnalysisReport = async () => {
    try {
      const result = await api.get("service/password_strength_analysis/");
      const totalNumber = result.data.total_number;
      const notContainDigit = result.data.not_contain_digit;
      const notMeetMinlength = result.data.not_meet_minlength;
      const notContainUppercase = result.data.not_contain_uppercase;
      const notContainSpecial = result.data.not_contain_special;

      const notContainDigitPercentage = ((notContainDigit / totalNumber) * 100).toFixed(1);
      const notMeetMinlengthPercentage = ((notMeetMinlength / totalNumber) * 100).toFixed(1);
      const notContainUppercasePercentage = ((notContainUppercase / totalNumber) * 100).toFixed(1);
      const notContainSpecialPercentage = ((notContainSpecial / totalNumber) * 100).toFixed(1);

      const data = [
        {
          created: "Total number of passwords found",
          results: `${totalNumber}`,
        },
        {
          created: "Passwords that do not contain numbers",
          results: `${notContainDigit} with ${notContainDigitPercentage}%`,
        },
        {
          created: "Passwords that do not meet the minimum length",
          results: `${notMeetMinlength} with ${notMeetMinlengthPercentage}%`,
        },
        {
          created: "Passwords that do not contain the uppercase",
          results: `${notContainUppercase} with ${notContainUppercasePercentage}%`,
        },
        {
          created: "Passwords that do not contain the specialcase",
          results: `${notContainSpecial} with ${notContainSpecialPercentage}%`,
        },
      ];

      setAnalysisReport(data);

      return result.data; // Return the fetched data
    } catch (error) {
      console.error("Error fetching risky users chart stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    passwordStrengthAnalysisReport();
  }, []);

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow tw-h-full">
      <Heading size="h2" text="Password Strength analysis" />
      <CustomTable data={analysisReport} columns={columns} selectable={false} scrollable={true} height="200px" />
    </div>
  );
}

export default PasswordStrengthTable;
