import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from 'redux-persist';

// import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import storage from 'redux-persist-indexeddb-storage';

import authReducer from './slices/auth';
import userReducer from './slices/user';
import searchReducer from './slices/search';
import reportReducer from './slices/report';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  search: searchReducer,
  report: reportReducer,
});

const resetSpecificKeysTransform = createTransform(
  // Transform incoming state before rehydration
  (inboundState, key) => {
    if (key === 'search') {
      return {
        ...inboundState,
        live: {
          ...inboundState.live,
          main: {
            ...inboundState.live.main,
            currentOptionId: -1,
            currentQuery: '',
            isSearching: false,
          },
          socialNetwork: {
            ...inboundState.live.socialNetwork,
            loading: false,
            pageLoading: false,
            error: null,
          },
          externalSource: {
            ...inboundState.live.externalSource,
            loading: false,
            pageLoading: false,
            error: null,
          },
        },
      };
    }

    return inboundState;
  },
  // Transform outgoing state before persisting
  (outboundState, key) => outboundState,
);

const persistConfig = {
  key: 'root',
  storage: storage('localDB'),
  transforms: [resetSpecificKeysTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggingMiddleware = (store) => (next) => (action) => {
  console.log('Dispatching action:', action);
  const result = next(action);
  console.log('New state:', store.getState());
  return result;
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }) /*.concat(loggingMiddleware)*/,
});

export const persistor = persistStore(store);
export default store;
