import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';

function App() {
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Custom logic here
  //     event.preventDefault();
      
  //     // event.returnValue = ""; // Required for modern browsers to show the confirmation dialog      
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const pages = useRoutes(routes);
  return <>{pages}</>;
}

export default App;
