import React from 'react';

const KaduuTagRenderer = ({ content }) => {
  // Regular expression to identify the <kaduu:h>...</kaduu:h> custom tags
  const regex = /(<kaduu:h>[\s\S]*?<\/kaduu:h>)/m;

  content = content.replace(/\n\n/gm, '\n');
  
  // Split the content into parts and process each part
  const parts = content.split(regex);

  return (
    <div>
      {parts.map((part, index) => {
        if (part.startsWith("<kaduu:h>") && part.endsWith("<\/kaduu:h>")) {
          // Extract content inside the custom tag and replace it with <span>
          const innerContent = part.replace(/<\/?kaduu:h>/g, "");
          return (
            <span key={index} className="tw-bg-secondary">
              {innerContent}
            </span>
          );
        } else if (!part.match(regex)) {
          // Render plain text parts as is
          return <React.Fragment key={index}>{part}</React.Fragment>;
        }
        return null;
      })}
    </div>
  );
};

export default KaduuTagRenderer;
