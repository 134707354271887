import React, { useId, useState, forwardRef } from 'react';
import _ from 'lodash';

import InputLabel from '../InputLabel/InputLabel';
import AuthEyeIcon from '../../Icons/AuthEyeIcon'; // Assuming this is the open-eye icon
import EyeClosed from '../../Icons/EyeClosed'; // Assuming this is the closed-eye icon

const InputGroup = forwardRef(({
  labelText = '',
  inputType = 'text',
  placeholder = '',
  inputName = '',
  register = () => {},
  errors = {},
  value,
  onChange,
  className,
  children,
  defaultValue = '',
  checked,
  readOnly,
  bgColor,
  disabled = false,
  extraLabel = '',
  focusOnType = false,
  prefixIcon,
  suffixIcon,
}, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputId = useId();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`tw-relative tw-m-auto tw-w-full tw-space-y-1`}>
      {labelText && (
        <InputLabel
          htmlFor={inputId}
          text={labelText}
          extraLabel={extraLabel}
        />
      )}
      <div className="tw-relative tw-flex tw-items-center">
        {prefixIcon && (
          <span className="tw-absolute tw-left-4">{prefixIcon}</span>
        )}
        {inputType !== 'textarea' ? (
          <input
            id={inputId}
            ref={ref}
            type={
              inputType === 'password'
                ? showPassword
                  ? 'text'
                  : 'password'
                : inputType
            }
            name={inputName}
            placeholder={placeholder}
            checked={checked}
            // defaultValue={defaultValue}
            value={value}
            readOnly={readOnly}
            className={`tw-outline-none ${
              inputType === 'checkbox' || inputType === 'radio'
                ? 'tw-w-4 tw-h-4 tw-accent-secondary tw-border-[#DFE0E3] focus:tw-ring-0 focus:tw-ring-secondary/30 focus:tw-outline-none'
                : 'tw-block tw-w-full tw-px-4 tw-py-2 tw-text-primary tw-bg-white tw-border tw-border-[#DFE0E3] tw-rounded-lg focus:tw-border-secondary focus:tw-ring-0 focus:tw-ring-secondary/30 focus:tw-outline-none'
            } ${prefixIcon ? 'tw-pl-10' : ''} ${suffixIcon ? 'tw-pr-10' : ''} ${
              bgColor ? bgColor : ''
            } ${inputType === 'checkbox' && 'rounded' ? '' : ''} ${
              _.get(errors, inputName)
                ? '!tw-border-red-500 !tw-ring-red-500 !tw-ring-red-500/30'
                : ''
            } ${
              focusOnType ? 'focus:tw-ring-2 focus:tw-ring-secondary/30' : ''
            } ${className ?? ''}`}
            onChange={onChange}
            autoComplete={inputName}
            {...register(inputName)}
            disabled={disabled}
          />
        ) : (
          <textarea
            id={inputId}
            ref={ref}
            rows="6"
            name={inputName}
            placeholder={placeholder}
            // defaultValue={defaultValue}
            value={value}
            className={`tw-block tw-w-full tw-px-4 py-2 tw-appearance-none tw-text-text-primary tw-bg-white tw-border tw-rounded-lg focus:tw-border-border-primary focus:tw-ring-0 focus:tw-outline-none tw-h-28 tw-resize-none ${
              _.get(errors, inputName)
                ? 'tw-border-red-500'
                : 'tw-border-border-primary'
            } ${focusOnType ? 'focus:tw-ring-2 focus:tw-ring-blue-500' : ''}`}
            onChange={onChange}
            autoComplete={inputName}
            {...register(inputName)}
          />
        )}
        {inputType === 'password' && (
          <span
            className="tw-absolute tw-right-4 tw-cursor-pointer"
            onClick={handleTogglePassword}
          >
            {showPassword ? <EyeClosed /> : <AuthEyeIcon />}
          </span>
        )}
      </div>
      {children}
      {_.get(errors, inputName) && (
        <p className="tw-text-red-500 tw-text-sm">
          {_.get(errors, inputName).message}
        </p>
      )}
    </div>
  );
});

export default InputGroup;
