import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Yup from 'yup';

import CustomButton from 'common/components/Button/Button';
import CustomDropdown from 'common/components/DropDown/CustomDropdown';
import InputGroup from 'common/components/InputGroup/InputGroup';
import { DatabaseSearchOptions } from 'common/components/SearchComponent/data';
import { ArrowDownLight, SearchIcon, Information } from 'common/Icons';
import CrossIcon from 'common/Icons/CrossIcon';

import useFocus from 'hooks/use-focus';

const SearchComponent = ({
  defaultOption = {},
  searchTerm = '',
  onOptionChange,
  onSearchTermChange,
  onSearch,
  loading,
}) => {
  const searchConfig = useSelector((state) => state.search.database.main);

  const [selectedOption, setOption] = useState({});
  const [searchInputValue, setSearchInputValue] = useState(searchTerm);
  const [errorMessage, setErrorMessage] = useState('');

  const fileExtensionOptions = DatabaseSearchOptions.find(
    (option) => option.label === 'File Name',
  ).extensions;
  const [fileExtensionOption, setFileExtensionOption] = useState(
    fileExtensionOptions[0],
  );

  useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    setSearchInputValue(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (onSearchTermChange) onSearchTermChange(searchInputValue);
  }, [searchInputValue]);

  const handleSearch = async () => {
    Yup.object()
      .shape({
        searchInputValue: selectedOption.validation,
      })
      .validate({ searchInputValue })
      .then(() => {
        setErrorMessage('');

        if (searchConfig.isSearching) {
          // If there is a search running...
          openNewSearchModal();
          return;
        }

        if (
          searchConfig.selectedOptionId == 2 &&
          searchInputValue.split(' ').length == 1
        ) {
          // If search option is Name and input is first/last name only...
          openWarningModal();
          return;
        }

        onSearch(searchInputValue, fileExtensionOption);
      })
      .catch((err) => {
        console.error(err.errors);
        setErrorMessage(err.errors);
      });
  };

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [inputRef, setInputFocus] = useFocus();

  const openWarningModal = () => {
    setIsWarningModalOpen(true);
  };

  const closeWarningModal = () => {
    setIsWarningModalOpen(false);
    setInputFocus();
  };

  const confirmWarningModal = () => {
    setIsWarningModalOpen(false);

    onSearch(searchInputValue, fileExtensionOption);
  };

  const [isNewSearchModalOpen, setIsNewSearchModalOpen] = useState(false);
  const openNewSearchModal = () => {
    setIsNewSearchModalOpen(true);
  };

  const closeNewSearchModal = () => {
    setIsNewSearchModalOpen(false);
  };

  const confirmNewSearchModal = () => {
    setIsNewSearchModalOpen(false);

    onSearch(searchInputValue, fileExtensionOption);
  };

  return (
    <div className="tw-p-4 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex tw-flex-col min-[1920px]:tw-flex-row min-[1920px]:tw-items-center tw-rounded-lg tw-gap-3">
        <div className="tw-flex tw-grow tw-flex-col lg:tw-flex-row lg:tw-items-center tw-rounded-lg tw-gap-3">
          <div className="tw-font-bold tw-text-sm tw-text-nowrap">
            Select Search Type
          </div>
          <div className="tw-w-full lg:tw-max-w-[220px]">
            <CustomDropdown
              labelOfList="Search Type"
              placeholder="Select"
              defaultOption={defaultOption}
              options={DatabaseSearchOptions}
              name="search_type"
              background={true}
              border={true}
              // disabled={searchConfig.isSearching}
              dropdownArrow={<ArrowDownLight />}
              className="tw-w-full tw-h-10 !tw-bg-[#F5F6F8] tw-border tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-lg tw-whitespace-nowrap"
              onChange={(option) => {
                setErrorMessage('');

                if (option.label !== 'File Name') {
                  setFileExtensionOption(fileExtensionOptions[0]);
                }

                setOption(option);
                onOptionChange && onOptionChange(option);
              }}
            />
          </div>

          <div className="tw-font-bold tw-text-sm tw-text-nowrap">
            Select Search Term
          </div>
          <div className="tw-w-full min-[1920px]:tw-min-w-[574px] tw-flex tw-items-center tw-relative">
            <InputGroup
              ref={inputRef}
              placeholder={`${
                _.isEmpty(selectedOption)
                  ? 'Keyword...'
                  : selectedOption.placeholder
                  ? selectedOption.placeholder
                  : `Enter your ${selectedOption.label.toLowerCase()} (e.g., ${
                      selectedOption.example
                    })`
              }`}
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
              className="!tw-bg-[#F5F6F8] !tw-border-[#D9D9D9] tw-w-full tw-h-10 tw-text-sm tw-font-normal tw-tracking-[0.02em] tw-px-4 tw-py-2"
            />
            {errorMessage && (
              <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                {errorMessage}
              </div>
            )}
          </div>

          {selectedOption.label === 'File Name' && (
            <>
              <div className="tw-font-bold tw-text-sm tw-text-nowrap">
                File Extension
              </div>
              <div className="tw-w-full lg:tw-max-w-[180px] tw-flex tw-items-center tw-relative">
                <CustomDropdown
                  defaultOption={fileExtensionOption}
                  placeholder="Select a option"
                  options={fileExtensionOptions}
                  name="dropdown"
                  background={true}
                  border={true}
                  dropdownArrow={<ArrowDownLight />}
                  className="tw-w-full tw-h-10 tw-whitespace-nowrap tw-text-sm tw-border tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-lg"
                  onChange={(option) => {
                    setFileExtensionOption(option);
                  }}
                />
              </div>
            </>
          )}

          <div>
            <CustomButton
              text="Search"
              // disabled={_.isEmpty(option) || loading}
              onClick={handleSearch}
              isLoading={loading}
              icon={<SearchIcon fill="#ffffff" />}
              className="tw-w-full tw-py-[10px] tw-rounded-md tw-px-3 tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-h-full tw-text-sm tw-font-semibold tw-leading-6 tw-text-left"
            />
          </div>
        </div>
        <div className="tw-flex tw-flex-none tw-items-center tw-py-2 tw-gap-3 min-[1920px]:tw-w-[30%]">
          <Information />
          <span className="tw-text-sm">
            Search across multiple databases for your assets. We automatically
            determine the applicable database based on the search type. Click
            results as they appear while the search continues running.
          </span>
        </div>
      </div>

      <WarningModal
        isOpen={isWarningModalOpen}
        onClose={closeWarningModal}
        onConfirm={confirmWarningModal}
      />

      <NewSearchModal
        searchOptionLabel={
          DatabaseSearchOptions[searchConfig.currentOptionId]?.label
        }
        searchTerm={searchConfig.currentQuery}
        isOpen={isNewSearchModalOpen}
        onClose={closeNewSearchModal}
        onConfirm={confirmNewSearchModal}
      />
    </div>
  );
};

export default SearchComponent;

// Warning Modal Component
const WarningModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center tw-z-[100]">
      <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-xl tw-max-w-[700px] tw-w-full tw-relative">
        {/* Cross Icon for closing */}
        <button
          className="tw-absolute tw-top-3 tw-right-3 tw-text-gray-400 hover:tw-text-gray-600"
          onClick={onClose}
        >
          <CrossIcon />
        </button>

        <h2 className="tw-font-bold tw-text-lg">Tip</h2>
        <p className="tw-text-gray-700 tw-mt-2 tw-py-1">
          To avoid getting too many <strong>Leaks</strong> search results, we
          recommend entering your full name or make sure your first or last name
          is at least 10 characters long.
        </p>
        <div className="tw-flex tw-justify-end tw-space-x-4 tw-mt-6">
          <CustomButton text="Continue anyway" onClick={onConfirm} />
          <CustomButton
            text="Edit the keyword"
            bgColor="tw-bg-secondary"
            textColor="tw-text-primary"
            hoverTextColor="white"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

// New Search Modal Component
const NewSearchModal = ({
  searchOptionLabel,
  searchTerm,
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center tw-z-[100]">
      <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-xl tw-max-w-[700px] tw-w-full tw-relative">
        {/* Cross Icon for closing */}
        <button
          className="tw-absolute tw-top-3 tw-right-3 tw-text-gray-400 hover:tw-text-gray-600"
          onClick={onClose}
        >
          <CrossIcon />
        </button>

        <h2 className="tw-font-bold tw-text-lg">New Search</h2>
        <p className="tw-text-gray-700 tw-mt-2 tw-py-1">
          The <b>{searchOptionLabel}</b> search for &quot;<b>{searchTerm}</b>
          &quot; is still in progress.
          <br />
          Do you want to stop it first and try a new search?
        </p>
        <div className="tw-flex tw-justify-end tw-space-x-4 tw-mt-6">
          <CustomButton text="Yes, I want" onClick={onConfirm} />
          <CustomButton
            text="No thanks"
            bgColor="tw-bg-secondary"
            textColor="tw-text-primary"
            hoverTextColor="white"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};
