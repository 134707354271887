import moment from 'moment';
import React, { useRef, useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import api from 'store/api';
import { AlertIcon, Setting } from '../../../common/Icons';
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import { useOutsideClick } from "hooks/useOutsideClick";

function Alerts() {
  const [selectedPeriod, setSelectedPeriod] = useState("Last Week");
  const [areaChartData, setAreaChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [alertSources, setAlertSources] = useState([]);
  const [selectedAlertSourceIds, setSelectedAlertSourceIds] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [error, setError] = useState(null);
  const [alertDropdownOptions, setAlertDropdownOptions] = useState([
    { name: "Show All", checked: false },
    { name: "Show Only Selected Sources", checked: false },
  ]);
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const settingsDropdownRef = useRef(null);
  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));

  const getPeriodValue = (option) => {
    const index = periods.indexOf(option);
    return period_values[index];
  };

  const handleRetry = async () => {
    await fetchAlertSources();
    await getAlertStats();
  };

  const fetchAlertSources = async () => {
    setLoadingState(true);
    setError(null);

    try {
      const response = await api.get('/service/alert_system_service_get_service_list/');
      if (response?.data) {
        const sources = response.data
        .filter(source => source.is_active)
        .map(source => ({
          id: source.id,
          name: source.name,
          checked: true
        }));

        setAlertSources(sources);
        setSelectedAlertSourceIds(sources.map(source => source.id));

        const newAlertDropdownOptions = [...alertDropdownOptions];
        newAlertDropdownOptions[0].checked = true;
        setAlertDropdownOptions(newAlertDropdownOptions);
      }
    } catch (error) {
      console.error('Error fetching alert sources:', error);
      setError('Failed to fetch alert sources');
    } finally {
      setLoadingState(false);
    }
  };

  const getAlertStats = async () => {
    if (!selectedAlertSourceIds.length)
      return;

    setLoadingState(true);
    setError(null);

    try {
      const periodValue = getPeriodValue(selectedPeriod);
      const result = await api.get("service/alert_system_alert_get_alert_match_statistics/", {
        params: {
          period: periodValue,
          services: selectedAlertSourceIds.join(',')
        },
      });

      if (!result?.data) {
        throw new Error('No data received from API');
      }

      let data = result.data.map(item => ({
        service_id: item.service_id,
        service_name: item.service_name,
        creation_date: item.creation_date,
        alert_hit_count: item.alert_hit_count
      }));

      // Add missing dates
      if (data.length > 0 && selectedPeriod !== "Last Year") {
        let startDay, lastDay;

        if (selectedPeriod === "Last Week") {
          startDay = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD');
          lastDay = moment().endOf('day').format('YYYY-MM-DD');
        } else if (selectedPeriod === "Last Month") {
          startDay = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD');
          lastDay = moment().endOf('day').format('YYYY-MM-DD');
        }

        const filledData = [];
        for (let date = startDay; date <= lastDay; date = moment(date).add(1, 'days').format('YYYY-MM-DD')) {
          const found = data.find(item => item.creation_date === date);
          if (found) {
            filledData.push(found);
          } else {
            filledData.push({
              service_id: data[0].service_id,
              service_name: data[0].service_name,
              creation_date: date,
              alert_hit_count: 0
            });
          }
        }
        data = filledData;
      }

      data.sort((a, b) => a.creation_date.localeCompare(b.creation_date));
      setAreaChartData(data);
    } catch (error) {
      console.error("Error fetching alert stats:", error);
      setError('Failed to fetch alert statistics');
      setAreaChartData([]);
    } finally {
      setLoadingState(false);
    }
  };

  const handleOptionChange = (index) => {
    const newAlertDropdownOptions = [...alertDropdownOptions];
    newAlertDropdownOptions[index].checked = !newAlertDropdownOptions[index].checked;
    newAlertDropdownOptions.forEach((option, i) => {
      if (i !== index) {
        option.checked = !newAlertDropdownOptions[index].checked;
      }
    });
    setAlertDropdownOptions(newAlertDropdownOptions);

    if (newAlertDropdownOptions[index].name === "Show All" && newAlertDropdownOptions[index].checked) {
      const newAlertSources = [...alertSources]
      newAlertSources.forEach((domain, idx) => {
        newAlertSources[idx].checked = true;
      });
      setAlertSources(newAlertSources);
      setSelectedAlertSourceIds(newAlertSources.map(source => source.id));
    }
  };

  const handleSourceChange = (index, id) => {
    const newSources = [...alertSources];
    newSources[index].checked = !newSources[index].checked;
    setAlertSources(newSources);

    const selectedAlertDropdownOption = alertDropdownOptions.find(option => option.checked);
    if (!newSources[index].checked && selectedAlertDropdownOption.name === "Show All") {
      const newAlertDropdownOptions = [...alertDropdownOptions];
      newAlertDropdownOptions[0].checked = false;
      newAlertDropdownOptions[1].checked = true;
      setAlertDropdownOptions(newAlertDropdownOptions);
    }

    const selectedSourceIds = newSources.filter(source => source.checked).map(source => source.id);
    setSelectedAlertSourceIds(selectedSourceIds);
  }

  const toggleDropdown = (event) => {
    event.stopPropagation(); 
    setDropdownOpen((prev) => !prev);
  };

  const handleSelectPeriod = (option) => {
    setSelectedPeriod(option);
  };

  useEffect(() => {
    fetchAlertSources();
  }, []);

  useEffect(() => {
    getAlertStats();
  }, [selectedPeriod, selectedAlertSourceIds]);

  useEffect(() => {
    if (!areaChartData.length)
      return;

    const groupDataByService = (data) => {
      return data.reduce((acc, curr) => {
        if (!curr?.service_name || !curr?.creation_date) return acc;

        const { service_name, creation_date, alert_hit_count } = curr;
        if (!acc[service_name]) {
          acc[service_name] = [];
        }
        acc[service_name].push({
          x: creation_date,
          y: alert_hit_count ?? 0,
        });
        return acc;
      }, {});
    };
    const prepareMultiSeriesData = (groupedData) => {
      return Object.keys(groupedData).map(service => ({
        name: `${service.charAt(0).toUpperCase() + service.slice(1)} Alerts`,
        data: groupedData[service],
      }));
    };
    const getAllUniqueDates = (data) => {
      const datesSet = new Set();
      data.forEach(item => {
        datesSet.add(item.creation_date);
      });
      const uniqueDates = Array.from(datesSet);
      uniqueDates.sort(); // Sort dates in ascending order
      return uniqueDates;
    };
    const normalizeGroupedData = (groupedData, allDates) => {
      const normalizedData = {};

      Object.keys(groupedData).forEach(service => {
        const serviceDataMap = new Map();
        groupedData[service].forEach(dataPoint => {
          serviceDataMap.set(dataPoint.x, dataPoint.y);
        });
        normalizedData[service] = allDates.map(date => ({
          x: date,
          y: serviceDataMap.get(date) || 0, // Assign 0 if date is missing
        }));
      });

      return normalizedData;
    };

    const showAll = alertDropdownOptions[0].checked;
    const grouped = groupDataByService(areaChartData);
    const allDates = getAllUniqueDates(areaChartData);
    const normalizedData = normalizeGroupedData(grouped, allDates)

    if (!showAll) {
      const multiSeriesData = prepareMultiSeriesData(normalizedData)
      setChartSeries(
        multiSeriesData
      );
    } else {
      const aggregatedAlerts = allDates.map(date => {
        let sum = 0;
        Object.keys(normalizedData).forEach(service => {
          const dataPoint = normalizedData[service].find(item => item.x === date);
          if (dataPoint) {
            sum += dataPoint.y;
          }
        });
        return sum;
      });
      setChartSeries([{
        name: "Total Alerts",
        data: aggregatedAlerts,
      }]);
    }

    setChartOptions({
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      responsive: [{
        breakpoint: 1024,
      }],
      colors: ["#FEB019", "#008FFB", "#00E396", "#FF00FF", "#FF6347", "#FF4560", "#FFD700", "#8A2BE2", "#FF1493", "#FF4500", "#808000", "#4B0082"],
      xaxis: {
        type: "category",
        categories: allDates,
        title: {
          text: "Date",
        }
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: "Number of Alerts",
        },
      },
      stroke: {
        curve: "smooth",
        width: 4,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100]
        }
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      tooltip: {
        enabled: true,
      },
    });
  },  [areaChartData, alertDropdownOptions]);

  return (
    <div className="tw-p-4 tw-bg-white tw-shadow-md tw-rounded-lg tw-h-full">
      {/* Header Section */}
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-mb-4">
        <div className="tw-flex tw-items-center tw-gap-1 md:tw-gap-4">
          {(error) && (
            <div className="tw-flex tw-items-center tw-p-2 tw-rounded-lg tw-border tw-bg-red-50 tw-border-red-200">
              <div className="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-mr-3 tw-text-red-400">
                <AlertIcon color="#F87171" />
              </div>

              <div className="tw-flex-1">
                <p className="tw-text-sm tw-text-red-800">
                  {error}
                </p>
              </div>

              {/* Retry Button */}
              <button
                onClick={() => handleRetry()}
                className="tw-ml-4 tw-px-3 tw-py-1 tw-text-sm tw-rounded-md tw-flex tw-items-center tw-gap-2 tw-bg-red-100 hover:tw-bg-red-200 tw-text-red-800"
              >
                <svg className="tw-w-4 tw-h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
                Retry
              </button>
            </div>
          )}
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-gap-4">
          <button
            className="tw-bg-black tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-gray-800 tw-transition-colors"
            onClick={() => navigate("/AlertingRules", { state: { isNewAlertingOpen: true } })}
          >
            Setup Alerts
          </button>
          <div className="tw-relative">
            <button
              onClick={toggleDropdown}
              className="tw-p-2 tw-rounded-full hover:tw-bg-gray-100 tw-transition-colors"
            >
              <Setting />
            </button>

            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                className="tw-absolute tw-mt-2 tw-p-4 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10 tw-shadow-lg"
              >
                <h3 className="tw-text-sm tw-font-medium tw-mb-2">Different Options:</h3>
                <div className="tw-max-h-40 tw-overflow-y-auto">
                  {alertDropdownOptions.map((option, index) => (
                    <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-p-2">
                      <input
                        type="checkbox"
                        checked={option.checked}
                        onChange={() => handleOptionChange(index)}
                        className="tw-mr-2 tw-h-5 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                      />
                      <span className="tw-text-sm tw-text-gray-600">
                        {option.name}
                      </span>
                    </label>
                  ))}
                </div>

                <h3 className="tw-text-sm tw-font-medium tw-mb-2 tw-mt-4">Selected Alert Sources:</h3>
                <div className="tw-max-h-40 tw-overflow-y-auto">
                  {alertSources.map((source, index) => (
                    <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-p-2">
                      <input
                        type="checkbox"
                        checked={source.checked}
                        onChange={() => handleSourceChange(index, source.id)}
                        className="tw-mr-2 tw-h-5 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                      />
                      <span className="tw-text-sm tw-text-gray-600">
                        {source.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>

          <GenericDropdown
            options={periods}
            defaultText={selectedPeriod}
            onSelect={handleSelectPeriod}
            staticFirstOption={true}
          />
        </div>
      </div>

      {/* Chart Section */}
      <div className="tw-relative">
        {loadingState ? (
          <div className="tw-animate-pulse">
            <div className="tw-h-8 tw-bg-gray-200 tw-rounded tw-w-1/4"></div>
            <div className="tw-h-[330px] tw-bg-gray-200 tw-rounded"></div>
          </div>
        ) : (
          chartSeries && chartSeries.length > 0 && (
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="area"
              height={330}
            />
          )
        )}

        {/* Empty state */}
        {!loadingState && (!chartSeries || chartSeries.length === 0) && !error && (
          <div className="tw-h-[330px] tw-flex tw-items-center tw-justify-center tw-bg-gray-50 tw-rounded">
            <p className="tw-text-gray-500">No data available for the selected period</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Alerts;
const periods = ["Last Week", "Last Month", "Last Year"];
const period_values = ['last_week', 'last_month', 'last_year'];
