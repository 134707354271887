import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Modal from "../../../../common/components/Modal/Modal";
import moment from "moment";
import api from 'store/api';

const EmailTemplate = () => {
  const [isEditorOpen, setIsEditorOpen] = useState(true);
  const editorRef = useRef(null);
  const [initialData, setInitialData] = useState('');
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const fetchEmailTemplate = async () => {
    try {
      const response = await api.get('service/alert_system_setting_get_template_list/');
      const template = response.data[0].body;
      // const filteredText = filterTemplate(template, {
      //   current_date: moment(new Date()).format('YYYY-MM-DD'),
      //   number_of_hits: '123',
      //   service: 'Sample Service',
      //   alert_rule_name: 'Sample Alert Rule',
      //   match_detail_link: 'http://example.com',
      // });
      // console.log("Template data is", filteredText)
      setInitialData(template);
    } catch (error) {
      console.error('Failed to fetch email template:', error);
    }
  };

  useEffect(() => {
    fetchEmailTemplate();
  }, []);

  // const filterTemplate = (template, values) => {
  //   return template
  //     .replace(/\r\n/g, '<br />')
  //     .replace(/{current_date}/g, values.current_date)
  //     .replace(/{number_of_hits}/g, values.number_of_hits)
  //     .replace(/{service}/g, values.service)
  //     .replace(/{alert_rule_name}/g, values.alert_rule_name)
  //     .replace(/{match_detail_link}/g, values.match_detail_link);
  // };
  return (
    <>
      {isEditorOpen && (
        <Modal
          isOpen={true}
          title={"WYSIWYG Email Editor"}
          onClose={() => {
            setIsEditorOpen(false);
          }}
        >
          <div className="tw-px-3 tw-flex tw-flex-col tw-gap-4">
            <Editor
              apiKey="cktgkb5fo4d3hzvny71eyei4qmqywwi982j99mptx55qm9q8"
              onInit={(_evt, editor) => (editorRef.current = editor)}
              initialValue={`<div>${initialData}<div/>`}
              init={{
                height: 500,
                menubar: "file insert view  format table tools",
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks fontfamily fontsizeinput  | bold italic underline strikethrough  | forecolor | link image",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <div className="tw-flex tw-gap-2 tw-w-full tw-justify-end">
              <button
                className="tw-text-primary tw-bg-white tw-border tw-p-2 tw-rounded-lg"
                onClick={() => {}}
              >
                Switch to HTML
              </button>
              <button
                className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg "
                onClick={() => {}}
              >
                Save Changes
              </button>
            </div>
          </div>
        </Modal>
      )}

      <button
        onClick={() => setIsEditorOpen(true)}
        className="tw-rounded-lg tw-p-2 tw-bg-primary tw-text-white"
      >
        Show editor modal
      </button>
    </>
  );
};

export default EmailTemplate;
