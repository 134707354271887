import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DarknetSearchOptions } from 'common/components/SearchComponent/data';

import Heading from 'common/components/heading/Heading';
import Table from 'components/common/table/table.component';
import EyeIcon from 'common/Icons/EyeIcon';
import Delete from 'components/assets/svgs/Delete';
import Loader from 'common/components/Loader/Loader';

import SearchTipFeedback from '../../SearchTipFeedback';
import NoResult from 'common/components/NoResult/NoResult';
import HackerMarketDetail from './HackerMarketDetail';

import { refreshSearchResult } from 'store/slices/search/live/hackerForum';

import api from 'store/api';
import { isURL, capitalizeFirstLetter } from 'utils/global';
import { useSnackbar, useErrorbar } from 'utils/snackbar';

const HackerMarket = ({
  isResultView = false,
  showTip = true,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(refreshSearchResult());
  }, [dispatch]);

  const optionIndex = 1;
  const searchOptionLabel = DarknetSearchOptions[optionIndex].label;

  const [isDetailView, setIsDetailView] = useState(false);
  const [resultDetails, setDetails] = useState([]);
  const [isStopping, setIsStopping] = useState(-1);
  const [isDeleting, setIsDeleting] = useState(-1);

  const { runningTasks, list, loading, error, crawlers } = useSelector(
    (state) => state.search.live.hackerForum,
  );
  const size = 10;

  const handleShowDetail = (details) => {
    setDetails(details);
    setIsDetailView(true);
  };

  const handleStopRunningItem = async (item, rIndex) => {
    try {
      setIsStopping(rIndex);

      const ids = item.map((subitem) => subitem.celery_id).join();
      await api.delete('service/live_hf_search_result_delete/', {
        params: {
          celery_ids: ids,
        },
      });

      dispatch(refreshSearchResult());

      useSnackbar('The running search has been successfully stopped.');
    } catch (error) {
      useErrorbar(error.response.data || 'Failed to stop the running search.');
    } finally {
      setIsStopping(-1);
    }
  };

  const handleDeleteHistoryItem = async (item, rIndex) => {
    try {
      setIsDeleting(rIndex);

      const ids = item.map((subitem) => subitem.celery_id).join();
      await api.delete('service/live_hf_search_result_delete/', {
        params: {
          celery_ids: ids,
        },
      });

      dispatch(refreshSearchResult());

      useSnackbar('The search history item has been successfully deleted.');
    } catch (error) {
      useErrorbar(
        error.response.data || 'Failed to delete search history item.',
      );
    } finally {
      setIsDeleting(-1);
    }
  };

  // Hacker Marketplaces
  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      render: (_, records) => (
        <span className=" tw-text-[#44464A]">{records[0].date}</span>
      ),
    },
    {
      Header: 'Search keyword',
      accessor: 'searchKeyword',
      render: (_, records) => (
        <span className=" tw-text-[#44464A]">{records[0].search_keyword}</span>
      ),
    },
    {
      Header: 'Results',
      accessor: 'results',
      render: (_, records) => {
        const successCount = records.filter(
          (record) => record.state === 'SUCCESS',
        ).length;
        const foundCount = records.filter(
          (record) => record.state === 'SUCCESS' && isURL(record.res),
        ).length;
        const failedCount = records.length - successCount;

        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span>Found {foundCount} result&#40;s&#41;</span>
            <span>Successfully processed {successCount} forum&#40;s&#41;</span>
            <span>Error {failedCount} forum&#40;s&#41;</span>
          </div>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'action',
      render: (_, records, rowIndex) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button onClick={() => handleShowDetail(records)}>
              <EyeIcon color="#71747C" />
            </button>
            <button
              onClick={() => handleDeleteHistoryItem(records, rowIndex)}
              disabled={isDeleting !== -1}
            >
              {isDeleting === rowIndex ? (
                <Loader className="tw-text-[#71747C] !tw-w-5 !tw-h-5 tw-ms-2" />
              ) : (
                <Delete color="#71747C" />
              )}
            </button>
          </div>
        );
      },
    },
  ];

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  // Function to match the heights of child divs
  const matchHeights = () => {
    try {
      const section1 = section1Ref.current;
      const section2 = section2Ref.current;

      const height = section1.offsetHeight;
      section2.setAttribute('style', `height:${height}px !important`);
    } catch (err) {
      // Resume
    }
  };

  useEffect(() => {
    matchHeights();
    window.addEventListener('resize', matchHeights);

    return () => window.removeEventListener('resize', matchHeights);
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isDetailView, runningTasks, list]);

  if (!isResultView && list.length === 0) {
    return <NoResult />;
  }

  return (
    <>
      {showTip && (
        <div className="tw-mb-4">
          <SearchTipFeedback optionIndex={optionIndex} />
        </div>
      )}

      <div className="tw-grid tw-grid-rows-1 tw-grid-cols-5 tw-gap-4">
        <div
          className="tw-col-span-5 lg:tw-col-span-4 tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4"
          ref={section1Ref}
        >
          {!isDetailView ? (
            <>
              {/* --- Current Search --- */}
              {isResultView && runningTasks.length > 0 && (
                <>
                  <h1 className="tw-text-lg tw-font-bold">
                    {`${searchOptionLabel} Current Search`}
                  </h1>
                  <div>
                    <div className="tw-bg-[#F4F7FC] tw-flex tw-justify-between tw-w-full tw-py-3 tw-px-3 tw-font-medium tw-text-sm tw-text-[#0B0B0B]">
                      <span>Status</span>
                      <span>Search Keyword</span>
                      <span>Action</span>
                    </div>

                    {runningTasks.map((task, index) => (
                      <div className="tw-flex tw-justify-between tw-w-full tw-py-3 tw-px-3 tw-border-b tw-border-[#F2F4F7] tw-text-sm">
                        <span
                          className={
                            'tw-capitalize ' +
                            (task[0].state === 'PENDING'
                              ? 'tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                              : 'tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]')
                          }
                        >
                          {capitalizeFirstLetter(task[0].state)}
                        </span>

                        <span className="tw-font-normal tw-text-[#44464A]">
                          {task[0].search_keyword
                            ? task[0].search_keyword
                            : 'NO KEYWORD'}
                        </span>

                        <button
                          onClick={() => handleStopRunningItem(task, index)}
                          disabled={isStopping !== -1}
                        >
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <span
                              className={
                                'tw-whitespace-nowrap tw-text-sm tw-font-bold tw-text-[#F03A3A]'
                              }
                            >
                              Stop
                            </span>
                            {isStopping === index && (
                              <Loader className="tw-text-[#71747C] !tw-w-5 !tw-h-5 tw-ms-2" />
                            )}
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {/* --- History --- */}
              <h1 className="tw-text-lg tw-font-bold tw-mt-4">
                {`${searchOptionLabel} Search History`}
              </h1>

              <Table
                data={list}
                columns={columns}
                paginatorLabel="URL Shorteners"
                rowsPerPage={size}
              />
            </>
          ) : (
            <HackerMarketDetail
              searchOptionLabel={searchOptionLabel}
              details={resultDetails}
              setIsDetailView={setIsDetailView}
            />
          )}
        </div>

        <div
          className="tw-col-span-5 lg:tw-col-span-1 tw-bg-white tw-p-4 tw-rounded-lg tw-overflow-auto !tw-h-0"
          ref={section2Ref}
        >
          <Heading size="h3" text="Site Status" />

          <div className="tw-mt-4 tw-relative">
            <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-h-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
              <span>Site</span>
              <span className="tw-pr-[14px]">Status</span>
            </div>
            <div className="tw-overflow-y-auto">
              {crawlers.map((crawler, index) => {
                return (
                  <div
                    className="tw-w-full tw-flex tw-flex-row lg:tw-flex-col min-[1366px]:tw-flex-row tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                    key={index}
                  >
                    <span className="tw-text-[#5B5D63] tw-text-sm">
                      {crawler.front_name}
                    </span>
                    <span
                      className={
                        crawler.has_error
                          ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                          : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                      }
                    >
                      {crawler.has_error ? 'FAILED' : 'SUCCESS'}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackerMarket;
