import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { DarknetSearchOptions } from 'common/components/SearchComponent/data';
import ProgressBar from 'common/components/ProgressBar/ProgressBar';

import {
  setSelectedOption as setSelectedOptionId,
  setCurrentSearchParams,
  setLastSearchParams,
  setSearching,
} from 'store/slices/search/live/main';

import { searchActions } from 'store/actions';

import {
  search as searchHackerForum,
  crawlerSearch as getHFCrawler,
} from 'store/slices/search/live/hackerForum';
import { searchRequest as searchSocialNetwork } from 'store/slices/search/live/socialNetwork';
import { searchRequest as searchExternalSource } from 'store/slices/search/live/externalSource';
import { searchRequest as searchDataPaste } from 'store/slices/search/live/dataPaste';

import { useSnackbar, useErrorbar } from 'utils/snackbar';

import SearchComponent from './SearchComponent';
import SearchResult from './SearchResult';
import NoResult from 'common/components/NoResult/NoResult';
import HackerMarket from './components/hacker/HackerMarket';
import SocialNetworks from './components/social/SocialNetworks';
import ExternalSources from './components/external/ExternalSources';
import DataPastes from './components/data-pastes/DataPastes';

const DarknetLiveSearch = () => {
  const dispatch = useDispatch();

  const licenseInfo = useSelector((state) => state.user.license);
  const searchLive = useSelector((state) => state.search.live);
  const { selectedOptionId, currentOptionId, currentQuery, lastSearchOptionId, isSearching } =
    searchLive.main;

  const features = [
    {
      id: 2,
      premium: true,
      label: 'Hacker Forum Search',
      subLabel:
        'Locate specific keywords within hacker forums using the integrated search function',
      licenseTitle: 'Live Hacker Forum Search',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: searchLive.hackerForum,
      searchFunc: searchHackerForum,
    },
    {
      id: 3,
      premium: true,
      label: 'Social Media Search',
      subLabel: 'Search for your keyword on public social media forums',
      licenseTitle: 'Social Media Spoofing Detection',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: searchLive.socialNetwork,
      searchFunc: searchSocialNetwork,
    },
    {
      id: 4,
      premium: true,
      label: 'Tor and I2P Search',
      subLabel: 'Discover if your keyword appears on any Tor or I2P sites',
      errorLabel: "Please enter a valid domain or a person's name (last name or first name).",
      licenseTitle: 'Dark Web Live Search Engine',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: searchLive.externalSource,
      searchFunc: searchExternalSource,
      // get searchFunc() {
      //   return selectedOption.id === 1 ? searchExternalSource : searchRequestSimple;
      // },
    },
    {
      id: 5,
      premium: true,
      label: 'Paste Search',
      subLabel: 'Utilize Google to find indexed data on paste sites',
      errorLabel: 'Please enter a valid app name.',
      licenseTitle: 'Paste Site Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: searchLive.dataPaste,
      searchFunc: searchDataPaste,
    },
    {
      id: 6,
      premium: true,
      label: 'Telegram Search',
      subLabel: 'Look for your keyword in hacker-related Telegram channels',
      licenseTitle: 'Telegram & Discord Channel Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: null,
      searchFunc: null,
    },
    {
      id: 7,
      premium: true,
      label: 'Discord Search',
      subLabel: 'Search for your keyword in Discord channels',
      licenseTitle: 'Telegram & Discord Channel Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: null,
      searchFunc: null,
    },
  ];

  const [autoSearch, setAutoSearch] = useState(false);
  const [selectedOption, setSelectedOption] = useState(DarknetSearchOptions[selectedOptionId]);
  const [resultOption, setResultOption] = useState(DarknetSearchOptions[selectedOptionId]);
  const [appliedForums, setAppliedForums] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalResult, setTotalResult] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    dispatch(getHFCrawler());
    // Parse the params
    // const type = searchParams.get('type');
    // const query = searchParams.get('query');
    // const _appliedForums = searchParams.get('forums');

    // if (type && query) {
    //   setSelectedOption(
    //     DarknetSearchOptions.find((option) => option.id == type),
    //   );
    //   setSearchTerm(query);

    //   if (_appliedForums)
    //     setAppliedForums(
    //       forums.filter((forum) =>
    //         _appliedForums.split(',').includes(forum.id.toString()),
    //       ),
    //     );

    //   setAutoSearch(true);
    // }

    if (isSearching) {
      setSearchTerm(currentQuery);
    } else {
      setSearchTerm('');
      dispatch(searchActions.resetProgress('Live'));
    }
    // setShowResult(true);
  }, [dispatch]);

  // useEffect(() => {
  //   if (autoSearch) {
  //     handleLiveSearch({
  //       option: selectedOption,
  //       searchTerm: searchTerm,
  //       selectedForums: appliedForums,
  //     });
  //   }
  // }, [autoSearch]);

  useEffect(() => {
    if (isSearching) {
      let totalResult = 0;
      let finished = true,
        isError = false;

      features.forEach((item) => {
        if (item.active && item.store) {
          totalResult += item.store.totalElements;
          finished &= !item.store.loading;
          isError |= item.store.error !== null;
        }
      });

      setTotalResult(totalResult);
      if (finished) {
        if (selectedOption.id === 1 || (selectedOption.id !== 1 && !isError)) {
          useSnackbar(
            `${DarknetSearchOptions[currentOptionId].label} search has been successfully completed.`,
          );
        } else {
          useErrorbar(
            `An error occurred during the ${DarknetSearchOptions[currentOptionId].label} search.`,
          );
        }

        setResultOption(DarknetSearchOptions[currentOptionId]);
        dispatch(setSearching(false));
        dispatch(searchActions.resetProgress('Live'));
        dispatch(setLastSearchParams());

        if (!showResult) {
          setShowResult(true);
        }
      }
    }
  }, [searchLive]);

  useEffect(() => {
    setSelectedOption(DarknetSearchOptions[selectedOptionId]);
  }, [selectedOptionId]);

  const handleOptionChange = (option) => {
    dispatch(
      setSelectedOptionId(DarknetSearchOptions.findIndex((_option) => _option.id === option.id)),
    );

    if (!isSearching) {
      setSearchTerm('');
      dispatch(searchActions.resetProgress('Live'));
    }
  };

  // Ref to store the AbortController instance
  const abortControllerRef = useRef(null);

  const handleLiveSearch = ({ searchTerm, selectedForums, isValidateResult }) => {
    setSearchTerm(searchTerm);

    setTotalResult(0);
    dispatch(setSearching(true));

    dispatch(
      setCurrentSearchParams({
        optionId: selectedOptionId,
        query: searchTerm,
      }),
    );

    // Abort the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;

    features.forEach((feature) => {
      let modifiedQuery = searchTerm;

      if (feature.active) {
        if (feature.searchFunc) {
          if (feature.id === 2) {
            const forum = selectedForums.map((f) => f.name).join(',');
            dispatch(feature.searchFunc({ forum, query: searchTerm }));
          } else if (feature.id === 4) {
            dispatch(
              feature.searchFunc({
                query: searchTerm,
                validate: isValidateResult,
                signal: newAbortController.signal,
              }),
            );
          } else {
            dispatch(
              feature.searchFunc({
                page: 0,
                query: modifiedQuery,
                signal: newAbortController.signal,
              }),
            );
          }
        }
      }
    });

    if (selectedOption.id === 2 || selectedOption.id === 4) {
      setTimeout(() => {
        setShowResult(true);
      }, 1000);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-p-2">
      <h1 className="tw-text-xl tw-font-bold tw-leading-8 tw-text-left">
        Live Search in the deep and dark web
      </h1>

      {showResult ? (
        <SearchResult
          setSearch={setShowResult}
          selectedOption={selectedOption}
          searchTerm={searchTerm}
        />
      ) : (
        <>
          <SearchComponent
            darknet={true}
            searchTerm={searchTerm}
            onSearch={handleLiveSearch}
            onOptionChange={handleOptionChange}
            defaultOption={selectedOption}
            defaultForums={appliedForums}
            loading={isSearching}
          />
          <div className="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6">
            <div className="tw-w-full">
              {selectedOption.id === 1 ? (
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                      Only Premium
                    </div>
                    <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left tw-hidden"></div>
                  </div>
                  <div className="tw-w-full tw-rounded-xl tw-bg-[#ffffff] tw-px-6 tw-pt-6">
                    {features.map((item, index) => (
                      <div className="tw-flex tw-flex-col tw-mb-6" key={index}>
                        <ProgressBar
                          premium={item.premium}
                          label={item.label}
                          disabled={!item.active}
                          height="tw-h-[6px]"
                          subLabel={item.subLabel}
                          current={item.store ? item.store.progress : 0}
                          backgroundColor={
                            item.store ? (item.store.error ? '#dc2626' : '#ECC551') : '#ECC551'
                          }
                          trackColor="tw-bg-[#EFF0F2]"
                          className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                          subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : selectedOption.id === 2 ? (
                <HackerMarket isResultView={false} />
              ) : selectedOption.id === 3 ? (
                <SocialNetworks isResultView={false} />
              ) : selectedOption.id === 4 ? (
                <ExternalSources setSearch={setShowResult} isResultView={false} />
              ) : selectedOption.id === 5 ? (
                <DataPastes isResultView={false} />
              ) : (
                <NoResult />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DarknetLiveSearch;
