import React from 'react';

const Checkbox = ({ label, checked = false, onChange }) => {
  return (
    <div>
      <div className="tw-flex tw-items-center tw-px-2">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange && onChange(e.target.checked)}
          className="tw-mr-2 custom-checkbox"
        />
        <span className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#44464A]">
          {label}
        </span>
      </div>
    </div>
  );
};

export default Checkbox;
