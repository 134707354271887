import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import ReadMore from 'common/components/ReadMore/ReadMore';
import Loading from 'common/components/Loading/Loading';
import useExport from 'hooks/use-export';
import { search as searchRansomware } from 'store/slices/search/database/ransomware';

import SearchResultTitle from '../SearchResultTitle';
import SearchResultError from '../SearchResultError';

const ThreatActor = ({ selectedOptionLabel, title, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    query,
    list: ransomwares,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
    error,
  } = useSelector((state) => state.search.database.ransomware);
  
  const [isPageSearch, setIsPageSearch] = useState(false);

  const setCurrentPage = (page) => {
    setIsPageSearch(true);

    dispatch(
      searchRansomware({
        page: page - 1,
        progress: false,
      }),
    );
  };

  const defaultRenderer = (value) => (
    <span className="tw-whitespace-nowrap tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Created',
      accessor: 'created_at',
      render: (value) => (
        <span className="tw-whitespace-nowrap tw-text-[#44464A]">
          {value ? moment(value).format('YYYY-MM-DD') : '—'}
        </span>
      ),
    },
    {
      Header: 'Company',
      accessor: 'company',
      render: (value) => <span className="tw-text-[#00B6FF]">{value}</span>,
    },
    {
      Header: 'Malware Type',
      accessor: 'groupname',
      render: defaultRenderer,
    },
    {
      Header: 'Region',
      accessor: 'region',
      render: defaultRenderer,
    },
    {
      Header: 'Country',
      accessor: 'country',
      render: defaultRenderer,
    },
    {
      Header: 'Industry',
      accessor: 'industry_type',
      render: defaultRenderer,
    },
    {
      Header: 'Details',
      accessor: 'description',
      render: (value) => {
        return <ReadMore text={value} size={200} />;
      },
    },
  ];

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'ransomware', size, searchTerm });

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!(loading && !isPageSearch) ? (
        <>
          <SearchResultTitle
            selectedOptionLabel={selectedOptionLabel}
            title={title}
          />

          <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-z-20">
            <div className="tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search results
                </span>
                <span className="tw-text-sm tw-font-medium">
                  {totalElements}
                </span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search term
                </span>
                <span className="tw-text-sm tw-font-medium">{query}</span>
              </div>
            </div>
            <ExportButton
              onChange={handleExportOptionChange}
              isLoading={exporting}
              disabled={!canExport}
            />
          </div>

          {error ? (
            <SearchResultError errorText={error} />
          ) : (
            <div className="tw-relative">
              <Table
                data={ransomwares}
                columns={columns}
                selectable={true}
                onRowSelection={handleRowSelection}
              />
              <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
                <div className={loading ? 'tw-invisible' : 'tw-visible'}>
                  <p className="tw-my-4">
                    Showing ransomwares:{' '}
                    {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                    {currentPage * size > totalElements
                      ? totalElements
                      : currentPage * size}{' '}
                    of {totalElements}
                  </p>
                </div>

                {totalPages > 1 && (
                  <Paginator
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                )}
              </div>
              {loading && (
                <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
              )}
            </div>
          )}
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default ThreatActor;
