import React, { useEffect, useState} from "react";
import Heading from "../../common/components/heading/Heading";
import { useErrorbar, useSnackbar } from "utils/snackbar";
import api from 'store/api';

import CustomTable from "../common/table/table.component";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import CustomButton from "../../common/components/Button/Button";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowDown } from "react-icons/io";

import SelectGroup from "../../common/components/SelectDropdown/SelectDropdown";
import MultiSelectGroup from "../../common/components/SelectDropdown/MultipleSelectDropdown";
import { useForm, useFieldArray } from "react-hook-form";
import Modal from "../../common/components/Modal/Modal.js";
import GoBack from "../assets/svgs/GoBack.js";
import Cross from "../../common/Icons/Cross.js";
import PencilIcon from "../../common/Icons/PencilIcon.js";
import DeleteIcon from "../../common/Icons/DeleteIcon.js";
import PlusIcon from "../../common/Icons/PlusIcon.js";
import SettingsIcon from "../assets/svgs/Setting.js";
import Delete from "../assets/svgs/Delete.js";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../common/components/Loader/Loader";
import CustomModel from 'common/components/Model/Model';

export default function AlertingRules() {
  const [serverList, setServerLIst] = useState(null);
  const [emailRecipientList, setEmailRecipientList] = useState(null);
  const [emailSenderList, setEmailSenderList] = useState(null);
  const [emailTemplateList, setEmailTemplateList] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);

  const [isNewAlertnigOpen, setIsNewAlertnigOpen] = useState(false);
  const [isNewExpertRuleOpen, setIsNewExpertRuleOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [ruleList, setRuleList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);

  // For simple alert rule creation
  const [simpleService, setSimpleService] = useState('');
  const [simpleEmailRecipient, setSimpleEmailRecipient] = useState('');
  const [simpleBugName, setSimpleBugName] = useState('');

  const handleSimpleBugNameChange = (e) => {
    setSimpleBugName(e.target.value); // Update state when input changes
  };
  // For simple alert rule creation

  // For expert alert rule creation
  const [expertRuleName, setExpertRuleName] = useState('');
  const [expertRuleType, setExpertRuleType] = useState('');
  const [expertAlertKeyword, setExpertAlertKeyword] = useState('');
  const [expertAlertCondition, setExpertAlertCondition] = useState('');
  const [expertEmailRecipient, setExpertEmailRecipient] = useState('');
  const [expertEmailSender, setExpertEmailSender] = useState('');
  const [expertEmailTemplate, setExpertEmailTemplate] = useState('');
  const [expertFilters, setExpertFilters] = useState([]);
  const [expertTags, setExpertTags] = useState([]);

  const handleExpertRuleNameChange = (e) => {
    setExpertRuleName(e.target.value); // Update state when input changes
  };

  const handleExpertAlertKeywordChange = (e) => {
    setExpertAlertKeyword(e.target.value); // Update state when input changes
  };

  const handleExpertAlertConditionChange = (e) => {
    setExpertAlertCondition(e.target.value); // Update state when input changes
  };

  const filterTags = (service, filters) => {
    const filtered = tagsList.filter((tag) => tag.service === service && filters.includes(tag.id))
                             .flatMap((tag) => tag.tags)
                             .map((tagItem) => ({ label: tagItem, value: tagItem }));
    console.log('selected filtered tags are --->', filtered);
    setFilteredTags(filtered);
  }
  
  // For expert alert rule creation

  const onHandleDelete = (record) => {
      setModalOpen(true);
      setDeleteRecord(record);
    };

  const confirmDelete = async () => {
    //setIsAPICalling(true);
    const result = await api.delete(`service/alert_system_alert_delete_alert_rule/${deleteRecord.id}`);
    //setIsAPICalling(false);
    setModalOpen(false);
  
    if (result.status === 200) {
      useSnackbar("Deleted the report successfully!");
      initializeData();
    } else {
      useErrorbar("Delete report failed!");
    }
  };
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      render: (_, date) => {
        return <span className="tw-whitespace-nowrap">{date.date}</span>;
      },
    },
    {
      Header: "Keywords",
      accessor: "query",
    },
    { Header: "Types", accessor: "service" },

    {
      Header: "Actions",
      accessor: "action",
      render: (_, record) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button>
              <SettingsIcon color="#71747C" />
            </button>
            <button onClick={() => onHandleDelete(record)}>
              <Delete color="#71747C" />
            </button>
          </div>
        );
      },
    },
  ];

  // const data = [
  //   {
  //     date: "2024-05-21",
  //     alertName: "Unusual login attempt",
  //     keywords: "spoofguard, domain.com",
  //     types: "leak",
  //     status: "In Progress",
  //     alertExtract:
  //       "peter@domain.com:passwd23; https://login.ebanding.com/changepwd:User...",
  //   }
  // ];
  const fetchEmailRecipientList = async () => {
    try {
      const response = await api.get('service/alert_system_setting_get_recipient_list/');
      const emailData = response.data.map((item) => ({
        value: item.id,
        label: item.email
      }));
      setEmailRecipientList(emailData);
    } catch (error) {
      console.error('Error fetching email list:', error);
      return {};
    }    
  }

  const fetchEmailSenderList = async () => {
    try {
      const response = await api.get('service/alert_system_setting_get_sender_list/');
      const emailData = response.data.map((item) => ({
        value: item.id,
        label: item.email
      }));
      setEmailSenderList(emailData);
    } catch (error) {
      console.error('Error fetching email sender list:', error);
      return {};
    }    
  }

  const fetchEmailTemplateList = async () => {
    try {
      const response = await api.get('service/alert_system_setting_get_template_list/');
      const emailData = response.data.map((item) => ({
        value: item.id,
        label: item.name
      }));
      setEmailTemplateList(emailData);
    } catch (error) {
      console.error('Error fetching email template list:', error);
      return {};
    }    
  }

  const fetchWebhookList = async () => {
    try {
      const response = await api.get('service/alert_system_setting_get_webhook_list/');
      const webhookData = response.data.reduce((acc, service) => {
        acc[service.id] = service.url;
        return acc;
      }, {});
      return webhookData;
    } catch (error) {
      console.error('Error fetching webhookData:', error);
      return {};
    }    
  }

  const fetchFilterList = async () => {
    try {
      const response = await api.get('service/alert_system_service_get_filter_list/');
      const filterData = response.data.map((item) => ({
        value: item.id,
        label: item.name
      }));
      const tagData = response.data.map((item) => ({
        id: item.id,
        service: item.service,
        tags: item.valid_tags
      }));
      setFilterList(filterData);
      setTagsList(tagData);
    } catch (error) {
      console.error('Error fetching filter Data:', error);
      return {};
    }    
  }

  const fetchServiceList = async () => {
    try {
      const response = await api.get('service/alert_system_service_get_service_list/');
      const serviceData = response.data.reduce((acc, service) => {
        acc[service.id] = service.name;
        return acc;
      }, {});
      const serverOptions = response.data.map(service => ({
        value: service.id,
        label: service.name
      }));
      setServerLIst(serverOptions);
      return serviceData;
    } catch (error) {
      console.error('Error fetching service list:', error);
      return {};
    }    
  }

  const fetchAlertRuleList = async (serviceData) => {
    try {
      setLoading(true);
      const response = await api.get(`service/alert_system_alert_get_alert_rule_list/`);
			const responseData = response.data.map((item, index) => ({
        id: item.id,
				date: item.last_run ? item.last_run.split('T')[0] : new Date().toISOString().split('T')[0],
				name: item.name,
				query: item.query,
				service: serviceData[item.service] || item.service
			}));
      return responseData;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
	}

  // Simple Alert Rule Post
  const simpleAlertRuleSave = async () => {
    try {
      const response = await api.post(`service/alert_system_alert_create_alert_rule/`,
        {
          name : simpleBugName,
          query : "spoofguard",
          service : simpleService,
          recipient : simpleEmailRecipient,
          sender : 4,
          email_template : 4,
          webhook : null,
          filters_applied : [],
          tags : []
        },
        
      );
      useSnackbar(response.data.error);
    } catch (error) {
      const errorData = error.response.data;
      const formattedError = formatErrorData(errorData);
      console.log(formattedError)
      useSnackbar(formattedError, 'error');
    }
  }

  // Expert Alert Rule Post
  const expertAlertRuleSave = async () => {
    try {
      const response = await api.post(`service/alert_system_alert_create_alert_rule/`,
        {
          name : expertRuleName,
          query : expertAlertKeyword,
          service : expertRuleType,
          recipient : expertEmailRecipient,
          sender : expertEmailSender,
          email_template : expertEmailTemplate,
          webhook : null,
          filters_applied : expertFilters,
          tags : expertTags
        },
        
      );
      useSnackbar(response.data.error);
    } catch (error) {
      const errorData = error.response.data;
      const formattedError = formatErrorData(errorData);
      console.log(formattedError)
      useSnackbar(formattedError, 'error');
    }
  }

  const formatErrorData = (errorData) => {
    if (typeof errorData === 'object' && errorData !== null) {
      if ('error' in errorData) {
        return errorData.error;
      }
  
      let errorMessage = '';
      for (const [key, value] of Object.entries(errorData)) {
        if (Array.isArray(value)) {
          value.forEach((message) => {
            errorMessage += `${key}: ${message}\n`;
          });
        }
      }
      return errorMessage.trim(); 
    }
    return errorData;
  };
  // Simple Alert Rule Post

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      alertKeywords: [{ domainNameIp: "", condition: "" }],
      filters: [{ leakTag: "", excludeAccounts: "" }],
    },
  });

  const onSubmit = (data) => {
    setIsNewExpertRuleOpen(false)
    console.log(data);
  };

  const location = useLocation();

  const { isNewAlertingOpen: dashboardALertState } = location.state || {};
  
  const initializeData = async () => {
    const serviceData = await fetchServiceList();
    await fetchEmailRecipientList();
    await fetchEmailSenderList();
    await fetchEmailTemplateList();
    await fetchFilterList();
    const ruleListData = await fetchAlertRuleList(serviceData);
    setRuleList(ruleListData);
  };

  useEffect(() => {
    if (dashboardALertState) {
      setIsNewAlertnigOpen(dashboardALertState);
    }
    initializeData();
  }, []);

  useEffect(() => {
    filterTags(expertRuleType, expertFilters);
  }, [expertFilters, expertRuleType]);

  const {
    fields: alertKeywordsFields,
    append: appendAlertKeyword,
    remove: removeAlertKeyword,
  } = useFieldArray({
    name: "alertKeywords",
    control,
  });
  const {
    fields: filtersFields,
    append: appendFiltersFields,
    remove: removeFiltersFields,
  } = useFieldArray({
    name: "filters",
    control,
  });

  return (
    <>
      {isNewAlertnigOpen ? (
        <div>
          <div
            className="tw-flex tw-gap-2 tw-items-center hover:tw-cursor-pointer tw-mb-3"
            onClick={() => setIsNewAlertnigOpen(false)}
          >
            <GoBack />
            <div className="tw-flex tw-gap-1">
              <Heading size="h3" text="New Alerting Rule" />
              <p className="tw-text-[#71747C]"> (Simple Setup)</p>
            </div>
          </div>
          <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-w-full">
            <div className="tw-flex tw-items-center tw-gap-4 tw-w-full tw-border-b tw-border-[#DFE0E3] tw-pb-4 tw-flex-col md:tw-flex-row">
              <span className="tw-whitespace-nowrap">Notify me, when the</span>
              <InputGroup
                // labelText="Phone"
                inputType="text"
                placeholder="Bug Type"
                inputName="bug"
                //register={register}
                errors={errors}
                focusOnType={true}
                value={simpleBugName}
                onChange={handleSimpleBugNameChange}
              />
              <span className="tw-whitespace-nowrap">appears in</span>
              <SelectGroup
                htmlfor="attackType"
                // labelText="Country"
                selectTitle="Select Type"
                inputName="attackType"
                register={register}
                errors={errors}
                options={serverList}
                onChange={(value) => {
                    setSimpleService(value);}}
                isLoading={false}
                className="md:tw-w-[200px] tw-w-[215px]"
              />
              <span className="tw-whitespace-nowrap">
                and send the notification to
              </span>
              <SelectGroup
                htmlfor="emailReceiver"
                // labelText="Country"
                selectTitle="Select Receiver"
                inputName="emailReceiver"
                register={register}
                errors={errors}
                options={emailRecipientList}
                onChange={(value) => {
                  setSimpleEmailRecipient(value);}}
                isLoading={false}
                className="md:tw-w-[200px] tw-w-[215px]"
              />
              <button
                className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg md:tw-w-[60px] tw-h-[40px] tw-w-[215px] tw-ml-auto tw-ml-auto-md-none"
                onClick={simpleAlertRuleSave}
              >
                Save
              </button>
            </div>

            <div className="tw-pt-4 tw-flex tw-items-center tw-justify-between tw-flex-col md:tw-flex-row tw-gap-4 md:tw-gap-0">
              <span>Need more sophisticated alerting Rules?</span>
              <button
                className="tw-flex tw-items-center tw-gap-[4px] tw-border tw-border-primary tw-bg-white tw-text-primary  tw-p-2 tw-rounded-lg  md:tw-w-max tw-font-semibold "
                onClick={() => setIsNewExpertRuleOpen(true)}
              >
                New Expert Rule Setup
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="tw-mt-4 tw-flex md:tw-flex-row tw-justify-between tw-items-center tw-px-6 tw-flex-col">
            <Heading size="h2" text="Alerting Rules" />
            <button
              className="tw-bg-primary tw-w-full tw-text-white tw-p-2 tw-rounded-lg md:tw-max-w-[150px] md:tw-font-semibold"
              onClick={() => setIsNewAlertnigOpen(true)}
            >
              New Alerting Rule
            </button>
          </div>

          <div className="tw-py-4 md:tw-px-6 ">
            <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 ">
              {loading ? <div className="tw-w-full tw-flex tw-justify-center tw-pt-20"><Loader /></div> : <CustomTable data={ruleList} columns={columns} selectable={true} />}
            </div>
            <CustomModel isOpen={isModalOpen}>
              <div className="tw-flex tw-flex-col tw-items-center">
                <h2 className="tw-text-xl tw-font-bold tw-mb-4">Confirm Delete</h2>
                <p className="tw-mb-4">Are you sure you want to delete this report?</p>
                <div className="tw-flex tw-gap-4">
                  <button
                    onClick={confirmDelete}
                    className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setModalOpen(false)}
                    className="tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
                  >
                    No
                  </button>
                </div>
              </div>
            </CustomModel>
          </div>
        </>
      )}
      {isNewExpertRuleOpen && (
        <>
          <div className="tw-flex tw-flex-col tw-gap-6 md:tw-w-[540px] tw-bg-white tw-absolute tw-top-0 tw-right-0 tw-p-6 tw-overflow-y-auto tw-h-[100%] tw-shadow-lg">
            <div className="tw-flex tw-items-center tw-justify-between">
              <span className="tw-text-lg tw-font-bold">
                Expert Alerting Rule Setup
              </span>
              <button onClick={() => setIsNewExpertRuleOpen(false)}>
                <Cross />
              </button>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                Step 1 : New Alerting Rule (Expert Setup)
              </span>
              <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                <div className="tw-flex tw-items-end tw-gap-3">
                  <InputGroup
                    labelText="Rule Name"
                    inputType="text"
                    placeholder="Enter rule name"
                    inputName="ruleName"
                    //register={register}
                    errors={errors}
                    focusOnType={true}
                    className=""
                    value={expertRuleName}
                    onChange={handleExpertRuleNameChange}
                  />
                  <button>
                    <Cross />
                  </button>
                </div>

                {step === 1 && (
                  <button
                    className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                    onClick={() => setStep(2)}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {step >= 2 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 2 : Define Rule Alert Type
                </span>
                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                  <div className="tw-flex tw-items-end tw-gap-3">
                    <SelectGroup
                      htmlfor="ruleAlert"
                      labelText="Rule Alert"
                      inputName="ruleAlert"
                      selectTitle="Select Type"
                      register={register}
                      errors={errors}
                      options={serverList}
                      onChange={(value) => {
                        setExpertRuleType(value);}}
                      isLoading={false}
                      className="tw-w-full"
                    />
                    <button>
                      <Cross />
                    </button>
                  </div>
                  {step === 2 && (
                    <button
                      className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                      onClick={() => setStep(3)}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}
            {step >= 3 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 3 : Define your Alert Keyword
                </span>

                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                  <InputGroup
                    labelText="Alert Keyword"
                    inputType="text"
                    placeholder="One keyword rule a Domain, IP or email domain"
                    inputName="alertKeyword"
                    //register={register}
                    errors={errors}
                    focusOnType={true}
                    className="tw-m-2"
                    value={expertAlertKeyword}
                    onChange={handleExpertAlertKeywordChange}
                  />
                  <InputGroup
                    labelText="Add Condition"
                    inputType="text"
                    placeholder="Keyword"
                    inputName="alertKeyword"
                    //register={register}
                    errors={errors}
                    focusOnType={true}
                    className=""
                    value={expertAlertCondition}
                    onChange={handleExpertAlertConditionChange}
                  />    

                  {step === 3 && (
                    <button
                      className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                      onClick={() => setStep(4)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            )}

            {step >= 4 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 4 : Add Filters (Only for Leaks from database Query)
                </span>
                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                <div className="tw-flex tw-items-end tw-gap-3">
                    <MultiSelectGroup
                      htmlfor="ruleAlert"
                      labelText="Add Special Filters"
                      inputName="ruleAlert"
                      register={register}
                      errors={errors}
                      options={filteredTags}
                      onChange={(value) => {
                        setExpertTags(value);
                      }}
                      isLoading={false}
                      className="tw-w-full"
                    />
                    <button>
                      <Cross />
                    </button>
                  </div>
                  <div className="tw-flex tw-items-end tw-gap-3">
                    <MultiSelectGroup
                      htmlfor="ruleAlert"
                      labelText="Include only results with the following Leaks Tag(s)"
                      inputName="ruleAlert"
                      register={register}
                      errors={errors}
                      options={filterList}
                      onChange={(value) => {
                        setExpertFilters(value)}
                      }
                      isLoading={false}
                      className="tw-w-full"
                    />
                    <button>
                      <Cross />
                    </button>
                  </div>
                  
                  {step === 4 && (
                    <button
                      className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                      onClick={() => setStep(5)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            )}

            {step >= 5 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 5 : Define Email Alerting Settings
                </span>
                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                  <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">
                    <SelectGroup
                      htmlfor="Recipient"
                      labelText="Recipient"
                      inputName="recipient"
                      selectTitle="Select Receiver"
                      register={register}
                      errors={errors}
                      options={emailRecipientList}
                      onChange={(value) => {
                        setExpertEmailRecipient(value);}}
                      placeholder="Select"
                      className="tw-w-full"
                      isLoading={false}
                    />
                    <SelectGroup
                      htmlfor="Sender"
                      labelText="Sender"
                      inputName="Sender"
                      selectTitle="Select Sender"
                      register={register}
                      errors={errors}
                      options={emailSenderList}
                      onChange={(value) => {
                        setExpertEmailSender(value);}}
                      placeholder="Select"
                      className="tw-w-full"
                      isLoading={false}
                    />
                    <SelectGroup
                      htmlfor="Email Template"
                      labelText="Email Template"
                      inputName="Email Template"
                      selectTitle="Select Template"
                      register={register}
                      errors={errors}
                      options={emailTemplateList}
                      onChange={(value) => {
                        setExpertEmailTemplate(value);}}
                      placeholder="Select"
                      className="tw-w-full"
                      isLoading={false}
                    />
                  </div>
                </div>
              </div>
            )}

            {step >= 5 && (
              <div className="tw-flex tw-gap-4 tw-items-center tw-mt-6">
                <button
                  className="tw-bg-primary tw-text-white tw-p-3 tw-rounded-lg"
                  onClick={expertAlertRuleSave}
                >
                  Submit
                </button>
                <button
                  className="tw-text-primary tw-bg-white tw-border tw-p-3 tw-rounded-lg"
                  onClick={() => setIsNewExpertRuleOpen(false)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
