import moment from 'moment/moment';
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Tooltip } from "react-tooltip";
import api from 'store/api';
import {ArrowUpSide, InfoIcon, ArrowDown, ArrowBack} from '../../../common/Icons';
import NoResultIcon from '../../../common/Icons/NoResultIcon';
import { aggregateDataPerMonth } from 'utils/global';

function DarkNet({selectedDomain, defaultCompany, setDefaultCompany}) {
  const endYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(endYear);
  const [selectedYear, setSelectedYear] = useState(endYear);
  const [selectedYearScores, setSelectedYearScores] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [openYearDropDown, setOpenYearDropDown] = useState(false);
  const [detailDarknetScore, setDetailDarknetScore] = useState({});
  const [maxRiskScore, setMaxRiskScore] = useState(-Infinity);
  const [minRiskScore, setMinRiskScore] = useState(Infinity);
  const [view, setView] = useState("main");

  const detailDarknetScoreEdited = {
    "score_a": detailDarknetScore.score_a,
    "score_b": detailDarknetScore.score_b,
    "score_c": detailDarknetScore.score_c,
    "score_d": detailDarknetScore.score_d,
    "score_e": detailDarknetScore.score_e,
    "weak_pass_count": detailDarknetScore.weak_pass_count,
    "targeted_infrastructure_count": detailDarknetScore.targeted_infrastructure_count,
    "insecure_system_count": detailDarknetScore.insecure_system_count,
  };
  const tableScoreTitles = [
    "Number of leaks vs Organization size (score /100)",
    "Recent vs Historical leak comparison (score /100)",
    "Leaks targeting company infrastructure (score /100)",
    "Proportion of weak passwords in leaks (score /100)",
    "Reports of insecure systems on IT search engines (score /100)",
    "Number of weak passwords in accounts",
    "Number of leaks targeting own infrastructure",
    "Number of insecure systems found in IT search engines",
  ];
  const tableScores = Object.keys(detailDarknetScoreEdited).filter(key => key !== "total_score");

  const fetchDetailDarknetScore = async () => {
    try {
      const response = await api.get('/service/get_risk_score/');

      const darknetScoreData = response.data;
      if (
        darknetScoreData.creation_date &&
        darknetScoreData.last_update &&
        darknetScoreData.creation_date.split(".")[0] === darknetScoreData.last_update.split(".")[0]
      ){
        darknetScoreData.total_score = "Calculating...";
      }

      setDetailDarknetScore(darknetScoreData);
    } catch (error) {
      console.error('Error fetching domain data:', error);
    }
  };
  const fetchScoreForYear = async () => {
    try {
      const response = await api.get('/service/get_company_score_history/', {
          params: {
            year: selectedYear
          },
        },
      );
      setSelectedYearScores(response.data);
    } catch (error) {
      console.error('Error fetching year score data:', error);
    }
  };
  const fetchCompetitors = async () => {
    try {
      const response = await api.get('/service/competitors/');
      const defaultCompany = response.data.default_company;
      setDefaultCompany(defaultCompany);
      const creationDate = new Date(defaultCompany.creation_date);
      setStartYear(creationDate.getFullYear());
    } catch (error) {
      console.error('Error fetching competitor data:', error);
    }
  };
  useEffect(() => {
    setSelectedYear(endYear);
    fetchDetailDarknetScore();
    fetchCompetitors();
    fetchScoreForYear();
  }, [selectedDomain]);

  useEffect(() => {
    fetchScoreForYear();
  }, [selectedYear]);

  useEffect(() => {
    if (selectedYearScores.length === 0) {
      setChartSeries([]);
      setMinRiskScore(Infinity);
      setMaxRiskScore(-Infinity);
      return;
    }

    const days = selectedYearScores.map(item => item.day);
    const yearScores = selectedYearScores.map(item => item.score);
    const dates = selectedYearScores.map(item => moment(`${selectedYear}-${item.day}`));
    const minDate = moment.min(dates);
    const maxDate = moment.max(dates);
    const diffDays = maxDate.diff(minDate, 'days');
    const shouldAggregate = diffDays > 31;
    if (shouldAggregate) {
      const formattedScores = selectedYearScores.map(item => ({
        ...item,
        date: `${selectedYear}-${item.day}`
      }));
      const aggregatedData = aggregateDataPerMonth(formattedScores, "score", true);
      const aggregatedMonths = Object.keys(aggregatedData).sort((a, b) => {
        return moment(a, "MMMM YYYY").toDate() - moment(b, "MMMM YYYY").toDate();
      });
      const aggregatedScores = aggregatedMonths.map(
        month => (aggregatedData[month].sum / aggregatedData[month].count).toFixed(2)
      );

      setChartOptions({
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: aggregatedMonths.map(date => date.split(" ")[0]),
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        fill: {
          type: "solid",
          colors: ["#ECC551"],
        },
        colors: ["#ECC551"],
        grid: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      });

      setChartSeries([
        {
          name: "Average Risk Score",
          data: aggregatedScores,
        },
      ]);
      setMinRiskScore(Math.min(...aggregatedScores));
      setMaxRiskScore(Math.max(...aggregatedScores));
    } else {
      setChartOptions({
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          tickAmount: 10,
          categories: days,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        fill: {
          type: "solid",
          colors: ["#ECC551"],
        },
        colors: ["#ECC551"],
        grid: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      });

      setChartSeries([
        {
          name: "Risk Score",
          data: yearScores,
        },
      ]);
      setMinRiskScore(Math.min(...yearScores));
      setMaxRiskScore(Math.max(...yearScores));
    }
  }, [selectedYearScores]);
  
  return (
    <div className="tw-p-4 tw-bg-white tw-border tw-rounded-lg tw-shadow tw-h-full">
      {view === "main" && (
        <>
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-1">
              <h2 className="tw-text-xl tw-font-bold">DARKNET</h2>
              <div className="my-anchor-elementsa">
                <InfoIcon />
              </div>
              <Tooltip
                anchorSelect=".my-anchor-elementsa"
                place="right"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  width: "600px",
                }}
              >
                The Darknet Risk Score quantifies the security threat to your
                organization with a score<br></br> between 1 and 100, where 100
                represents the highest risk.<br></br>This score is derived from
                several key factors: the number of data leaks associated{" "}
                <br></br>with your company's domain, the types and severity of
                these leaks—ranging from <br></br> generic collections to those
                specifically targeting your infrastructure,and the presence{" "}
                <br></br> of your organization's data in hacker forums<br></br>
                .Additionally, we conduct an infrastructure risk analysis that
                includes checks for open <br></br>ports and known
                vulnerabilities (CVEs), and assess the security of passwords
                that <br></br>have been exposed in data leaks
              </Tooltip>
            </div>
          </div>
          <p>Risk Score</p>
          {Object.keys(detailDarknetScore).length > 0 && (
            <div>
              <div className="tw-flex tw-justify-between">
                <div>
                  <p className="tw-text-base tw-font-medium tw-text-[#00B6FF]">
                    Domain : {defaultCompany.domain} <br/>
                    Industry : {defaultCompany.industry} <br/>
                    Country : {defaultCompany.country}
                  </p>
                  <p className="tw-text-sm tw-font-normal">
                    Min. Risk: <b>{minRiskScore !== Infinity ? minRiskScore : ' - '}</b>&nbsp;&nbsp;&nbsp;&nbsp;Max. Risk: <b>{maxRiskScore !== -Infinity ? maxRiskScore : ' - '}</b>
                  </p>
                  <div
                    className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                    onClick={() => setView("details")}
                  >
                    <p className="tw-text-base tw-font-medium">
                      How it works
                    </p>
                    <ArrowUpSide />
                  </div>
                </div>
                <p className="tw-text-2xl md:tw-text-3xl lg:tw-text-5xl tw-font-bold tw-px-6">
                  {detailDarknetScore.total_score}
                </p>
              </div>
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="line"
                height={230}
              />
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div className="tw-flex tw-items-center tw-gap-1 mt-2">
                  <span className="tw-text-[#AFABAB] tw-text-sm">Year:</span>
                  <span className="tw-font-semibold tw-text-black tw-text-sm tw-flex tw-items-center">
                {selectedYear}{" "}
                    <button
                      className="tw-relative tw-ml-2"
                      onClick={() => setOpenYearDropDown((prev) => !prev)}
                    >
                  <ArrowDown />
                      {openYearDropDown && (
                        <div className="tw-absolute tw-w-28 tw-bg-white card-shadow tw-rounded-lg tw-z-50 py-1 tw-right-0 tw-top-4">
                          <ul className="">
                            {Array.from(
                              { length: endYear - startYear + 1 },
                              (_, i) => startYear + i
                            ).map((year) => (
                              <li
                                key={year}
                                onClick={() => {
                                  setSelectedYear(year);
                                }}
                                className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                              >
                                {year}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                </button>
              </span>
                </div>
              </div>
            </div>
          )}
          {Object.keys(detailDarknetScore).length < 1 && (
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1 tw-p-10 tw-bg-white tw-rounded-lg">
              <div>
                <NoResultIcon />
              </div>
              <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#263238]">
                No Results
              </div>
              <div className="tw-text-[#44464A] tw-text-sm tw-font-normal tw-leading-6 tw-text-center">
                Please enter your organization information and wait until the darknet risk score is calculated.
              </div>
            </div>
          )}
        </>
      )}
      {view === "details" && (
        <>
          <div
            className="tw-flex tw-gap-[11px]"
            onClick={() => setView("main")}
          >
            <div className="tw-cursor-pointer">
              <ArrowBack />
            </div>
            <span className="tw-font-bold tw-text-[#263238]">
              Details Darknet Risk Score
            </span>
          </div>
          <p className="tw-pl-8">{defaultCompany.domain}</p>
          <div className="tw-overflow-x-auto tw-shadow-lg tw-max-w-4xl tw-mx-auto ">
            <table className="tw-min-w-full tw-leading-normal">
            <thead>
              <tr>
                <th className="tw-px-5 tw-font-semibold tw-py-3 tw-border-b-2 tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tracking-wider">
                  Title
                </th>
                <th className="tw-px-5 tw-py-3 tw-font-semibold tw-border-b-2 tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tracking-wider">
                  Your Value
                </th>
              </tr>
            </thead>
            <tbody>
              {tableScores.map((key, index) => (
                <tr key={index}>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    {tableScoreTitles[index]}
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    {detailDarknetScore[key]}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="tw-px-5 tw-py-2.5 tw-font-semibold tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                  Total Risk Score
                </td>
                <td className="tw-px-5 tw-py-2.5 tw-border-b tw-font-semibold tw-border-gray-200 tw-bg-white tw-text-sm">
                  {detailDarknetScore.total_score}
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default DarkNet;
