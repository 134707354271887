import React from 'react';
import { useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table/table.component';
import Loading from 'common/components/Loading/Loading';

import SearchResultTitle from '../SearchResultTitle';
import SearchResultError from '../SearchResultError';

const FakeApps = ({ selectedOptionLabel, title, searchTerm }) => {
  const { query, list, totalElements, loading, error } = useSelector(
    (state) => state.search.database.fakeApps,
  );

  const size = 10;

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Link',
      accessor: 'link',
      render: defaultRenderer,
    },
    {
      Header: 'Name',
      accessor: 'name',
      render: defaultRenderer,
    },
    {
      Header: 'Store',
      accessor: 'store',
      render: defaultRenderer,
    },
    {
      Header: 'Updated at',
      accessor: 'updated_at',
      render: defaultRenderer,
    },
  ];

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!loading ? (
        <>
          <SearchResultTitle
            selectedOptionLabel={selectedOptionLabel}
            title={title}
          />

          <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-z-20">
            <div className="tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search results
                </span>
                <span className="tw-text-sm tw-font-medium">
                  {totalElements}
                </span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search term
                </span>
                <span className="tw-text-sm tw-font-medium">{query}</span>
              </div>
            </div>
            <ExportButton />
          </div>
          {error ? (
            <SearchResultError errorText={error} />
          ) : (
            <Table
              data={list}
              columns={columns}
              paginatorLabel="apps"
              rowsPerPage={size}
            />
          )}
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default FakeApps;
