import React, {useState, useEffect} from "react";
import Chart from "react-apexcharts";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import Heading from "../../../common/components/heading/Heading";
import CustomSelect from "../../../common/components/Select/select";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import api from 'store/api';

const AlertsOverviewChart = () => {
  const [selectedOption, setSelectedOption] = useState("Last Week");
  const [barChartData, setBarChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
 
  const getPeriodValue = (option) => {
    const index = options.indexOf(option);
    return option_values[index];
  };

  const getAlertOverview = async () => {
    try {
      const periodvalue = getPeriodValue(selectedOption);
      const result = await api.get("service/alert_system_statistic_get_alert_overview/", {
        params: {
          period: periodvalue,
        },
      });
      const apiResponse = result.data;
      const sortedData = apiResponse.sort((a, b) => a.count - b.count);
      setBarChartData(sortedData);
    } catch (error) {
      console.error("Error fetching bar chart stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    getAlertOverview();
  }, [selectedOption]);

  useEffect(() => {
    if (barChartData.length > 0) {
      const services = barChartData.map(item => item.service_name);
      const counts = barChartData.map(item => item.count);
    setChartSeries([{
      data: counts,
    }]);
    setChartOptions({
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "70%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: services,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 1,
          gradientToColors: ["#ECC551"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
          colorStops: [
            {
              offset: 0,
              color: "#F9EDC9",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#ECC551",
              opacity: 1,
            },
          ],
        },
      },
    });
  }
}, [barChartData]);


  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-px-4 tw-pt-4">
      <div className="tw-flex tw-justify-between tw-items-center">
        <Heading size="h3" text="Alerts Overview" />

        <GenericDropdown
            options={options}
            onSelect={handleSelect}
            defaultText={selectedOption}
            staticFirstOption={true}
          />
      </div>

      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default AlertsOverviewChart;
const options = ["Last Week", "Last Month", "Last Year", "All Time"];
const option_values = ['last_week', 'last_month', 'last_year', 'all_time'];
