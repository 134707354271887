import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay, isValidDomain } from 'utils/global';
import { searchActions } from 'store/actions';

export const searchRequest = createAsyncThunk(
  'search/live/externalSource/searchRequest',
  async ({ query, validate, signal }, { getState, dispatch, rejectWithValue }) => {
    let progressValue = 0,
      step = 0,
      isError = false,
      total = 0;

    const stepInfos = [
      [10, 95],
      [50, 1000],
    ];

    const mockProgress = async () => {
      if (!isError) {
        for (let s = 0; s < stepInfos[0].length; s++) {
          if (s === step && progressValue < stepInfos[0][step]) {
            dispatch(setProgress(progressValue++));
            await delay(stepInfos[1][step]);
            requestAnimationFrame(mockProgress);
            break;
          }
        }
      }
    };

    try {
      const state = getState().search.live.externalSource;

      if (isValidDomain(query)) {
        const domainName = query.toLowerCase().split('.')[0];
        if (domainName.length >= 4) {
          query = domainName;
        }
      }

      dispatch(setQuery(query));

      // Step 1
      requestAnimationFrame(mockProgress);
      const requestResponse = await api.post(
        'service/external_sources_search/',
        {
          query,
          validate,
        },
        { signal },
      );
      if (signal?.aborted) throw new Error('Request cancelled');

      const searchStates = parseStates(requestResponse.data.states);
      dispatch(setStates({ count: 0, searchStates }));

      // Step 2
      progressValue = stepInfos[0][step++];
      requestAnimationFrame(mockProgress);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(
          `service/external_sources_search_status/${requestResponse.data.id}`,
          { signal },
        );
        if (signal?.aborted) throw new Error('Request cancelled');

        const searchStates = parseStates(statusResponse.data.states);
        dispatch(setStates({ count: statusResponse.data.resultCount, searchStates }));

        if (statusResponse.data.resultCount > total) {
          const resultResponse = await api.get(
            `service/external_sources_search_results/${requestResponse.data.id}`,
            {
              params: {
                page: 0,
                size: state.size,
                sort: state.sort,
              },
              signal,
            },
          );
          if (signal?.aborted) throw new Error('Request cancelled');

          dispatch(
            setSearchData({
              searchId: requestResponse.data.id,
              query,
              data: resultResponse.data,
            }),
          );

          total = statusResponse.data.resultCount;
        }

        if (statusResponse.data.status === 'FINISHED') break;
        await delay(1500);
      }

      // Done
      step++;
      return;
    } catch (error) {
      isError = true;

      if (signal.aborted) {
        return rejectWithValue('Request cancelled');
      }

      return rejectWithValue(
        /*error.response.data ||*/ 'An error occurred while fetching search results',
      );
    }
  },
);

export const search = createAsyncThunk(
  'search/live/externalSource/search',
  async ({ page, size, sort }, { getState, dispatch, rejectWithValue }) => {
    const state = getState().search.live.externalSource;
    if (_.isNil(page)) page = state.currentPage - 1;
    if (_.isNil(size)) size = state.size;
    if (_.isNil(sort)) sort = state.sort;

    try {
      dispatch(setParams({ size, sort }));

      const resultResponse = await api.get(
        `service/external_sources_search_results/${state.searchId}`,
        {
          params: {
            page,
            size,
            sort,
          },
        },
      );

      return resultResponse.data;
    } catch (error) {
      return rejectWithValue(
        /*error.response.data ||*/ 'An error occurred while fetching search results',
      );
    }
  },
);

const externalSourceSlice = createSlice({
  name: 'search/live/externalSource',
  initialState: {
    query: '',
    searchId: null,
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    pageLoading: false,
    searchStates: [],
    progress: 0,
    error: null,
    done: false,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.sort = action.payload.sort;
    },
    setStates: (state, action) => {
      state.searchStates = action.payload.searchStates;
      state.totalElements = action.payload.count;
      state.totalPages = Math.ceil(state.totalElements / state.size);
    },
    setSearchData: (state, action) => {
      state.searchId = action.payload.searchId;
      // state.query = action.payload.query;

      const { hasContent, totalElements, totalPages, number } = action.payload.data;
      state.list = hasContent ? action.payload.data.content : [];
      state.totalElements = totalElements;
      state.totalPages = totalPages;
      state.currentPage = number + 1;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchRequest.pending, (state) => {
        state.searchId = null;
        state.searchStates = [];
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = true;
        state.error = null;
        state.searchId = null;
        state.progress = 0;
      })
      .addCase(searchRequest.fulfilled, (state) => {
        state.loading = false;
        state.progress = 100;
      })
      .addCase(searchRequest.rejected, (state, action) => {
        // state.list = [];
        // state.totalElements = state.totalPages = 0;
        if (action.payload !== 'Request cancelled') {
          state.loading = false;
          state.error = action.payload;
        }
      })
      .addCase(search.pending, (state) => {
        state.pageLoading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.hasContent ? action.payload.content : [];
        state.currentPage = action.payload.number + 1;

        state.pageLoading = false;
      })
      .addCase(search.rejected, (state, action) => {
        state.pageLoading = false;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state, action) => {
        if (action.payload === 'Live') state.progress = 0;
      });
  },
});

const { setQuery, setParams, setStates, setSearchData, setProgress } = externalSourceSlice.actions;
export default externalSourceSlice.reducer;

const parseStates = (states) => {
  return Object.keys(states).map((key, idx) => {
    return {
      id: idx + 1,
      title: key,
      ...states[key],
    };
  });
};
