import React, { useState, useEffect } from "react";
import CustomTable from "../../common/table-no-paginator/table.component";
import Heading from "../../../common/components/heading/Heading";
import EyeIcon from "../../../common/Icons/EyeIcon";
import api from 'store/api';

function RiskyPassword() {
  // State to track overall password visibility
  const [arePasswordsVisible, setArePasswordsVisible] = useState(false);
  const [riskyPassword, setRiskyPassword] = useState([]);

  // Function to toggle visibility for all passwords
  const toggleAllPasswordsVisibility = () => {
    setArePasswordsVisible((prev) => !prev);
  };

  const columns = [
    { Header: "Account", accessor: "username" },
    {
      Header: (
        <div className="tw-flex tw-items-center tw-gap-[5px]">
          Password
          <button
            onClick={toggleAllPasswordsVisibility} 
          >
            <EyeIcon
              color="#5B5D63"
              className="eye-icon-hover cursor-pointer"
            />
          </button>
        </div>
      ),
      accessor: "password",
      Cell: ({ row }) => {
        const { password } = row.original; // Destructure to get the password from the record
        return (
          <div className="tw-flex tw-items-center tw-gap-[10px]">
            {/* Show password if visibility is toggled on, otherwise asterisks */}
            <span>{arePasswordsVisible ? "111" : "******"}</span>
          </div>
        );
      },
    },
  ];

  const topRiskyPasswordReport = async () => {
    try {
      const result = await api.get("service/top_risky_passwords/");
      return result.data.results; // Return the fetched data
    } catch (error) {
      console.error("Error fetching risky users chart stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    topRiskyPasswordReport().then((data) => {
      setRiskyPassword(data.map((item) => ({
        username: item.username,
        password: arePasswordsVisible ? item.password : '***',
      })));
    }).catch((error) => {
      console.error("Failed to fetch risky passwords:", error);
    });
  }, [arePasswordsVisible])

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow tw-h-full">
      <Heading size="h2" text="Top 3 Risky Passwords" />
      <CustomTable data={riskyPassword} columns={columns} selectable={false} height="200px" />
    </div>
  );
}

export default RiskyPassword;
