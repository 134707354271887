import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getList as getRiskList } from 'store/slices/report/risk';

import { EyeIcon, Setting } from '../../../common/Icons';
import GenericDropdown from '../../../common/components/DropDown/GenericDrop';
import ExportButton from '../../../common/components/Export/ExportButton';
import Heading from '../../../common/components/heading/Heading';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import CustomTable from '../../common/table-no-paginator/table.component';
import Paginator from '../../common/table-no-paginator/paginator.component';
import DomainSelector from './DomainSelector';
import { current, legacy_createStore } from '@reduxjs/toolkit';
import api from 'store/api';

function Risk() {
  const [arePasswordsVisible, setArePasswordsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [domains, setDomains] = useState([]);
  const dispatch = useDispatch();

  const toggleAllPasswordsVisibility = () => {
    setArePasswordsVisible((prev) => !prev);
  };

  const handleCheckboxChange = async (index) => {
    const newDomains = [...domains];
    newDomains[index].active = !newDomains[index].active;
    setDomains(newDomains);

    try {
      const response = await api.post('service/set_active_domains/', {
        domains: newDomains.filter((domain) => domain.active).map((domain) => domain.root_domain),
      });
      if (response.status !== 200) {
        console.error('Failed to update active domains.');
      }
    } catch (error) {
      console.error("Error updating domains:", error);
    }
  };

  const columns = [
    { Header: 'Created', accessor: 'created' },
    {
      Header: 'Login',
      accessor: 'login',
      render: (_, record) => {
        return <span className="tw-text-blue">{record.login}</span>;
      },
    },
    {
      Header: (
        <div className="tw-flex tw-items-center tw-gap-[5px]">
          Password
          <button onClick={toggleAllPasswordsVisibility}>
            <EyeIcon
              color="#5B5D63"
              className="eye-icon-hover cursor-pointer"
            />
          </button>
        </div>
      ),
      accessor: 'password',
    },
    { Header: 'Leak Source', accessor: 'leakSource' },
    { Header: 'Website', accessor: 'website' },
    // {
    //   Header: 'Status',
    //   accessor: 'state',
    //   render: (_, record, index) => {
    //     return (
    //       <div className="tw-relative">
    //         <span
    //           onClick={() => toggleStatusDropdown(index)}
    //           className={`tw-cursor-pointer tw-whitespace-nowrap tw-rounded-full tw-py-[2px] tw-px-2 ${
    //             record.state === 'Closed'
    //               ? 'tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-text-[#B93815]'
    //               : 'tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-text-[#067647]'
    //           }`}
    //         >
    //           {record.state}
    //         </span>
    //         {openDropdownIndex === index && (
    //           <div
    //             ref={statusDropdownRef}
    //             className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-white tw-rounded-lg tw-p-4 tw-divide-y tw-shadow-xl tw-z-10"
    //           >
    //             <h3 className="tw-text-black tw-font-semibold">
    //               Change Status
    //             </h3>
    //             <ul className="tw-flex tw-flex-col tw-mt-3">
    //               <li
    //                 className="tw-cursor-pointer tw-py-1"
    //                 onClick={() => handleStatusChange(index, 'Closed')}
    //               >
    //                 <span className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]">
    //                   Closed
    //                 </span>
    //               </li>
    //               <li
    //                 className="tw-cursor-pointer tw-py-1"
    //                 onClick={() => handleStatusChange(index, 'Open')}
    //               >
    //                 <span className="tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]">
    //                   Open
    //                 </span>
    //               </li>
    //             </ul>
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await api.get(`service/get_user_domains/`);
        if (response.status === 200) {
          setDomains(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch domains:", error);
      }
    };

    const fetchDefaultDateRange = async () => {
      try {
        const response = await api.get('service/get_current_date_range/');
        if (response.status === 200) {
          const defaultValue = response.data.current_date_range;
          setSelectedOption(options[option_values.indexOf(defaultValue)]);
        }
      } catch (error) {
        console.error('Failed to fetch default date range:', error);
      }
    };

    fetchDomains();
    fetchDefaultDateRange();
  }, []);

  useEffect(() => {
    dispatch(getRiskList({ arePasswordsVisible }));
  }, [dispatch, arePasswordsVisible]);

  useEffect(() => {
    dispatch(getRiskList({ page: 0, arePasswordsVisible }));
  }, [dispatch, selectedOption, domains]);

  const { list, size, currentPage, totalElements, totalPages, loading } =
    useSelector((state) => state.report.risk);

  const setCurrentPage = (page) => {
    dispatch(
      getRiskList({
        page: page - 1,
        arePasswordsVisible,
      }),
    );
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Create refs for dropdown elements
  const settingsDropdownRef = useRef(null);
  const exportDropdownRef = useRef(null);

  // Use the useOutsideClick hook for both dropdowns
  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));
  useOutsideClick(exportDropdownRef, () => setExportDropdown(false));

  const toggleDropdown = (event) => {
    setDropdownOpen((prev) => !prev);
  };

  const handleSelect = async (option) => {
    const index = options.indexOf(option);
    const value = option_values[index];
    setSelectedOption(option);

    try {
      const response = await api.post('service/update_current_date_range/', {
        current_date_range: value,
      });
      if (response.status !== 200) {
        console.error('Failed to update current date range.');
      }
    } catch (error) {
      console.error('POST request failed:', error);
    }
  };

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <Heading size="h2" text="Leak Risk Analysis" />
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
        <Heading size="h3" text="Latest Account Breaches" />

        <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-4 tw-relative">
          <ExportButton />
          <div
            onClick={toggleDropdown}
            className="tw-cursor-pointer tw-relative"
          >
            <Setting />
            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                onClick={(e) => e.stopPropagation()}
                className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <DomainSelector setDropdownOpen={setDropdownOpen} domains={domains} handleCheckboxChange={handleCheckboxChange} />
              </div>
            )}
          </div>

          {selectedOption ? (
            <GenericDropdown
              options={options}
              defaultText={selectedOption}
              staticFirstOption={true}
              onSelect={handleSelect}
            />
          ) : (<div></div>)}
        </div>
      </div>
      <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
        <CustomTable data={list} columns={columns} selectable={false} />
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
          <div className={loading ? 'tw-invisible' : 'tw-visible'}>
            <p className="tw-my-4">
              Showing list:{' '}
              {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
              {currentPage * size > totalElements
                ? totalElements
                : currentPage * size}{' '}
              of {totalElements}
            </p>
          </div>
          {totalPages > 1 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Risk;
const options = ['This Week', 'Last Month', 'Last Year'];
const option_values = ['7D', '30D', '365D'];
