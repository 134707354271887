import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/creditCardLeaks/search',
  async ({ query }, { dispatch, rejectWithValue }) => {
    let resultList = [];

    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 10) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(100);
          requestAnimationFrame(mockProgress);
        } else if (step === 1 && progressValue < 100) {
          //
        }
      }
    };

    try {
      let page = 0,
        totalPages = 1;
      requestAnimationFrame(mockProgress);

      while (page < totalPages) {
        const response = await api.get(
          'service/leak_extended_database_search/',
          {
            params: {
              page: page++,
              size: 100,
              length: 100,
              highlight: true,
              query,
            },
          },
        );

        totalPages = response.data.totalPages;
        resultList = resultList.concat(
          response.data.content
            .filter((leak) => leak.leakTags.includes('credit-card'))
            .map((leak) => ({
              id: leak.id,
              leakId: leak.leakId,
              createdAt: leak.createdAt,
              leakName: leak.leakName,
              content: leak.content,
              fileName: leak.fileName,
              leakTags: leak.leakTags,
              leakDiscoverDate: leak.leakDiscoverDate,
            })),
        );

        step = 1;
        progressValue =
          10 + Math.ceil(((100 - 10) / response.data.totalPages) * page);
        dispatch(setProgress(progressValue));
      }

      return resultList;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Failed to search data');
    }
  },
);

const creditCardLeaksSlice = createSlice({
  name: 'search/database/creditCardLeaks',
  initialState: {
    list: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload;
        state.totalElements = state.list.length;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.list = [];
        state.totalElements = 0;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state, action) => {
        if (action.payload === "Database") state.progress = 0;
      });
  },
});

const { setProgress } = creditCardLeaksSlice.actions;
export default creditCardLeaksSlice.reducer;
