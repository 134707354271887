import moment from 'moment';

export const delay = (millis) =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(), millis);
  });

export const kaduuHighlightTags = (string) => {
  const regex = /<kaduu:h>(.*?)<\/kaduu:h>/g;
  return string.replace(regex, '"<span class="tw-bg-secondary">$1</span>"');
};

export const extractAndHighlight = (text, keyword, length = 500) => {
  // Create a case-insensitive regex for matching the keyword
  const regex = new RegExp(`(${keyword.split(' ').join('|')})`, 'i');
  const match = text.match(regex);

  if (!match) {
    // If no match, return the first "length" characters
    return text.slice(0, length);
  }

  const matchIndex = match.index;
  const start = Math.max(0, matchIndex - Math.floor(length / 2));
  const end = Math.min(text.length, start + length);

  // Extract substring and highlight matches
  let snippet = text.slice(start, end);

  // Replace matches with <kaduu:h> tags
  snippet = snippet.replace(regex, (match) => `<kaduu:h>${match}</kaduu:h>`);

  // Combine consecutive <kaduu:h> tags
  snippet = snippet.replace(/<\/kaduu:h><kaduu:h>/g, '');

  // Ensure no unclosed tags by trimming appropriately
  if (snippet.includes('<kaduu:h>') && !snippet.includes('</kaduu:h>')) {
    snippet = snippet.replace(/<kaduu:h>[^<]*$/, ''); // Remove unclosed <kaduu:h> tags
  }

  return snippet;
};

export const formatFileSize = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = 0;
  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }

  return `${bytes.toFixed(unitIndex === 0 ? 0 : 2)} ${units[unitIndex]}`;
};

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

export const getCountryNameFromCode = (code) => {
  return regionNames.of(code.toUpperCase());
};

export const isURL = (str) =>
  typeof str === 'string' &&
  (str.startsWith('http://') || str.startsWith('https://'));

export const parseURL = (url) => {
  try {
    const parsedUrl = new URL(url);
    return {
      protocol: parsedUrl.protocol,
      hostname: parsedUrl.hostname,
    };
  } catch (error) {
    console.error('Invalid URL:', error);
    return {
      protocol: '',
      hostname: '',
    };
  }
};

export const isValidDomain = (domain) => {
  const regex = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return regex.test(domain);
}

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';

  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const truncateString = (str, maxLength = 160) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }

  return str;
};

export const convertToWildcardIP = (ip) => {
  const cleanIP = ip.split('/')[0];

  const segments = cleanIP.split('.');
  segments[3] = '*';

  return segments.join('.');
};

export const removeLastIP = (ip) => {
  const cleanIP = ip.split('/')[0];

  const segments = cleanIP.split('.');
  segments.pop();

  return segments.join('.');
};

export const replaceExtension = (fileName, newExtension) => {
  if (fileName.includes('.')) {
    return (
      fileName.substring(0, fileName.lastIndexOf('.')) + '.' + newExtension
    );
  } else {
    return fileName + '.' + newExtension;
  }
};

// Utility function to aggregate data per month
export const aggregateDataPerMonth = (
  data,
  key = 'count',
  calcAverage = false,
) => {
  if (calcAverage) {
    return data.reduce((acc, curr) => {
      const month = moment(curr.date).format('MMMM YYYY'); // e.g., "January 2025"
      if (!acc[month]) {
        acc[month] = { sum: 0, count: 0 };
      }
      acc[month].sum += curr[key];
      acc[month].count += 1;
      return acc;
    }, {});
  } else {
    return data.reduce((acc, curr) => {
      const month = moment(curr.date).format('MMMM YYYY'); // e.g., "January 2025"
      if (!acc[month]) {
        acc[month] = 0;
      }
      acc[month] += curr[key];
      return acc;
    }, {});
  }
};
