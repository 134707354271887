import React, { useState } from 'react';

import CustomButton from 'common/components/Button/Button';
import Feedback from 'common/Icons/Feedback';
import SendMessage from 'common/Icons/Message';

import Modal from 'common/components/Modal/Modal';
import ReadMore from 'common/components/ReadMore/ReadMore';
import CustomTextArea from 'common/components/TextArea/TextArea';

import api from 'store/api';
import { useSnackbar, useErrorbar } from 'utils/snackbar';

const SearchTipFeedback = ({optionIndex = 1}) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openSearchTips, setOpenSearchTips] = useState(false);

  const [feedback, setFeedback] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [submitFeedback, setSubmitFeedback] = useState(false);

  const handleSubmitFeedback = async () => {
    try {
      setSubmitFeedback(true);

      await api.post('service/live_search_submit_feedback/', {
        feedback,
        is_anoymous: isAnonymous,
      });

      useSnackbar('Your feedback has been successfully submitted.');
    } catch (error) {
      useErrorbar(error || 'Failed to submit your feedback.');
    } finally {
      setSubmitFeedback(false);
      setOpenFeedback(false);
    }
  };

  return (
    <>
      <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
        <div className="tw-w-full tw-max-w-[676px]">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <Feedback />
            <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#0B0B0B]">
              Tips & Feedback
            </div>
          </div>
          <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
            Explore search tips and share your feedback to help us improve your
            experience. Click below to access the feedback form and discover
            valuable search tips.
          </div>
        </div>

        <div className="tw-flex tw-flex-col tw-gap-2">
          <CustomButton
            bgColor="tw-bg-white"
            textColor="tw-text-primary"
            hoverTextColor="tw-text-primary"
            text="Submit feedback"
            className="tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border !tw-border-solid tw-border-primary tw-rounded-md tw-text-sm tw-font-semibold tw-leading-6 tw-text-center hover:tw-bg-[#D4B149] hover:tw-border-[#D4B149]"
            onClick={() => {
              setFeedback('');
              setIsAnonymous(false);

              setOpenFeedback(true);
            }}
          />
          <div
            className="text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-cursor-pointer hover:tw-text-[#D4B149]"
            onClick={() => setOpenSearchTips(true)}
          >
            Explore Search Tips
          </div>
        </div>
      </div>

      {/* FEEDBACK IF OPEN */}
      {openFeedback && (
        <Modal
          isOpen={openFeedback}
          title="Feedback Form"
          onClose={() => setOpenFeedback(false)}
          className="tw-w-full tw-max-w-[416px]"
        >
          <div className="tw-flex tw-flex-col tw-gap-2 tw-border-b tw-border-solid tw-border-[#EFF0F2] tw-p-4">
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#35363A]">
              Are we missing a leak? Do you know a site we should include a
              crawl? Noticed a bug?
            </div>
            <CustomTextArea
              className="tw-w-full"
              textAreaClassName="tw-border tw-border-solid tw-border-[#DFE0E3] tw-rounded-lg tw-p-4"
              value={feedback}
              placeholder="Facing an issue to find the hacker forum"
              onChange={(event) => setFeedback(event.target.value)}
            />
            <div className="tw-flex tw-items-center">
              <input
                type="checkbox"
                className="tw-mr-2 custom-checkbox"
                checked={isAnonymous}
                onChange={(event) => setIsAnonymous(event.target.checked)}
              />
              <span className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#35363A]">
                Send Anonymous
              </span>
            </div>
          </div>
          <div className="tw-flex tw-gap-2 tw-mt-2 tw-px-4">
            <CustomButton
              onClick={handleSubmitFeedback}
              icon={<SendMessage color="#fff" />}
              isLoading={submitFeedback}
              className="tw-text-sm tw-font-semibold tw-leading-6 tw-w-full tw-max-w-[103px] tw-h-[42px] tw-mt-2 tw-bg-black tw-text-white tw-rounded"
            >
              Submit
            </CustomButton>
            <CustomButton
              onClick={() => setOpenFeedback(false)}
              className="tw-text-sm tw-font-semibold tw-leading-6 tw-w-full tw-max-w-[73px] tw-h-[42px] tw-mt-2 tw-border tw-border-solid tw-border-[#DFE0E3] tw-text-black tw-rounded"
            >
              Cancel
            </CustomButton>
          </div>
        </Modal>
      )}

      {/* MODALS IF OPEN */}
      <Modal
        title="Search Tips"
        isOpen={openSearchTips}
        onClose={() => setOpenSearchTips(false)}
        className="tw-w-full tw-max-w-[700px]"
      >
        <div className="tw-p-4">
          <ReadMore
            size={446}
            text={searchTips[optionIndex]}
          />
        </div>
      </Modal>
    </>
  );
};

const searchTips = [
  '',
  'For the search in hacker forums it should be noted that we transmit all search terms live to the search functions of the hacker forums and the administrators thus have access to such search logs. It may well be that hackers share information about specific leaks and organizations, which is why entering a domain or company name may well show results. In most cases, however, hackers do not share data with such a precise specification. They are often referred to as combo lists, leaks, accounts, etc. from a particular geographic region, industry type or service. The details of the data are usually not disclosed. This must be taken into account in the search and be creative in the query. The more generic your search term is, but still has associations with the target, the more likely you are to find what you are looking for. For example, if an Austrian bank wants to know whether logins have been lost, you could search for "bank", "login" and the country "Austria".',
  'On this search, you can search social networks in live mode. Currently only Twitter, Youtube and Reddit are supported. It may take up to a few minutes to get the results, as we will be requesting external services.',
  'On this search, you can search multiple (10+) darknet and clearnet search engines in live mode. Words you enter in the query field will be directly forwarded to multiple external search engines, so we suggest using only simple phrases - a company, person or domain name. Set "Validate Results" option in order to verify each found result and check whether it contains the exact search phrase. This option may be useful only when searching 1-word queries, otherwise search results may be inaccurate. It may take up to a few minutes to get all results, as we will be requesting multiple external resources over proxy servers, TOR and I2P networks, which may be very slow.',
  'When doing a paste search, we recommend using your company name or domain. Any result that appears on a paste page should be investigated, as there is usually no reason to create anonymous paste pages related to your organization. You will find that your company or domain often appears on so-called marketing or target lists. It is not uncommon for lists to be shared with, for example, all banks from a region. These lists can be used for marketing purposes, but also for attacks. More critical are lists that give concrete hints about technology or even possible attack points of an application. This can be an indication of an imminent attack.',
  '',
]

export default SearchTipFeedback;
