import React, { useEffect, useState, useRef } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

function CustomTable({
                       columns,
                       data,
                       selectable = false,
                       onRowSelection,
                       height,
                       scrollable = false,
                     }) {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [includeRows, setIncludeRows] = useState([]);
  const [excludeRows, setExcludeRows] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setOpenDropdownIndex(null));

  useEffect(() => {
    if (isSelectAll) {
      setIncludeRows((prev) => [
        ...new Set([
          ...prev,
          ...data
            .map((item) => item.id)
            .filter((i) => !excludeRows.includes(i)),
        ]),
      ]);
    } else {
      setExcludeRows((prev) => [
        ...new Set([
          ...prev,
          ...data
            .map((item) => item.id)
            .filter((i) => !includeRows.includes(i)),
        ]),
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (onRowSelection) onRowSelection(isSelectAll, includeRows, excludeRows);
  }, [isSelectAll, includeRows, excludeRows]);

  const handleSelectRow = (e, id) => {
    if (e.target.checked) {
      setIncludeRows((prev) => {
        return [...prev, id];
      });

      setExcludeRows((prev) => {
        return prev.filter((i) => i !== id);
      });
    } else {
      setIncludeRows((prev) => {
        return prev.filter((i) => i !== id);
      });

      setExcludeRows((prev) => {
        return [...prev, id];
      });
    }
  };

  const handleSelectAll = (e) => {
    setIsSelectAll(e.target.checked);

    setIncludeRows(e.target.checked ? data.map((item) => item.id) : []);
    setExcludeRows(e.target.checked ? [] : data.map((item) => item.id));
  };

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const containerClasses = scrollable
    ? 'tw-overflow-y-auto'
    : 'tw-overflow-visible';

  const containerStyles = height
    ? { height: height }
    : {};

  return (
    <div
      className={`${containerClasses} tw-w-full`}
      style={containerStyles}
    >
      <table className="tw-w-full tw-table-auto">
        <thead className="tw-bg-grey">
        <tr>
          {selectable && (
            <th
              className="tw-text-left tw-px-4 tw-py-2 tw-sticky tw-top-0 tw-bg-grey tw-z-10"
              scope="col"
            >
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={isSelectAll}
                className="tw-w-4 tw-h-4"
              />
            </th>
          )}
          {columns.map((column) => (
            <th
              key={column.accessor}
              className="tw-text-dark tw-text-sm tw-text-left tw-py-2 tw-px-4 tw-sticky tw-top-0 tw-bg-grey tw-z-10"
              scope="col"
            >
              {column.Header}
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={columns.length + (selectable ? 1 : 0)}>
              <div className="tw-text-center tw-py-10">No data found</div>
            </td>
          </tr>
        ) : (
          data.map((row, rowIndex) => (
            <tr key={row.id || rowIndex}>
              {selectable && (
                <td className="tw-py-4 tw-px-4 tw-border-b tw-border-grey tw-align-top">
                  <input
                    type="checkbox"
                    checked={includeRows.includes(row.id)}
                    onChange={(e) => handleSelectRow(e, row.id)}
                    className="tw-w-4 tw-h-4"
                  />
                </td>
              )}
              {columns.map((column) => (
                <td
                  key={column.accessor}
                  className={`tw-py-4 tw-px-4 tw-border-b tw-border-grey tw-text-sm tw-align-top ${
                    column.accessor === 'state' && 'tw-w-[12%]'
                  } ${
                    column.accessor === 'created' && 'tw-whitespace-nowrap'
                  } tw-truncate`}
                >
                  {column.render ? (
                    column.accessor === 'state' ? (
                      <div className="tw-relative">
                          <span
                            onClick={() => toggleDropdown(rowIndex)}
                            className={`tw-cursor-pointer tw-whitespace-nowrap tw-rounded-full tw-py-[2px] tw-px-2 ${
                              row[column.accessor] === 'Closed'
                                ? 'tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-text-[#B93815]'
                                : 'tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-text-[#067647]'
                            }`}
                          >
                            {row[column.accessor]}
                          </span>
                        {openDropdownIndex === rowIndex && (
                          <div
                            ref={dropdownRef}
                            className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-white tw-rounded-lg tw-p-4 tw-divide-y tw-shadow-xl tw-z-20"
                          >
                            <h3 className="tw-text-black tw-font-semibold">
                              Change Status
                            </h3>
                            <ul className="tw-flex tw-flex-col tw-pt-4 tw-mt-3 tw-space-y-3">
                              <li
                                className="tw-cursor-pointer tw-py-1"
                                onClick={() => {
                                  column
                                  .render(null, row, rowIndex)
                                  .onStatusChange('Open');
                                  setOpenDropdownIndex(null);
                                }}
                              >
                                  <span className="tw-whitespace-nowrap tw-bg-[#EFF8FF] tw-border tw-border-[#B2DDFF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#175CD3]">
                                    Open
                                  </span>
                              </li>
                              <li
                                className="tw-cursor-pointer tw-py-1"
                                onClick={() => {
                                  column
                                  .render(null, row, rowIndex)
                                  .onStatusChange('In Progress');
                                  setOpenDropdownIndex(null);
                                }}
                              >
                                  <span className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]">
                                    In Progress
                                  </span>
                              </li>
                              <li
                                className="tw-cursor-pointer tw-py-1"
                                onClick={() => {
                                  column
                                  .render(null, row, rowIndex)
                                  .onStatusChange('Closed');
                                  setOpenDropdownIndex(null);
                                }}
                              >
                                  <span className="tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#ABEFC6]">
                                    Closed
                                  </span>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : (
                      column.render(row[column.accessor], row, rowIndex)
                    )
                  ) : (
                    row[column.accessor]
                  )}
                </td>
              ))}
            </tr>
          ))
        )}
        </tbody>
      </table>
    </div>
  );
}

export default CustomTable;
