import React, { useState, useRef } from "react";
import InputLabel from "../InputLabel/InputLabel";
import _ from "lodash";
import ArrowDown from "../../Icons/ArrowDown";  // Your custom dropdown icon
import { FaSearch } from "react-icons/fa";      // Example using react-icons
import { useOutsideClick } from "../../../hooks/useOutsideClick";

const MultiSelectGroup = ({
  htmlfor = "",
  labelText = "",
  inputName = "",
  options = [],
  register = () => {},
  errors = {},
  value,
  onChange,
  className,
  defaultValue,
  bgColor,
  disabled = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(value || defaultValue || []); // Manage selected values array
  const dropdownRef = useRef(null);

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectChange = (option) => {
    let updatedSelectedValues;
    if (selectedValues.includes(option.value)) {
      updatedSelectedValues = selectedValues.filter(value => value !== option.value); // Deselect
    } else {
      updatedSelectedValues = [...selectedValues, option.value]; // Select
    }
    setSelectedValues(updatedSelectedValues); // Update selected values
    if(onChange){
      onChange(updatedSelectedValues); // Pass updated values array to onChange
    }
  };

  return (
    <div ref={dropdownRef} className={`tw-relative tw-space-y-1 ${className}`}>
      <InputLabel htmlFor={htmlfor} text={labelText} />
      <div className="tw-relative tw-w-full">
        {/* Custom dropdown button */}
        <div
          onClick={handleDropdownToggle}
          className="tw-block tw-w-auto tw-cursor-pointer tw-inline-flex tw-px-6 tw-py-2 tw-text-primary tw-bg-white tw-border tw-border-blue-500 tw-rounded-lg tw-flex tw-justify-between tw-items-center"
        >
          {selectedValues.length > 0 ? selectedValues.map(value => options.find(opt => opt.value === value)?.label).join(", ") : "Select ..."}
          <ArrowDown />
        </div>

        {/* Dropdown menu */}
        {isDropdownOpen && (
          <div className="tw-absolute tw-mt-2 tw-bg-white tw-shadow-lg tw-rounded-lg tw-z-10 tw-max-h-60 tw-overflow-y-auto">
            {/* Search input with icon */}
            <div className="tw-relative tw-px-4 tw-py-2">
              <FaSearch className="tw-absolute tw-left-6 tw-top-1/2 tw-transform -tw-translate-y-2 tw-text-gray-400" />
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="tw-block tw-w-full tw-pl-10 tw-px-4 tw-py-2 tw-text-primary tw-border tw-rounded-md focus:tw-outline-none"
              />
            </div>

            {/* Dropdown options */}
            <ul className="tw-list-none tw-py-2">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleSelectChange(option)}
                    className={`tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-primary hover:tw-bg-gray-100 tw-cursor-pointer ${selectedValues.includes(option.value) ? 'tw-bg-gray-200' : ''}`}
                  >
                    {option.flag && (
                      <img src={option.flag} alt={`${option.label} flag`} className="tw-w-6 tw-h-6 tw-mr-2" />
                    )}
                    {option.label}
                  </li>
                ))
              ) : (
                <li className="tw-px-4 tw-py-2 tw-text-gray-500">No options found</li>
              )}
            </ul>
          </div>
        )}
      </div>

      {_.get(errors, inputName) && (
        <p className="tw-text-red-500 tw-text-sm">
          {_.get(errors, inputName).message}
        </p>
      )}
    </div>
  );
};

export default MultiSelectGroup;