import React, {useState, useEffect} from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import Heading from "../../../common/components/heading/Heading";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import CustomSelect from "../../../common/components/Select/select";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import api from 'store/api';

const AlertsHistoryChart = () => {
  const [selectedOption, setSelectedOption] = useState("Last Week");
  const [areaChartData, setAreaChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
 
  const getPeriodValue = (option) => {
    const index = options.indexOf(option);
    return option_values[index];
  };

  const getAlertHistory = async () => {
    try {
      const periodvalue = getPeriodValue(selectedOption);
      const result = await api.get("service/alert_system_statistic_get_alert_history/", {
        params: {
          period: periodvalue,
        },
      });
      setAreaChartData(result.data);
    } catch (error) {
      console.error("Error fetching bubble chart stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    getAlertHistory();
  }, [selectedOption]);

  useEffect(() => {
    if (areaChartData.length > 0) {
      const dates = areaChartData.map(item => item.date);
      const scores = areaChartData.map(item => item.count);

      setChartOptions({
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: dates,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ["#ECC551"],
        grid: {
          borderColor: "#555",
          clipMarkers: false,
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          enabled: true,
        },
      });

      setChartSeries([
        {
          name: "Alert Count",
          data: scores,
        },
      ]);
    } 
  },  [areaChartData]);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <div className="tw-bg-white tw-rounded-lg  tw-px-4 tw-pt-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <Heading size="h3" text="Alerts History" />
          <GenericDropdown
            options={options}
            onSelect={handleSelect}
            defaultText={selectedOption}
            staticFirstOption={true}
          />
        </div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="area"
          height={350}
        />
      </div>
    </>
  );
};

export default AlertsHistoryChart;
const options = ["Last Week", "Last Month", "Last Year", "All Time"];
const option_values = ['last_week', 'last_month', 'last_year', 'all_time'];

