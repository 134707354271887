import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const searchRequest = createAsyncThunk(
  'search/live/dataPaste/searchRequest',
  async ({ query, signal }, { dispatch, rejectWithValue }) => {
    let progressValue = 0,
      step = 0,
      isError = false;

    const stepInfos = [
      [10, 75, 95, 100],
      [50, 600, 600, 50],
    ];

    const mockProgress = async () => {
      if (!isError) {
        for (let s = 0; s < stepInfos[0].length; s++) {
          if (s === step && progressValue < stepInfos[0][step]) {
            dispatch(setProgress(progressValue++));
            await delay(stepInfos[1][step]);
            requestAnimationFrame(mockProgress);
            break;
          }
        }
      }
    };

    try {
      dispatch(setQuery(query));

      // Step 1
      requestAnimationFrame(mockProgress);
      const requestResponse = await api.post(
        'service/create_paste_search_request/',
        {
          search_term: query,
        },
        { signal },
      );
      if (signal?.aborted) throw new Error('Request cancelled');

      // Step 2
      progressValue = stepInfos[0][step++];
      requestAnimationFrame(mockProgress);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(
          `service/get_paste_search_request/${requestResponse.data.id}/`,
          { signal },
        );
        if (signal?.aborted) throw new Error('Request cancelled');

        if (statusResponse.data.status === 'finished') break;
        await delay(2500);
      }

      // Step 3
      progressValue = stepInfos[0][step++];
      requestAnimationFrame(mockProgress);
      const resultResponse = await api.get('service/list_paste_search_result/', {
        params: { searchrequest: requestResponse.data.id },
        signal,
      });
      if (signal?.aborted) throw new Error('Request cancelled');

      // Step 4
      progressValue = stepInfos[0][step++];
      requestAnimationFrame(mockProgress);
      await api.delete(`service/delete_paste_search_request/${requestResponse.data.id}/`, {
        signal,
      });
      if (signal?.aborted) throw new Error('Request cancelled');

      // Done
      step++;
      return resultResponse.data;
    } catch (error) {
      isError = true;

      if (signal.aborted) {
        return rejectWithValue('Request cancelled');
      }

      return rejectWithValue(
        /*error.response.data ||*/ 'An error occurred while fetching search results',
      );
    }
  },
);

const dataPasteSlice = createSlice({
  name: 'search/live/dataPaste',
  initialState: {
    query: '',
    list: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchRequest.pending, (state) => {
        state.progress = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(searchRequest.fulfilled, (state, action) => {
        state.list = action.payload;
        state.totalElements = state.list.length;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(searchRequest.rejected, (state, action) => {
        state.list = [];
        state.totalElements = 0;
        if (action.payload !== 'Request cancelled') {
          state.loading = false;
          state.error = action.payload;
        }
      })
      .addCase(searchActions.resetProgress, (state, action) => {
        if (action.payload === 'Live') state.progress = 0;
      });
  },
});

const { setQuery, setProgress } = dataPasteSlice.actions;
export default dataPasteSlice.reducer;
