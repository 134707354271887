import React, { createContext, useContext, useState } from "react";

// Create a context
const InMemoryContext = createContext();

// Context provider
const InMemoryProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({});
  return (
    <InMemoryContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </InMemoryContext.Provider>
  );
};

// Hook for using the context
const useInMemoryState = () => useContext(InMemoryContext);

export { InMemoryProvider, useInMemoryState };
