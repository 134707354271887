import React, { useRef, useState, useEffect } from 'react';

import Heading from '../../../common/components/heading/Heading';
import CustomTable from '../../common/table-no-paginator/table.component';
import Paginator from '../../common/table-no-paginator/paginator.component';
import Loader from "../../../common/components/Loader/Loader";

import api from 'store/api';

function RansomNewsTable() {

  const columns = [
    { Header: 'Date', accessor: 'discovered' },
    { Header: 'Company', 
      accessor: 'company',
      render: (_, record) => {
        return <span className="tw-text-[#00B6FF]">{record.company}</span>;
      },
    },
    { Header: 'Group Name', accessor: 'groupname' },
    { Header: 'Description', accessor: 'description' },
  ];
	const size = 10;
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const fetchRecords = async () => {
    try {
      setLoading(true);
      const response = await api.get(`service/ransomware_victim_details/`, {
        params: {
          page: currentPage,
          per_page: 10,
          sort: 'DATE,DESC',
        },
      });
			const latestNewsData = response.data.rows.map((item, index) => ({
				discovered: item.discovered.split('T')[0],
				company: item.company,
				groupname: item.groupname,
				description: item.description
			}));
      setNewsData(latestNewsData);
      setTotalElements(Math.min(response.data.count, 30));

      // Ensure totalPages does not exceed 3
      const calculatedTotalPages = Math.ceil(response.data.count / 10);
      setTotalPages(Math.min(calculatedTotalPages, 3));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
	}

  useEffect(() => {
    fetchRecords();
  }, [currentPage]);

  return (
    <div className='tw-h-full tw-flex tw-flex-col'>
      <Heading size="h4" text="Ransomware News" />
      <div className="tw-flex-1 tw-bg-white tw-p-4 tw-rounded-lg tw-shadow tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden">
      {loading ? <div className="tw-w-full tw-flex tw-justify-center tw-pt-20"><Loader /></div> : 
      <div>
        <CustomTable data={newsData} columns={columns} selectable={false} /> 
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
          <div className={loading ? 'tw-invisible' : 'tw-visible'}>
            <p className="tw-my-4">
              Showing list:{' '}
              {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
              {currentPage * size > totalElements
                ? totalElements
                : currentPage * size}{' '}
              of {totalElements}
            </p>
          </div>
          {totalPages > 1 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      </div>}
      </div>
    </div>
  );
}

export default RansomNewsTable;

