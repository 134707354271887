import React from 'react';

export default function CustomTabs({ children, active, onTabActived }) {
  return (
    <>
      <div className="tabs tw-overflow-auto md:tw-overflow-hidden">
        <div className="tab-list tw-w-fit tw-flex tw-gap-4 tw-flex-nowrap md:tw-flex-wrap">
          {children.map((child, index) => {
            const info = child.props['data-info'];

            return (
              <Tab
                key={index.toString()}
                label={info.label}
                onClick={() => {
                  if (onTabActived) onTabActived(info.id);
                }}
                isActive={info.id === active}
                disabled={!info.active}
                store={info.store}
              />
            );
          })}
        </div>
      </div>

      {children?.length && (
        <div className="tab-content">
          {children.map((child) =>
            child.props['data-info'].id === active ? child : null,
          )}
        </div>
      )}
    </>
  );
}

const Tab = ({ label, onClick, isActive, disabled, store }) => {
  return (
    <button
      className={`tab ${
        disabled
          ? 'tw-text-[#CBCBCB] md:tw-text-base tw-pb-3 defaultTab !tw-border-gray-300 tw-whitespace-nowrap tw-text-sm'
          : isActive
          ? 'tw-font-bold tw-pb-3 tabActive tw-whitespace-nowrap tw-text-sm md:tw-text-base'
          : 'tw-text-[#0B0B0B] md:tw-text-base tw-pb-3 defaultTab tw-whitespace-nowrap tw-text-sm'
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      <div className='tw-mb-1 tw-font-medium'>{label}</div>
      {disabled ? null : store.loading ? (
        <div className="tw-text-xs">{`( In progress )`}</div>
      ) : store.error ? (
        <div className='tw-text-xs tw-text-danger'>{`( Error )`}</div>
      ) : store.totalElements == 0 ? (
        <div className='tw-text-xs'>{`( No results )`}</div>
      ) : (
        <div className='tw-text-xs'>{`(${store.totalElements})`}</div>
      )}
    </button>
  );
};
