import React, { useState, useEffect } from "react";
import Heading from "../../common/components/heading/Heading";
import moment from "moment";
import CustomTable from "../common/table/table.component";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import CustomButton from "../../common/components/Button/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendarDate } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import AlertsOverviewChart from "../charts/alerts_chart/AlertsOverview";
import AlertsHistoryChart from "../charts/alerts_chart/AlertsHistoryChart";
import SelectGroup from "../../common/components/SelectDropdown/SelectDropdown";
import { useForm } from "react-hook-form";
import DownloadIcon from "../assets/svgs/DownloadIcon.js";
import { useErrorbar, useSnackbar } from "utils/snackbar";
import Modal from "../../common/components/Modal/Modal.js";
// import DownloadIcon from "../assets/svgs/DownloadIcon.js";
import Delete from "../assets/svgs/Delete.js";
import EyeIcon from "../../common/Icons/EyeIcon.js";
import api from 'store/api';
import Loader from "../../common/components/Loader/Loader";
import CustomModel from 'common/components/Model/Model';


export default function ActiveAlerts() {
  const [serverList, setServerLIst] = useState([{ value: "", label: "" }]);
  const [ruleList, setRuleLIst] = useState([{ value: "", label: "" }]);
  const [reportData, setReportData] = useState([]);
  const [isExportAlertsModalOpen, setIsExportAlertsModalOpen] = useState(false);
  const [isAPICalling, setIsAPICalling] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);

  // Handle delete
  const onHandleDelete = (record) => {
    setRecordToDelete(record);
    console.log(record, "<----record")
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    setIsAPICalling(true);
    const result = await api.delete(`service/alert_system_alert_delete_alert_match/${recordToDelete.id}`);
    setIsAPICalling(false);
    setModalOpen(false);
  
    if (result.status === 200) {
      useSnackbar("Deleted the alert successfully!");
      initReportData();
    } else {
      useErrorbar("Delete report failed!");
    }
  };

  useEffect(() => {
    initReportData()
  }, []);

  const initReportData = async () => {
    const serviceData = await fetchServiceList();
    const ruleData = await fetchRuleList();
    await getAlertTableData(serviceData, ruleData);
  }

  const getAlertTableData = async (serviceData, ruleData) => {
    try {
      setIsAPICalling(true);
      const result = await api.get("service/alert_system_alert_get_alert_match_list/", {
        params: {
        },
      });
      setIsAPICalling(false);
      const alertTableData = result.data.map((item) => ({
        id: item.id,
        date: item.creation_date.split('T')[0],
        alertName: ruleData[item.alert_rule] || item.alert_rule,
        keywords: item.query,
        types: serviceData[item.service] || item.service,
        status: item.status,
        alertExtract: 
          item.truncated_result_data,
      }));
      setReportData(alertTableData.sort((a, b) => b.date - a.date));
        

    } catch (error) {
      console.error(error);
    } finally {
      setIsAPICalling(false);
    }
  }

  const getAlertTableDataOnApply = async (serviceData, ruleData) => {
    try {
      setIsAPICalling(true);
      const result = await api.get("service/alert_system_alert_get_alert_match_list/", {
        params: {
          from_date: startDate,
          to_date: endDate,
          alert_rule_id: alertRule,
          service_id: serviceType,
          status: statusType
        },
      });
      setIsAPICalling(false);
      const alertTableData = result.data.map((item) => ({
        date: item.creation_date.split('T')[0],
        alertName: ruleData[item.alert_rule] || item.alert_rule,
        keywords: item.query,
        types: serviceData[item.service] || item.service,
        status: item.status,
        alertExtract: 
          item.truncated_result_data,
      }));
      setReportData(alertTableData.sort((a, b) => b.date - a.date));
        

    } catch (error) {
      console.error(error);
    } finally {
      setIsAPICalling(false);
    }
  }

  const fetchServiceList = async () => {
    try {
      const response = await api.get('service/alert_system_service_get_service_list/');
      const serviceData = response.data.reduce((acc, service) => {
        acc[service.id] = service.name;
        return acc;
      }, {});
      const serverOptions = response.data.map(service => ({
        value: service.id,
        label: service.name
      }));
      setServerLIst(serverOptions);
      return serviceData;
    } catch (error) {
      console.error('Error fetching service list:', error);
      return {};
    }    
  }

  const fetchRuleList = async () => {
    try {
      const response = await api.get('service/alert_system_alert_get_alert_rule_list/');
      const serviceData = response.data.reduce((acc, service) => {
        acc[service.id] = service.name;
        return acc;
      }, {});
      const ruleOptions = response.data.map(service => ({
        value: service.id,
        label: service.name
      }));
      setRuleLIst(ruleOptions);
      return serviceData;
    } catch (error) {
      console.error('Error fetching service list:', error);
      return {};
    }    
  }

  //Handle Apply Button
  const handleApply = async () => {
    const serviceData = await fetchServiceList();
    const ruleData = await fetchRuleList();
    await getAlertTableDataOnApply(serviceData, ruleData);
  }

  const openExportAlertsModal = () => {
    setIsExportAlertsModalOpen(true);
  };

  const closeopenExportAlertsModal = () => {
    setIsExportAlertsModalOpen(false);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [alertRule, setAlertRule] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [statusType, setStatusType] = useState('');

  const columns = [
    {
      Header: "Create Date",
      accessor: "date",
      render: (_, date) => {
        return <span className="tw-whitespace-nowrap">{date.date}</span>;
      },
    },
    {
      Header: "Alert Name",
      accessor: "alertName",
      render: (_, record) => {
        return <span className="tw-text-blue">{record.alertName}</span>;
      },
    },
    {
      Header: "Keywords",
      accessor: "keywords",
    },
    { Header: "Types", accessor: "types" },
    {
      Header: "Status",
      accessor: "status",
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2 tw-items-center">
            <span
              className={`tw-font-medium tw-text-xs tw-whitespace-nowrap ${
                item.status === "In Progress"
                  ? "tw-text-red-800"
                  : "tw-text-[#067647]"
              }`}
            >
              {item.status}
            </span>

            <IoIosArrowDown
              className={`tw-font-medium tw-text-xs tw-whitespace-nowrap ${
                item.status === "In Progress"
                  ? "tw-text-red-800"
                  : "tw-text-[#067647]"
              }`}
            />
          </div>
        );
      },
    },
    { Header: "Alert Extract", 
      accessor: "alertExtract",
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2 tw-items-center">
            <span
              className="tw-font-medium tw-text-xs tw-break-all">
                {item.alertExtract}
              </span>
          </div>
        )
      } },
    {
      Header: "Actions",
      accessor: "action",
      render: (_, record) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button>
              <EyeIcon color="#71747C" />
            </button>
            <button onClick={() => onHandleDelete(record)}>
              <Delete color="#71747C" />
            </button>
          </div>
        );
      },
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  return (
    <>
      <div className="tw-mt-0 md:tw-px-6 ">
        <Heading size="h2" text="Active Alerts " />
      </div>
      <div className="tw-py-2 md:tw-px-2 tw-mt-0">
        <div className="tw-flex tw-justify-center md:tw-justify-between tw-mb-2 tw-flex-col md:tw-flex-row tw-gap-4 md:tw-gap-0">
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-w-[90vw] md:tw-w-[49%]">
            <AlertsOverviewChart />
          </div>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-w-[90vw] md:tw-w-[49%]">
            <AlertsHistoryChart />
          </div>
        </div>

        <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
          <Heading
            size="h3"
            fontSize="18px"
            color="#263238"
            text="Filter Alerts"
          />
          <div className="tw-flex tw-mb-4 tw-gap-3 tw-w-[100%]">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-3 tw-w-full">
              <div className="tw-w-4/4 md:tw-w-2/4 tw-border tw-border-[#DFE0E3] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker">
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(value) => {{
                    const formattedDate = moment(value).format('YYYY-MM-DD');
                    setStartDate(formattedDate);
                  }}}
                  placeholderText="From Date"
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: "19px", color: "#71747C" }}
                    />
                  }
                />
              </div>
              <div className="tw-w-4/4 md:tw-w-2/4 tw-border tw-border-[#DFE0E3] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker">
                <DatePicker
                  showIcon
                  selected={endDate}
                  onChange={(value) => {{
                    const formattedDate = moment(value).format('YYYY-MM-DD');
                    setEndDate(formattedDate);
                  }}}
                  placeholderText="To Date"
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: "19px", color: "#71747C" }}
                    />
                  }
                />
              </div>
              <div className="tw-w-4/4 md:tw-w-2/4">
                <SelectGroup
                  htmlfor="Rule"
                  // labelText="Country"
                  inputName="rule"
                  selectTitle="Select Rule"
                  register={register}
                  errors={errors}
                  options={ruleList}
                  onChange={(value) => {
                    setAlertRule(value)}}
                  placeholder="Select"
                  className="tw-w-full"
                  isLoading={false}
                />
              </div>
              <div className="tw-w-4/4 md:tw-w-2/4">
                <SelectGroup
                  htmlfor="Service"
                  // labelText="Country"
                  inputName="service"
                  selectTitle="Select Service"
                  register={register}
                  errors={errors}
                  options={serverList}
                  onChange={(value) => {
                    setServiceType(value)}}
                  placeholder="Select"
                  className="tw-w-full"
                  isLoading={false}
                />
              </div>
              <div className="tw-w-4/4 md:tw-w-2/4">
                <SelectGroup
                  htmlfor="Status"
                  // labelText="Country"
                  inputName="status"
                  selectTitle="Select Status"
                  register={register}
                  errors={errors}
                  options={[
                    { value: "In Progress", label: "In Progress" },
                    { value: "Solved", label: "Solved" },
                  ]}
                  onChange={(value) => {
                    setStatusType(value)}}
                  placeholder="Select"
                  className="tw-w-full"
                  isLoading={false}
                />
              </div>
              <button onClick={handleApply} className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg tw-w-4/4 md:tw-min-w-[64px] hover:tw-bg-violet-500">
                Apply
              </button>
              <div
                className="tw-flex tw-items-center tw-justify-center tw-gap-[4px] tw-border tw-border-primary tw-bg-white tw-text-primary  tw-p-2 tw-rounded-lg tw-w-4/4 md:tw-min-w-[135px] hover:tw-cursor-pointer hover:tw-bg-violet-100"
                onClick={openExportAlertsModal}
              >
                <DownloadIcon />
                <button>Export Alerts</button>
              </div>
            </div>
          </div>
          <div className="tw-mt-0 md:tw-px-1 ">
            <Heading size="h3" text="Active Alerts " />
          </div>
          {isAPICalling ? <div className="tw-w-full tw-flex tw-justify-center tw-pt-20"><Loader /></div> : <div className="">
            <CustomTable data={reportData} columns={columns} selectable={true} />
          </div>}
          <CustomModel isOpen={isModalOpen}>
            <div className="tw-flex tw-flex-col tw-items-center">
              <h2 className="tw-text-xl tw-font-bold tw-mb-4">Confirm Delete</h2>
              <p className="tw-mb-4">Are you sure you want to delete this report?</p>
              <div className="tw-flex tw-gap-4">
                <button
                  onClick={confirmDelete}
                  className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
                >
                  Yes
                </button>
                <button
                  onClick={() => setModalOpen(false)}
                  className="tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
                >
                  No
                </button>
              </div>
            </div>
          </CustomModel>
        </div>
        {isExportAlertsModalOpen && (
          <Modal
            isOpen={isExportAlertsModalOpen}
            title={<DownloadIcon />}
            onClose={closeopenExportAlertsModal}
          >
            <div className="tw-px-3 tw-flex tw-flex-col tw-gap-4">
              <div>
                <Heading size="h3" text="Export Alerts" />
                <p className="tw-text-gray-700">
                  You can locate the export file in the Reports/Exports section
                </p>
              </div>

              <button
                className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg tw-w-3/4 md:tw-max-w-[64px]"
                onClick={closeopenExportAlertsModal}
              >
                Close
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
