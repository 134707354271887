import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/cloudStorage/search',
  async (
    { query, fileExt, signal },
    { getState, dispatch, rejectWithValue },
  ) => {
    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (isError || signal.aborted) return;

      if (step === 0 && progressValue < 10) {
        progressValue++;
        dispatch(setProgress(progressValue));

        await delay(50);
        requestAnimationFrame(mockProgress);
      } else if (step === 1 && progressValue < 75) {
        progressValue++;
        dispatch(setProgress(progressValue));
        await delay(600);
        requestAnimationFrame(mockProgress);
      } else if (step === 2 && progressValue < 95) {
        progressValue++;
        dispatch(setProgress(progressValue));
        await delay(500);
        requestAnimationFrame(mockProgress);
      } else if (step === 3 && progressValue < 100) {
        progressValue++;
        dispatch(setProgress(progressValue));
        await delay(50);
        requestAnimationFrame(mockProgress);
      }
    };

    if (getState().search.database.main.selectedOptionId === 0) {
      // Domain option
      const firstQuery = query.split('.')[0];
      try {
        requestAnimationFrame(mockProgress);
        dispatch(setQuery({ query: firstQuery, op: 1 }));

        let requestResponse = await api.post(
          'service/create_bucket_search_request/',
          { search_term: firstQuery },
          { signal },
        );
        if (signal.aborted) throw new Error('Request cancelled');

        progressValue = 10;
        step = 1;
        requestAnimationFrame(mockProgress);
        let resultOverflow = false;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const statusResponse = await api.get(
            `service/get_bucket_search_request/${requestResponse.data.id}/`,
            { signal },
          );

          if (signal.aborted) throw new Error('Request cancelled');

          if (statusResponse.data.status === 'finished') {
            resultOverflow = statusResponse.data.has_more_result_than_limit;
            break;
          }
          await delay(2500);
        }

        progressValue = 75;
        step = 2;
        requestAnimationFrame(mockProgress);
        const resultResponse = await api.get(
          'service/list_bucket_search_result/',
          { params: { searchrequest: requestResponse.data.id }, signal },
        );
        if (signal.aborted) throw new Error('Request cancelled');

        progressValue = 95;
        step = 3;
        requestAnimationFrame(mockProgress);
        await api.delete(
          `service/delete_bucket_search_request/${requestResponse.data.id}/`,
          { signal },
        );
        if (signal.aborted) throw new Error('Request cancelled');
        step = 4;

        // if results is more than 5000...
        if (resultOverflow) {
          progressValue = 0;
          step = 0;
          requestAnimationFrame(mockProgress);
          dispatch(setQuery({ query, op: 2 }));
          requestResponse = await api.post(
            'service/create_bucket_search_request/',
            { search_term: query },
            { signal },
          );
          if (signal.aborted) throw new Error('Request cancelled');

          progressValue = 10;
          step = 1;
          requestAnimationFrame(mockProgress);
          // eslint-disable-next-line no-constant-condition
          while (true) {
            const statusResponse = await api.get(
              `service/get_bucket_search_request/${requestResponse.data.id}/`,
              { signal },
            );
            if (signal.aborted) throw new Error('Request cancelled');

            if (statusResponse.data.status === 'finished') break;
            await delay(2500);
          }

          progressValue = 75;
          step = 2;
          requestAnimationFrame(mockProgress);
          const resultResponse2 = await api.get(
            'service/list_bucket_search_result/',
            { params: { searchrequest: requestResponse.data.id }, signal },
          );
          if (signal.aborted) throw new Error('Request cancelled');

          progressValue = 95;
          step = 3;
          requestAnimationFrame(mockProgress);
          await api.delete(
            `service/delete_bucket_search_request/${requestResponse.data.id}/`,
            { signal },
          );
          if (signal.aborted) throw new Error('Request cancelled');

          step = 4;

          return { result: resultResponse.data, result2: resultResponse2.data };
        }

        return { result: resultResponse.data };
      } catch (error) {
        isError = true;

        if (signal.aborted) {
          return rejectWithValue('Request cancelled');
        }

        return rejectWithValue(error.response.data || 'Failed to search data');
      }
    } else {
      let extensions = null;

      if (getState().search.database.main.selectedOptionId === 7) {
        // File Name option
        
        if (fileExt.value === '') {
          // Nothing
        } else if (fileExt.value.includes('doc')) {
          query = `${query} AND (fileExtension:doc OR fileExtension:docx)`;
          extensions = 'doc,docx';
        } else if (fileExt.value.includes('xls')) {
          query = `${query} AND (fileExtension:xls OR fileExtension:xlsx)`;
          extensions = 'xls,xlsx';
        } else {
          query = `${query} AND fileExtension:${fileExt.value}`;
          extensions = `${fileExt.value}`;
        }
      }

      try {
        requestAnimationFrame(mockProgress);
        dispatch(setQuery({ query, op: 1 }));
        const requestResponse = await api.post(
          'service/create_bucket_search_request/',
          { search_term: query, extensions },
          { signal },
        );
        if (signal.aborted) throw new Error('Request cancelled');

        progressValue = 10;
        step = 1;
        requestAnimationFrame(mockProgress);
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const statusResponse = await api.get(
            `service/get_bucket_search_request/${requestResponse.data.id}/`,
            { signal },
          );
          if (signal.aborted) throw new Error('Request cancelled');

          if (statusResponse.data.status === 'finished') break;
          await delay(2500);
        }

        progressValue = 75;
        step = 2;
        requestAnimationFrame(mockProgress);
        const resultResponse = await api.get(
          'service/list_bucket_search_result/',
          { params: { searchrequest: requestResponse.data.id }, signal },
        );
        if (signal.aborted) throw new Error('Request cancelled');

        progressValue = 95;
        step = 3;
        requestAnimationFrame(mockProgress);
        await api.delete(
          `service/delete_bucket_search_request/${requestResponse.data.id}/`,
          { signal },
        );
        if (signal.aborted) throw new Error('Request cancelled');

        step = 4;

        return { result: resultResponse.data };
      } catch (error) {
        isError = true;

        if (signal.aborted) {
          return rejectWithValue('Request cancelled');
        }

        return rejectWithValue(error.response.data || 'Failed to search data');
      }
    }
  },
);

const cloudStorageSlice = createSlice({
  name: 'search/database/cloudStorage',
  initialState: {
    query: '',
    query2: '',
    list: [],
    list2: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      if (action.payload.op === 1) state.query = action.payload.query;
      else state.query2 = action.payload.query;
    },
    swapResults: (state, action) => {
      let temp = state.query;
      state.query = state.query2;
      state.query2 = temp;

      temp = state.list;
      state.list = state.list2;
      state.list2 = temp;

      state.totalElements = state.list.length;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.query = state.query2 = '';
        state.list = state.list2 = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        if (action.payload.result2) {
          let temp = state.query;
          state.query = state.query2;
          state.query2 = temp;

          state.list = action.payload.result2;
          state.list2 = action.payload.result;
        } else {
          state.list = action.payload.result;
        }
        state.totalElements = state.list.length;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.list = [];
        state.list2 = [];
        state.totalElements = 0;

        if (action.payload !== 'Request cancelled') {
          state.loading = false;
          state.error = action.payload;
        }
      })
      .addCase(searchActions.resetProgress, (state, action) => {
        if (action.payload === "Database") state.progress = 0;
      });
  },
});

const { setQuery, setProgress } = cloudStorageSlice.actions;

export const { swapResults } = cloudStorageSlice.actions;
export default cloudStorageSlice.reducer;
