import React from 'react';

const SearchResultTitle = ({ selectedOptionLabel, title }) => {
  return (
    <div className="tw-flex tw-gap-3">
      <span className="tw-font-bold tw-text-lg tw-text-[#263238] tw-uppercase">
        {title} Results
      </span>
      <div className="tw-h-fit tw-bg-secondary tw-rounded-full tw-font-medium tw-text-xs tw-whitespace-nowrap tw-px-2 tw-py-1">
        {selectedOptionLabel} Search
      </div>
    </div>
  );
};

export default SearchResultTitle;
