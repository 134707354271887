import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import Loading from 'common/components/Loading/Loading';
import useExport from 'hooks/use-export';
import { search as searchSSLLogs } from 'store/slices/search/database/sslLogs';

import SearchResultTitle from '../SearchResultTitle';
import SearchResultError from '../SearchResultError';

const SSLlogs = ({ selectedOptionLabel, title, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    query,
    list: sslLogs,
    sort,
    size,
    length,
    currentPage,
    totalElements,
    totalPages,
    loading,
    error,
  } = useSelector((state) => state.search.database.sslLogs);
  
  const [isPageSearch, setIsPageSearch] = useState(false);

  const setCurrentPage = (page) => {
    setIsPageSearch(true);

    dispatch(
      searchSSLLogs({
        page: page - 1,
        progress: false,
      }),
    );
  };

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: (value) => (
        <span className="tw-text-[#44464A]">
          {moment(value).format('YYYY-MM-DD')}
        </span>
      ),
    },
    {
      Header: 'Domain',
      accessor: 'domain',
      render: defaultRenderer,
    },
    {
      Header: 'Issuer',
      accessor: 'issuer',
      render: defaultRenderer,
    },
    {
      Header: 'Validity',
      accessor: 'validity',
      render: (_, record) => (
        <span className=" tw-text-[#44464A]">
          {record.notBefore
            ? moment(record.notBefore).format('YYYY-MM-DD')
            : ''}{' '}
          —{' '}
          {record.notAfter ? moment(record.notAfter).format('YYYY-MM-DD') : ''}
        </span>
      ),
    },
  ];

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'certificate', sort, size, length, searchTerm });

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!(loading && !isPageSearch) ? (
        <>
          <SearchResultTitle
            selectedOptionLabel={selectedOptionLabel}
            title={title}
          />

          <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-z-20">
            <div className="tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search results
                </span>
                <span className="tw-text-sm tw-font-medium">
                  {totalElements}
                </span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search term
                </span>
                <span className="tw-text-sm tw-font-medium">{query}</span>
              </div>
            </div>

            <ExportButton
              onChange={handleExportOptionChange}
              isLoading={exporting}
              disabled={!canExport}
            />
          </div>

          {error ? (
            <SearchResultError errorText={error} />
          ) : (
            <div className="tw-relative">
              <Table
                data={sslLogs}
                columns={columns}
                selectable={true}
                onRowSelection={handleRowSelection}
              />
              <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
                <div className={loading ? 'tw-invisible' : 'tw-visible'}>
                  <p className="tw-my-4">
                    Showing ssl logs:{' '}
                    {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                    {currentPage * size > totalElements
                      ? totalElements
                      : currentPage * size}{' '}
                    of {totalElements}
                  </p>
                </div>

                {totalPages > 1 && (
                  <Paginator
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                )}
              </div>
              {loading && (
                <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
              )}
            </div>
          )}
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default SSLlogs;
