import { createSlice } from '@reduxjs/toolkit';

const mainSlice = createSlice({
  name: 'search/database/main',
  initialState: {
    selectedOptionId: 0,
    currentOptionId: -1,
    currentQuery: '',
    lastSearchOptionId: -1,
    lastSearchQuery: '',
    isSearching: false,
  },
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOptionId = action.payload;
    },
    setCurrentSearchParams: (state, action) => {
      state.currentOptionId = action.payload.optionId;
      state.currentQuery = action.payload.query;
    },
    setLastSearchParams: (state) => {
      state.lastSearchOptionId = state.currentOptionId;
      state.lastSearchQuery = state.currentQuery;
      state.currentOptionId = -1;
      state.currentQuery = '';
    },
    setSearching: (state, action) => {
      state.isSearching = action.payload;
    },
  },
});

export const { setSelectedOption, setCurrentSearchParams, setLastSearchParams, setSearching } = mainSlice.actions;

export default mainSlice.reducer;
