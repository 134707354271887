import React from "react";

const FilterIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82174 3.72239C1.19146 3.01796 0.876314 2.66574 0.864427 2.3664C0.854101 2.10636 0.965847 1.85643 1.16652 1.69074C1.39753 1.5 1.87015 1.5 2.8154 1.5H15.1845C16.1298 1.5 16.6024 1.5 16.8334 1.69074C17.0341 1.85643 17.1458 2.10636 17.1355 2.3664C17.1236 2.66574 16.8084 3.01796 16.1782 3.72239L11.423 9.03703C11.2973 9.17745 11.2345 9.24766 11.1897 9.32756C11.15 9.39843 11.1208 9.47473 11.1032 9.55403C11.0833 9.64345 11.0833 9.73766 11.0833 9.92609V14.382C11.0833 14.5449 11.0833 14.6264 11.057 14.6969C11.0338 14.7591 10.996 14.8149 10.9468 14.8596C10.8912 14.9102 10.8155 14.9404 10.6642 15.001L7.83088 16.1343C7.52459 16.2568 7.37145 16.3181 7.24851 16.2925C7.14101 16.2702 7.04666 16.2063 6.98599 16.1148C6.91662 16.0101 6.91662 15.8452 6.91662 15.5153V9.92609C6.91662 9.73766 6.91662 9.64345 6.89672 9.55403C6.87907 9.47473 6.84992 9.39843 6.81019 9.32756C6.7654 9.24766 6.70258 9.17745 6.57694 9.03703L1.82174 3.72239Z"
        stroke={props.fill || "#44464A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
