import { MagnifyingGlass } from "@phosphor-icons/react";
import React, { useState, useEffect } from "react";
import api from 'store/api';
import CrossIcon from '../../../common/Icons/CrossIcon';
import CustomModel from '../../../common/components/Model/Model';
import { useErrorbar, useSnackbar } from '../../../utils/snackbar';
 
const DomainDropdown = ({
  defaultCompany, domains, setDomains, setSelectedDomain, setSelectedCountry, setSelectedIndustry
}) => {
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await api.get('/settings/list_companies/');
        const domainData = response.data.map(item => ({
          id: item.id,
          domain: item.domain,
          checked: item.domain === defaultCompany.domain,
        }));
        setDomains(domainData);
        setFilteredDomains(domainData);
      } catch (error) {
        console.error('Error fetching domain data:', error);
      }
    };
    fetchDomains();
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredDomains(domains);
      return;
    }
    const results = domains.filter(item =>
      item.domain.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDomains(results);
  }, [searchTerm]);

  const handleCompanyChange = async (domainId) => {
    try {
      const response = await api.post('/service/set_default_company/', { id: domainId });
      console.log("set_default_company response:", response.data);
    } catch (error) {
      console.error("Error in set_default_company request:", error);
    }
    setSelectedDomain(domainId);

    const newDomains = [...domains];
    let pauseFlag = false;
    newDomains.forEach((domain) => {
      if (domain.id === domainId) {
        if (domain.checked) {
          pauseFlag = true;
        } else {
          domain.checked = true;
        }
      } else {
        domain.checked = false;
      }
    });
    if (pauseFlag) {
      return;
    }
    setDomains(newDomains);
    setSelectedCountry('');
    setSelectedIndustry('');
  };

  const confirmDelete = async () => {
    const result = await api.delete(`settings/remove_company/${companyToDelete}/`);
    setConfirmationModalOpen(false);

    if (result.status === 204) {
      useSnackbar("Deleted the company successfully!");
      setDomains((prevDomains) => prevDomains.filter((domain) => domain.id !== companyToDelete));
      setFilteredDomains((prevDomains) => prevDomains.filter((domain) => domain.id !== companyToDelete));

      // If default company is removed, set the first domain as default
      if (defaultCompany.id === companyToDelete) {
        const newDefaultCompany = domains.find((domain) => domain.id !== companyToDelete);
        if (newDefaultCompany) {
          await handleCompanyChange(newDefaultCompany.id);
        }
      }
    } else {
      useErrorbar("Delete report failed!");
    }
  };

  const handleRemoveCompany = async (companyId) => {
    setCompanyToDelete(companyId);
    setConfirmationModalOpen(true);
  };

  return (
    <div className="tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      <h2 className="tw-text-lg tw-font-medium tw-mb-4">Select Domain</h2>

      {/* Search Input */}
      <div className="tw-relative tw-mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
        />
        <span className="tw-absolute tw-left-3 tw-top-2 tw-text-gray-400">
          <MagnifyingGlass size={24} />
        </span>
      </div>

      {/* Recent Added List */}
      <h3 className="tw-text-sm tw-font-medium tw-mb-2">Recent Selected</h3>
      <div className="tw-h-40 tw-overflow-y-auto">
        {filteredDomains.length > 0 ? (
          filteredDomains.map((item, index) => (
            <label
              key={index}
              className="tw-flex tw-items-center tw-justify-between tw-mb-2 tw-py-1.5 tw-px-2 tw-border-b tw-border-gray-100 hover:tw-bg-gray-50 tw-transition-all"
            >
              <div className="tw-flex tw-items-center">
                <input
                  type="checkbox"
                  name="domain"
                  checked={item.checked}
                  onChange={() => handleCompanyChange(item.id)}
                  className="tw-mr-2 tw-h-5 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                />
                <span className="tw-text-sm tw-text-gray-700">{item.domain}</span>
              </div>
              <button
                onClick={() => handleRemoveCompany(item.id)}
                className="tw-text-gray-500 hover:tw-text-red-600 tw-transition-colors tw-ml-4 tw-p-2 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center"
                aria-label="Remove domain"
              >
                <CrossIcon />
              </button>
            </label>
          ))
        ) : (
          <div className="tw-text-sm tw-text-center tw-text-gray-700">
            No data available
          </div>
        )}
      </div>
      <CustomModel isOpen={isConfirmationModalOpen}>
        <div className="tw-flex tw-flex-col tw-items-center">
          <h2 className="tw-text-xl tw-font-bold tw-mb-4">Confirm Delete</h2>
          <p className="tw-mb-4">Are you sure you want to delete this company?</p>
          <div className="tw-flex tw-gap-4">
            <button
              onClick={confirmDelete}
              className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            >
              Yes
            </button>
            <button
              onClick={() => setConfirmationModalOpen(false)}
              className="tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            >
              No
            </button>
          </div>
        </div>
      </CustomModel>
    </div>
  );
};

export default DomainDropdown;
