import React, { useRef, useState } from 'react';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ArrowDown, InfoIcon, Setting } from '../../../common/Icons';
import BenchmarkChart from "../../charts/bench_marks/bench_marks";
import AddCompanyButton from "../../charts/bench_marks/AddCompanyButton";
import DarkNet from "../DarkNet/dark-net.component";
import DomainDropdown from '../DarkNet/DomainDropdown';
import { useOutsideClick } from 'hooks/useOutsideClick';

function BookMark({defaultCompany, setDefaultCompany}) {
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDomainDropdownOpen, setDomainDropdownOpen] = useState(false);
  const companyModalRef = useRef(null);
  const domainDropdownRef = useRef(null);
  useOutsideClick(companyModalRef, () => setModalOpen(false));
  useOutsideClick(domainDropdownRef, () => setDomainDropdownOpen(false));

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const toggleDomainDropdown = (event) => {
    event.stopPropagation();
    setDomainDropdownOpen(!isDomainDropdownOpen);
  };

  return (
    <div className="tw-p-4 tw-bg-white tw-rounded-lg tw-border">
      {/* Header Section */}
      <div className="tw-pt-4 tw-flex tw-justify-between tw-items-center tw-h-full">
        <div className="tw-flex tw-gap-1 tw-items-center">
          <p className="tw-text-lg tw-font-semibold">Benchmark and Risk Score</p>
          <div className="my-anchor-elements">
            <InfoIcon />
          </div>
          <Tooltip
            anchorSelect=".my-anchor-elements"
            place="top-end"
            effect="solid"
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "black",
              width: "800px",
              zIndex: "999999",
              opacity: "1",
            }}
          >
            <p>
              Create benchmarks for your organization based on industry type,
              <br></br> country, or specific competitors.<br></br>For benchmarks
              based on industry or country, we compare your <br></br> organization
              to others in our database of similar size <br></br>within the same
              country or industry.<br></br>When selecting competitors, you can
              directly compare your metrics <br></br>against a specific
              competitor's domain.
            </p>
          </Tooltip>
        </div>
        {/* AddCompanyButton and DomainDropdown */}
        <div className="tw-flex tw-gap-4 tw-items-center tw-relative">
          <div>
            <div onClick={toggleModal} className="tw-cursor-pointer">
              <Setting />
            </div>
            {isModalOpen && (
              <div
                ref={companyModalRef}
                className="tw-absolute tw-mt-2 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <AddCompanyButton onClose={toggleModal}
                      domains={domains} setDomains={setDomains}
                      setSelectedDomain={setSelectedDomain}
                      setSelectedCountry={setSelectedCountry} setSelectedIndustry={setSelectedIndustry} />
              </div>
            )}
          </div>
          <div>
            <div
              onClick={toggleDomainDropdown}
              className="tw-w-full tw-cursor-pointer tw-py-1.5 tw-gap-2 tw-px-2  tw-mr-1 tw-bg-[#F9F9F9] tw-text-gray-700 tw-rounded-md tw-flex tw-items-center tw-justify-center"
            >
              Domain <ArrowDown />
            </div>
            {isDomainDropdownOpen && (
              <div
                ref={domainDropdownRef}
                className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <DomainDropdown
                  defaultCompany={defaultCompany}
                  domains={domains}
                  setDomains={setDomains}
                  setSelectedDomain={setSelectedDomain}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedIndustry={setSelectedIndustry}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Subtitle */}
      <p className="tw-flex tw-justify-start tw-text-base tw-font-normal tw-mt-1">
        Discover how your organization measures up against similar ones.
      </p>

      {/* Main Content */}
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-12 tw-gap-3 tw-mt-2">
        <div className="md:tw-col-span-7">
          <BenchmarkChart selectedDomain={selectedDomain}
                          selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
                          selectedIndustry={selectedIndustry} setSelectedIndustry={setSelectedIndustry} />
        </div>
        <div className="md:tw-col-span-5">
          <DarkNet selectedDomain={selectedDomain} defaultCompany={defaultCompany} setDefaultCompany={setDefaultCompany} />
        </div>
      </div>
    </div>
  );
}

export default BookMark;
