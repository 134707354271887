import React from 'react';
import { MagnifyingGlass } from 'react-loader-spinner';

const Loading = ({ label, size = 'normal', showProgress = false, progress = 0 }) => {
  return (
    <div
      className={
        'tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1 tw-bg-white tw-rounded-lg ' +
        (size === 'small' ? 'tw-py-[100px]' : size === 'large' ? 'tw-py-[200px]' : 'tw-py-[150px]')
      }
    >
      <div>
        <MagnifyingGlass visible={true} height="80" width="80" glassColor="#c0efff" color="#333" />
      </div>
      <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#263238]">
        {label}
      </div>

      {showProgress && (
        <div className="tw-w-full tw-max-w-[300px] tw-flex tw-gap-3 tw-items-center">
          <div className={`tw-w-full tw-bg-[#EFF0F2] tw-rounded-lg tw-h-[6px]`}>
            <div
              className={`tw-rounded-lg tw-h-[6px]`}
              style={{
                backgroundColor: '#ECC551',
                width: `${progress}%`,
              }}
            ></div>
          </div>

          <div className="tw-text-xs tw-font-normal tw-leading-[14.06px] tw-text-[#797C84]">
            {Math.ceil(progress)}%
          </div>
        </div>
      )}
    </div>
  );
};

export default Loading;
