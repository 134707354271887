import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import DeleteIcon from "../../../../common/Icons/DeleteIcon";
import PlusIcon from "../../../../common/Icons/PlusIcon";
import { useErrorbar, useSnackbar } from "utils/snackbar";
import api from 'store/api';

const EmailRecipient = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      recipientFields: [{ recipientEmail: "" }],
    },
  });
  const {
    fields: recipientFields,
    append: appendRecipient,
    remove: removeRecipient,
  } = useFieldArray({
    name: "recipientFields",
    control,
  });

  const onSubmit = async (data) => {
    for (const field of data.recipientFields) {
      await postEmailRecipient(field.recipientEmail);
    }
    //useSnackbar("All recipients have been successfully saved.");
  };

  const postEmailRecipient = async (email) => {
    try {
      const response = await api.post('service/alert_system_setting_create_recipient/', {
        email: email,
        is_default: true
      });
      useSnackbar('Successfully saved email recipient.')
    } catch (error) {
      console.error('POST request failed:', error);
      useSnackbar("Request failed Network Error", 'error');
    }
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div className="tw-bg-[#F5F6F8] tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-rounded-lg">
        <p className="tw-px-4 tw-py-3 tw-border-b md:tw-border-b-0 md:tw-border-r tw-w-full tw-text-center md:tw-w-max tw-border-[#DFE0E3] tw-text-[#263238] tw-font-semibold">
          Recipient
        </p>
        <p className="tw-px-4 tw-py-3 tw-text-[#455A64]">
          Enter One or Multiple E-mail Recipients Below
        </p>
      </div>
      <div className="">
        {recipientFields.map((item, index) => (
          <div key={item.id} className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
            <InputGroup
              // labelText="email"
              inputType="email"
              placeholder="email@email.com"
              inputName={`recipientFields.${index}.recipientEmail`}
              register={register}
              errors={errors}
              focusOnType={true}
            />

            <div className=" tw-flex tw-items-center tw-gap-4">
              <button
                type="button"
                onClick={() => appendRecipient({ recipientEmail: "" })}
                className="tw-text-[#455A64] tw-flex tw-items-center tw-gap-1"
              >
                <PlusIcon color={"#2B2F38"} />{" "}
                <span>Add Another Recipient</span>
              </button>

              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeRecipient(index)}
                  className="tw-text-red-800 tw-flex tw-items-center tw-gap-1"
                >
                  <DeleteIcon /> <span>Delete</span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center">
        <button
          className="tw-bg-primary tw-w-full md:tw-w-max tw-text-white tw-py-2 tw-px-4  tw-rounded-lg"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </button>
        <button
          className="tw-text-primary tw-w-full md:tw-w-max tw-bg-white tw-border tw-py-2 tw-px-4  tw-rounded-lg"
          onClick={() => setIsNewExpertRuleOpen(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EmailRecipient;
