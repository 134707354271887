import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/fakeApps/search',
  async ({ query, signal }, { getState, dispatch, rejectWithValue }) => {
    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (isError || signal.aborted) return;

      if (step === 0 && progressValue < 10) {
        progressValue++;
        dispatch(setProgress(progressValue));

        await delay(50);
        requestAnimationFrame(mockProgress);
      } else if (step === 1 && progressValue < 50) {
        progressValue++;
        dispatch(setProgress(progressValue));
        await delay(600);
        requestAnimationFrame(mockProgress);
      } else if (step === 2 && progressValue < 95) {
        progressValue++;
        dispatch(setProgress(progressValue));
        await delay(500);
        requestAnimationFrame(mockProgress);
      } else if (step === 3 && progressValue < 100) {
        progressValue++;
        dispatch(setProgress(progressValue));
        await delay(50);
        requestAnimationFrame(mockProgress);
      }
    };

    if (getState().search.database.main.selectedOptionId === 0) {
      // Domain option
      query = query.split('.')[0];
    } else if (getState().search.database.main.selectedOptionId === 5) {
      // Brand option
      const brand = query.toLowerCase().split(' ');
      if (brand.length > 1) {
        query = `${brand[0]}${brand[1]} AND ${brand[0]}-${brand[1]}`;
      }
    }
    dispatch(setQuery(query));

    try {
      requestAnimationFrame(mockProgress);
      const requestResponse = await api.post(
        'service/create_store_search_request/',
        { search_term: query },
        { signal },
      );
      if (signal.aborted) throw new Error('Request cancelled');

      progressValue = 10;
      step = 1;
      requestAnimationFrame(mockProgress);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(
          `service/get_store_search_request/${requestResponse.data.id}/`,
          { signal },
        );
        if (signal.aborted) throw new Error('Request cancelled');

        if (statusResponse.data.status === 'finished') break;
        await delay(2500);
      }

      progressValue = 50;
      step = 2;
      requestAnimationFrame(mockProgress);
      const resultResponse = await api.get(
        'service/list_store_search_result/',
        { params: { searchrequest: requestResponse.data.id }, signal },
      );
      if (signal.aborted) throw new Error('Request cancelled');

      progressValue = 95;
      step = 3;
      requestAnimationFrame(mockProgress);
      await api.delete(
        `service/delete_store_search_request/${requestResponse.data.id}/`,
        { signal },
      );
      if (signal.aborted) throw new Error('Request cancelled');

      return resultResponse.data;
    } catch (error) {
      isError = true;

      if (signal.aborted) {
        return rejectWithValue('Request cancelled');
      }

      return rejectWithValue(error.response.data || 'Failed to search data');
    }
  },
);

const fakeAppsSlice = createSlice({
  name: 'search/database/fakeApps',
  initialState: {
    query: '',
    list: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload;
        state.totalElements = state.list.length;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.list = [];
        state.totalElements = 0;

        if (action.payload !== 'Request cancelled') {
          state.loading = false;
          state.error = action.payload;
        }
      })
      .addCase(searchActions.resetProgress, (state, action) => {
        if (action.payload === "Database") state.progress = 0;
      });
  },
});

const { setQuery, setProgress } = fakeAppsSlice.actions;
export default fakeAppsSlice.reducer;
