import { combineReducers } from '@reduxjs/toolkit';

import mainReducer from './main';
import hackerForumReducer from './hackerForum';
import socialNetworkReducer from './socialNetwork';
import externalSourceReducer from './externalSource';
import dataPasteReducer from './dataPaste';

export default combineReducers({
  main: mainReducer,
  hackerForum: hackerForumReducer,
  socialNetwork: socialNetworkReducer,
  externalSource: externalSourceReducer,
  dataPaste: dataPasteReducer,
});
