import React, {useState, useEffect} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import DeleteIcon from "../../../../common/Icons/DeleteIcon";
import PlusIcon from "../../../../common/Icons/PlusIcon";
import api from 'store/api';
import { useErrorbar, useSnackbar } from "utils/snackbar";

const TestEmail = () => {
  const [senderEmail, setSenderEmail] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({});

  const onSubmit = async (data) => {
    await postEmailTest(data.testEmailRecipient);
  };

  useEffect(() => {
    getEmailDefaultSender();
  }, []);

  const getEmailDefaultSender = async () => {
    try{
      const response = await api.get('service/alert_system_setting_get_sender_list');
      const senderList = response.data;
      const defaultSender = senderList.find(sender => sender.is_default === true);
    
      if (defaultSender) {
        const senderID = defaultSender.id;
        setSenderEmail(senderID);
      } else {
        console.log('No default sender found');
        return null;
      }
    } catch(error) {
      console.log('Error fetching sender list:', error);
      return null;
    }
  };

  const postEmailTest = async (email) => {
    try {
      if(!senderEmail) {
        useSnackbar('There is no default sender', 'error');
        return;
      }
       const response = await api.post('service/alert_system_setting_test_email/', {
        sender: senderEmail,
        recipient: email,
      });
      useSnackbar('Successfully Tested.')
    } catch (error) {
      console.error('POST request failed:', error);
      useSnackbar("Request failed Network Error", 'error');
    }
  }
  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div className="">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
          <InputGroup
            labelText="Test email"
            extraLabel="(enter the email recipient)"
            inputType="email"
            placeholder="email@email.com"
            inputName="testEmailRecipient"
            register={register}
            errors={errors}
            focusOnType={true}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center">
        <button
          className="tw-bg-primary tw-w-full md:tw-w-max tw-text-white tw-py-2 tw-px-4  tw-rounded-lg"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>
        <button
          className="tw-text-primary tw-w-full md:tw-w-max tw-bg-white tw-border tw-py-2 tw-px-4  tw-rounded-lg"
          onClick={() => setIsNewExpertRuleOpen(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TestEmail;
