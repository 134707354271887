import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import api from 'store/api';

function Risky() {
  // Define different datasets for each dropdown option
  const [data, setData] = useState({series: [
    {
      name: "Number of users per leak",
      data: [70, 60, 50, 40, 30, 20, 10], // Data for last 30 days
    },
  ],
  categories: ["70", "60", "50", "40", "30", "20", "10"],});

  // State to store the selected option
  const [selectedOption, setSelectedOption] = useState("Last 30 Days");

  const riskUserReport = async () => {
    try {
      const result = await api.get("service/risky_users_chart_stats/");
      const apiResponse = result.data.results;
      const sortedData = apiResponse.sort((a, b) => b.number_of_leaks - a.number_of_leaks);
      const processedAPIData = {
        series: [
          {
            name: "Number of users per leak",
            data: sortedData.map(item => item.number_of_users_per_leak),
          },
        ],
        categories: sortedData.map(item => String(item.number_of_leaks)),
      };
      setData(processedAPIData);
      return result.data.results; // Return the fetched data
    } catch (error) {
      console.error("Error fetching risky users chart stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    riskUserReport();
  }, []);

  // Handle option change
  const handleSelect = (option) => {
    console.log("Selected option:", option);
    setSelectedOption(option);
  };

  // Get the current data based on the selected option
  const chartData = {
    series: data.series,
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: data.categories,
        labels: {
          rotate: 0,
          rotateAlways: false,
          style: {
            cssClass: "apexcharts-xaxis-label",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        title: {
          text: "Number of leaks",
          style: {
            fontSize: "14px",
            fontWeight: "semibold",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val;
          },
          style: {
            cssClass: "apexcharts-yaxis-label",
            fontSize: "14px",
            fontWeight: "semibold",
            marginRight: "2px"
          },
        },
        title: {
          text: "Number of users per leak",
          style: {
            fontSize: "14px",
            fontWeight: "semibold",
            marginRight: "2px"
          },
        },
      },
      grid: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 1,
          gradientToColors: ["#ECC551"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
          colorStops: [
            {
              offset: 0,
              color: "#F9EDC9",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#ECC551",
              opacity: 1,
            },
          ],
        },
      },
    },
  };

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-h-full">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-gap-1">
          <h2 className="tw-text-xl tw-font-bold">Risky Users</h2>
        </div>

        {/* <div className="tw-flex tw-items-center tw-gap-2">
          <GenericDropdown
            options={["Last 30 Days", "Last Year", "Last 6 Month", "All Time"]}
            onSelect={handleSelect}
            defaultText={selectedOption}
            staticFirstOption={true}
          />
        </div> */}
      </div>

      <p className=" tw-flex tw-justify-start tw-text-base tw-font-normal ">
        Number of users appearing in multiple leaks{" "}
      </p>
      <div className="tw-bg-white tw-rounded-lg tw-px-2 tw-pt-4">
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={220}
        />
      </div>
    </div>
  );
}

export default Risky;
