import React from 'react';

import { DarknetSearchOptions } from 'common/components/SearchComponent/data';

import BellYellow from 'common/Icons/BellYellow';
import CustomButton from 'common/components/Button/Button';

import CustomTabs from '../../../pages/tabs';
import HackerMarket from './components/hacker/HackerMarket';
import SocialNetworks from './components/social/SocialNetworks';
import ExternalSources from './components/external/ExternalSources';
import DataPastes from './components/data-pastes/DataPastes';
import Telegram from './components/telegram/Telegram';
import Discord from './components/discord/Discord';

const SearchResult = ({ selectedOption, setSearch, searchTerm }) => {

  const currentTab = (selectedOption) => {
    switch (selectedOption.id) {
      case 2: // Hacker Marketplaces
        return <HackerMarket isResultView={true} />;
      case 3: // Social Media Mentions
        return <SocialNetworks isResultView={true} />;
      case 4: // Tor and I2P Network
        return <ExternalSources setSearch={setSearch} isResultView={true} />;
      case 5: // Paste Live Search
        return <DataPastes isResultView={true} />;
      case 6: // Telegram
        return <Telegram isResultView={true} />;
      case 7: // Discord
        return <Discord isResultView={true} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
        <div className="tw-flex tw-items-center tw-gap-[9.45px]">
          <BellYellow />
          <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
            If you didn&apos;t find what you were looking for, try refining your search or click
            &lsquo;New search&rsquo; to start again.
          </div>
        </div>
        <CustomButton
          text="New Search"
          onClick={() => {
            setSearch(false);
            // navigate('/darknet-search');
          }}
          className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
        />
      </div>

      {selectedOption.id !== 1 ? (
        currentTab(selectedOption)
      ) : (
        <>
          <div className="tabs-sec ">
            <CustomTabs>
              <div label={DarknetSearchOptions[1].label}>
                <HackerMarket isResultView={true} />
              </div>
              <div label={DarknetSearchOptions[2].label}>
                <SocialNetworks isResultView={true} />
              </div>
              <div label={DarknetSearchOptions[3].label}>
                <ExternalSources setSearch={setSearch} isResultView={true} />;
              </div>
              <div label={DarknetSearchOptions[4].label}>
                <DataPastes isResultView={true} />
              </div>
              <div label={DarknetSearchOptions[5].label}>
                <Telegram selectedOptionLabel="Telegram" searchTerm={searchTerm} />
              </div>
              <div label={DarknetSearchOptions[6].label}>
                <Discord selectedOptionLabel="Discord" searchTerm={searchTerm} />
              </div>
            </CustomTabs>
          </div>
        </>
      )}
    </>
  );
};

export default SearchResult;
