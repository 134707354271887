import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/stealerLogs/search',
  async (
    { page = 0, size, sort, query, progress = true, signal },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState().search.database.stealerLogs;

    if (_.isNil(page)) page = state.currentPage - 1;
    if (_.isNil(size)) size = state.size;
    if (_.isNil(sort)) sort = state.sort;
    if (_.isNil(query)) query = state.query;

    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (isError || signal?.aborted) return;

      if (step === 0 && progressValue < 85) {
        progressValue++;
        dispatch(setProgress(progressValue));

        await delay(50);
        requestAnimationFrame(mockProgress);
      }
    };

    try {
      if (progress) {
        requestAnimationFrame(mockProgress);

        if (getState().search.database.main.selectedOptionId === 2) {
          // Name option
          const names = query.toLowerCase().split(' ');
          if (names.length > 1) {
            query = names[0] + ' AND ' + names[1];
          }
        } else if (getState().search.database.main.selectedOptionId === 3) {
          // IP Address option
          const segments = query.split('.');
          if (segments.length >= 3) {
            if (query.indexOf("/") === -1) {
              query = `${segments[0]}.${segments[1]}.${segments[2]}`;
            }
          }
        }
  
        dispatch(setQuery(query));
      }

      dispatch(setParams({ size, sort }));
      const response = await api.get('service/botnet_record_search/', {
        params: {
          page,
          size,
          sort,
          query,
        },
        signal
      });
      if (signal?.aborted) throw new Error('Request cancelled');
      step = 1;

      return response.data;
    } catch (error) {
      isError = true;

      if (signal?.aborted) {
        return rejectWithValue('Request cancelled');
      }

      return rejectWithValue(error.response.data || 'Failed to search data');
    }
  },
);

const stealerLogsSlice = createSlice({
  name: 'search/database/stealerLogs',
  initialState: {
    query: '',
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.sort = action.payload.sort;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        const { hasContent, totalElements, totalPages } = action.payload;

        state.list = hasContent ? action.payload.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.number + 1;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        
        if (action.payload !== 'Request cancelled') {
          state.loading = false;
          state.error = action.payload;
        }
      })
      .addCase(searchActions.resetProgress, (state, action) => {
        if (action.payload === "Database") state.progress = 0;
      });
  },
});

const { setQuery, setParams, setProgress } = stealerLogsSlice.actions;
export default stealerLogsSlice.reducer;
