import _ from 'lodash';
import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const crawlerSearch = createAsyncThunk(
  'search/live/hackerForum/crawler',
  async (_, { rejectWithValue }) => {
    try {
      const resultResponse = await api.get(
        `service/get_active_forum_crawlers/`,
        {
          params: { page: 1, page_size: 100 },
        },
      );

      return resultResponse.data.results.sort((a, b) =>
        a.front_name < b.front_name ? -1 : 1,
      );
    } catch (error) {
      return rejectWithValue(error.response.data || 'Fetch crawlers failed');
    }
  },
);

export const search = createAsyncThunk(
  'search/live/hackerForum/search',
  async ({ forum, query }, { getState, dispatch, rejectWithValue }) => {
    const state = getState().search.live.hackerForum;

    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 10) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(50);
          requestAnimationFrame(mockProgress);
        } else if (step === 1 && progressValue < 95) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(600);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    // if (_.isNil(forum))
    //   forum = state.crawlers.map((crawler) => crawler.name).join(',');

    try {
      dispatch(setQuery(query));

      requestAnimationFrame(mockProgress);
      await api.post('service/live_hf_search_request_create/', {
        search_keyword: query,
        forum,
        date: moment().format('YYYY-MM-DD'),
      });

      progressValue = 10;
      step = 1;
      requestAnimationFrame(mockProgress);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const resultResponse = await api.get(
          `/service/live_hf_search_result_list/`,
        );

        dispatch(setResult(resultResponse.data));

        if (resultResponse.data.running_tasks.length === 0) break;
        await delay(2500);
      }
      step = 2;

      return true;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

export const refreshSearchResult = createAsyncThunk(
  'search/live/hackerForum/refreshSearchResult',
  async (_, { dispatch }) => {
    const resultResponse = await api.get(
      `/service/live_hf_search_result_list/`,
    );

    dispatch(setResult(resultResponse.data));
    return true;
  },
);

const hackerForumSlice = createSlice({
  name: 'search/live/hackerForum',
  initialState: {
    query: '',
    runningTasks: [],
    list: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
    crawlers: [],
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setResult: (state, action) => {
      state.runningTasks = action.payload.running_tasks;
      state.list = action.payload.historical_tasks;
      state.totalElements = state.list.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.totalElements = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state) => {
        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.runningTasks = state.list = [];
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(crawlerSearch.fulfilled, (state, action) => {
        state.crawlers = action.payload;
      })
      .addCase(crawlerSearch.rejected, (state) => {
        state.crawlers = [];
      })
      .addCase(searchActions.resetProgress, (state, action) => {
        if (action.payload === 'Live') state.progress = 0;
      });
  },
});

const { setQuery, setProgress, setResult } = hackerForumSlice.actions;
export default hackerForumSlice.reducer;
