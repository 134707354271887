import React, { useState, useEffect, useRef } from 'react';

import Table from 'components/common/table/table.component';
import CustomButton from 'common/components/Button/Button';
import ExportButton from 'common/components/Export/ExportButton';
import DocFile from 'components/assets/svgs/DocFile';
import ArrowBack from 'common/Icons/ArrowBack';
import CrossIcon from 'common/Icons/CrossIcon';
import DownloadFileIcon from 'common/Icons/DownloadFileIcon';
import Loader from 'common/components/Loader/Loader';
import Loading from 'common/components/Loading/Loading';

import api from 'store/api';
import { isURL, capitalizeFirstLetter, replaceExtension } from 'utils/global';
import { useSnackbar, useErrorbar } from 'utils/snackbar';

const HackerMarketDetail = ({
  searchOptionLabel,
  details,
  setIsDetailView,
}) => {
  // Arrange the search result details
  details = [...details].sort((a, b) => {
    // Define sorting priorities
    const getPriority = (item) => {
      if (item.state === 'SUCCESS' && isURL(item.res)) return 1; // SUCCESS with URL
      if (item.state === 'SUCCESS') return 2; // Other SUCCESS
      if (item.state === 'FAILURE') return 3; // FAILURE
      if (item.state === 'PENDING') return 4; // PENDING
      return 5; // Fallback (unlikely to occur)
    };

    // Compare priorities
    const priorityA = getPriority(a);
    const priorityB = getPriority(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB; // Lower priority value comes first
    }

    // If priorities are the same, sort alphabetically by `forum`
    return a.forum.localeCompare(b.forum);
  });

  const [selectedForum, setSelectedForum] = useState('');
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const handleShowDetail = async (record) => {
    try {
      setSelectedForum(record.forum);

      setLoadingDetail(true);
      setIsDetailModalOpen(true);

      const resultResponse = await api.get(
        'service/live_hf_search_result_detail/',
        {
          params: {
            celery_id: record.celery_id,
          },
        },
      );

      setSelectedRecord(resultResponse.data[0]);

      setLoadingDetail(false);
    } catch (error) {
      useErrorbar(error.response.data || 'Failed to retrieve detail data');
      setLoadingDetail(false);
    }
  };

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const columns = [
    {
      Header: 'Forum',
      accessor: 'forum',
      render: (_, record) => {
        return <span>{capitalizeFirstLetter(record.forum)}</span>;
      },
    },
    {
      Header: <div className="tw-text-center">Forum Connection</div>,
      accessor: 'forum_connection',
      render: (_, record) => (
        <div className="tw-text-center">
          <span
            className={
              record.state === 'SUCCESS'
                ? 'tw-whitespace-nowrap tw-bg-green-50 tw-border tw-border-green-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-green-700'
                : record.state === 'FAILURE'
                ? 'tw-whitespace-nowrap tw-bg-red-50 tw-border tw-border-red-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-red-700'
                : 'tw-whitespace-nowrap tw-bg-amber-50 tw-border tw-border-amber-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-amber-700'
            }
          >
            {record.state}
          </span>
        </div>
      ),
    },
    {
      Header: <div className="tw-text-center">State</div>,
      accessor: 'detail_state',
      render: (_, record) => (
        <div className="tw-text-center">
          {record.state === 'SUCCESS' ? (
            isURL(record.res) ? (
              <span className="tw-whitespace-nowrap tw-bg-green-50 tw-border tw-border-green-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-green-700">
                Search term found
              </span>
            ) : record.res === 'NOT FOUND' ? (
              <span className="tw-whitespace-nowrap tw-bg-red-50 tw-border tw-border-red-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-red-700">
                Nothing found
              </span>
            ) : (
              <span className="tw-whitespace-nowrap tw-bg-amber-50 tw-border tw-border-amber-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-amber-700 tw-capitalize">
                {record.res.toLowerCase()}
              </span>
            )
          ) : (
            '—'
          )}
        </div>
      ),
    },
    {
      Header: <div className="tw-text-right">Results</div>,
      accessor: 'res',
      render: (_, record) => (
        <div className="tw-flex tw-justify-end tw-pe-4">
          <button
            onClick={() => handleShowDetail(record)}
            disabled={!(record.state === 'SUCCESS' && isURL(record.res))}
          >
            <DocFile
              color={
                record.state === 'SUCCESS' && isURL(record.res)
                  ? '#D4B149FF'
                  : '#D4B1494D'
              }
            />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-gap-2">
          <div
            onClick={() => setIsDetailView(false)}
            className="tw-cursor-pointer"
          >
            <ArrowBack />
          </div>
          <span className="tw-font-semibold">
            {searchOptionLabel} Search Details
          </span>
        </div>
      </div>

      <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-z-20">
        <div className="tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">
              {details[0].search_keyword}
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search date</span>
            <span className="tw-text-sm tw-font-medium">{details[0].date}</span>
          </div>
        </div>
        <ExportButton disabled={true} />
      </div>
      <Table data={details} columns={columns} />

      <DetailModal
        forumName={selectedForum}
        data={selectedRecord}
        loading={loadingDetail}
        isOpen={isDetailModalOpen}
        onClose={closeDetailModal}
      />
    </>
  );
};

export default HackerMarketDetail;

// Detail Modal Component
const DetailModal = ({ forumName, loading, data, isOpen, onClose }) => {
  if (!isOpen) return null;

  const [downloadingZip, setDownloadingZip] = useState(false);
  const [downloadingHtml, setDownloadingHtml] = useState(false);
  const [downloadingScreenshot, setDownloadingScreenshot] = useState(false);
  const [isEnlargeScreenshot, setEnlargeScreenshot] = useState(false);

  const handleDownloadZip = async (data) => {
    try {
      setDownloadingZip(true);

      const fileName = new URLSearchParams(new URL(data.res).search).get(
        'file',
      );

      const response = await fetch(data.res);
      if (!response.ok) {
        throw new Error('Failed to download the archive file');
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);

      useSnackbar('The archive file has been successfully downloaded.');
    } catch (error) {
      useErrorbar(error || 'Failed to download the archive file');
    } finally {
      setDownloadingZip(false);
    }
  };

  const handleDownloadHTML = (data) => {
    try {
      setDownloadingHtml(true);
      const zipFileName = new URLSearchParams(new URL(data.res).search).get(
        'file',
      );
      const htmlFileName = replaceExtension(zipFileName, 'html');

      const blob = new Blob([data.zip_extracted_info.sample_html_file], {
        type: 'text/html',
      });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = htmlFileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);

      useSnackbar('The HTML page has been successfully downloaded.');
    } catch (error) {
      useErrorbar(error || 'Failed to download the HTML page');
    } finally {
      setDownloadingHtml(false);
    }
  };

  const handleDownloadScreenshot = async (data) => {
    try {
      setDownloadingScreenshot(true);
      const zipFileName = new URLSearchParams(new URL(data.res).search).get(
        'file',
      );
      const screenshotFileName = replaceExtension(zipFileName, 'jpg');

      const response = await fetch(data.zip_extracted_info.sample_image);
      const blob = await response.blob();

      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadUrl;
      link.download = screenshotFileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);

      useSnackbar('The screenshot has been successfully downloaded.');
    } catch (error) {
      useErrorbar(error || 'Failed to download the HTML page');
    } finally {
      setDownloadingScreenshot(false);
    }
  };

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center tw-z-[100]">
      <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-xl tw-min-w-[700px] tw-w-4/5 tw-h-4/5 tw-relative">
        {/* Cross Icon for closing */}
        <button
          className="tw-absolute tw-top-3 tw-right-3 tw-text-gray-400 hover:tw-text-gray-600"
          onClick={onClose}
        >
          <CrossIcon />
        </button>

        <h2 className="tw-font-bold tw-text-lg">
          Forum {capitalizeFirstLetter(forumName)} Detail
        </h2>

        <div className="tw-border tw-border-[#DFE0E3] tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-3 tw-h-4/5 tw-overflow-y-scroll">
          <div className="tw-flex tw-justify-between tw-items-center tw-border-b tw-border-[#DFE0E3] tw-pb-4">
            <div className="tw-flex tw-gap-1 tw-font-medium tw-text-md">
              <span className="tw-text-[#35363A]">Search Keyword: </span>
              <span>{data.search_keyword ?? ''}</span>
            </div>
            <div>
              <CustomButton
                onClick={() => handleDownloadZip(data)}
                text="Download Archive File"
                icon={
                  downloadingZip ? (
                    <Loader className="tw-text-[#ffffff] !tw-w-5 !tw-h-5 tw-ms-2" />
                  ) : (
                    <DownloadFileIcon color="#ffffff" />
                  )
                }
                className="tw-w-full tw-py-[10px] tw-rounded-md tw-px-3 tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-h-full tw-text-sm tw-font-semibold tw-leading-6 tw-text-left"
              />
            </div>
          </div>

          {!loading ? (
            !isEnlargeScreenshot ? (
              <>
                <div className="tw-flex tw-gap-8 tw-text-sm tw-text-[#44464A]">
                  <div>
                    <p className="tw-font-semibold">ID</p>
                    <p>{data.celery_id}</p>
                  </div>
                  <div>
                    <p className="tw-font-semibold">Search Date</p>
                    <p>{data.date}</p>
                  </div>
                  <div className="tw-flex tw-grow">
                    <div>
                      <div className="tw-flex tw-gap-2">
                        <p className="tw-font-semibold">Screenshot</p>
                        {data.zip_extracted_info.sample_image ? (
                          <button
                            title="Download the screenshot"
                            onClick={() => handleDownloadScreenshot(data)}
                          >
                            <DownloadFileIcon color="#00B6FF" />
                          </button>
                        ) : null}
                      </div>
                      <p>
                        {data.zip_extracted_info.sample_image
                          ? 'Please click on the thumbnail to enlarge'
                          : 'None'}
                      </p>
                    </div>
                    {data.zip_extracted_info.sample_image ? (
                      <div
                        className="tw-w-full tw-max-w-[600px] tw-h-40 tw-ms-4"
                        style={{
                          backgroundImage:
                            'url(' + data.zip_extracted_info.sample_image + ')',
                          backgroundSize: 'cover',
                        }}
                        onClick={() => setEnlargeScreenshot(true)}
                      ></div>
                    ) : null}
                  </div>
                </div>

                {data.zip_extracted_info.sample_html_file ? (
                  <div className="tw-text-sm tw-text-[#44464A]">
                    <p className="tw-font-semibold tw-mb-1">Download</p>
                    <p>
                      Please download the page{' '}
                      <span
                        className="tw-font-semibold tw-text-[#00B6FF] tw-cursor-pointer"
                        onClick={() => handleDownloadHTML(data)}
                      >
                        here
                      </span>
                    </p>
                  </div>
                ) : null}

                {/* <div>
                <div className="tw-flex tw-gap-2">
                  <p className="tw-text-sm tw-font-semibold tw-text-[#44464A] tw-mb-1">
                    Page
                  </p>
                  <button title="Download the page">
                    <DownloadFileIcon color="#00B6FF" />
                  </button>
                </div>
                <div className="tw-border tw-border-[#DFE0E3] tw-mt-4 tw-p-4 tw-rounded-lg">
                  // <div
                  //   className="tw-text-sm tw-text-[#0B0B0B]"
                  //   dangerouslySetInnerHTML={{
                  //     __html: data.zip_extracted_info.sample_html_file,
                  //   }}
                  // ></div>
                  <ResponsiveIframe
                    htmlContent={data.zip_extracted_info.sample_html_file}
                  />
                </div>
              </div> */}
              </>
            ) : (
              <img
                src={data.zip_extracted_info.sample_image}
                onClick={() => setEnlargeScreenshot(false)}
              />
            )
          ) : (
            <Loading label="Loading Forum Details..." size="small" />
          )}
        </div>

        <div className="tw-flex tw-justify-end tw-space-x-4 tw-mt-6">
          <CustomButton
            text="Close"
            bgColor="tw-bg-secondary"
            textColor="tw-text-primary"
            hoverTextColor="white"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

const ResponsiveIframe = ({ htmlContent }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const adjustIframeHeight = () => {
      if (iframeRef.current) {
        const iframeDocument =
          iframeRef.current.contentDocument ||
          iframeRef.current.contentWindow.document;
        if (iframeDocument) {
          const contentHeight = iframeDocument.body.scrollHeight;
          iframeRef.current.style.height = `${contentHeight}px`;
        }
      }
    };

    // Adjust height after iframe loads
    const iframeElement = iframeRef.current;
    iframeElement?.addEventListener('load', adjustIframeHeight);

    // Cleanup event listener on unmount
    return () => {
      iframeElement?.removeEventListener('load', adjustIframeHeight);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      title="Webpage content"
      srcDoc={htmlContent}
      style={{
        width: '100%',
        border: 'none',
        transition: 'height 0.2s ease-out',
      }}
    />
  );
};
