import React, { useState, useEffect, useCallback } from "react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import api from "store/api";
import CrossIcon from "../../../common/Icons/CrossIcon";
import CustomModel from '../../../common/components/Model/Model';
import { useErrorbar, useSnackbar } from '../../../utils/snackbar';

const CompetitorDropdown = ({ onClose, activeTab, setCompetitorList, setCompetitorStat }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeOptions, setActiveOptions] = useState([]);
  const [newDomain, setNewDomain] = useState("");
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [competitorToDelete, setCompetitorToDelete] = useState(null);

  // Filter options by search term
  const filteredOptions = activeOptions.filter((option) =>
    option.domain.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const fetchData = async () => {
    try {
      const competitorResponse = await api.get("/service/competitors/");
      const defaultCompany = competitorResponse.data.default_company;
      const competitorObjects = competitorResponse.data.competitors;

      setActiveOptions(competitorObjects);
      setCompetitorList(competitorObjects.map((comp) => comp.domain));

      const data = [defaultCompany, ...competitorObjects].map(item => ({
        x: item.domain,
        y: item.leak_count,
        last_update: item.last_update,
      }));
      setCompetitorStat(data);
    } catch (error) {
      console.error(`Error fetching ${activeTab} data:`, error);
    }
  };

  // Fetch list of competitors
  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const confirmDelete = async () => {
    const result = await api.delete(`/service/competitors/${competitorToDelete}/`);
    setConfirmationModalOpen(false);

    if (result.status === 200) {
      useSnackbar("Deleted the competitor successfully!");

      setActiveOptions((prevOptions) =>
        prevOptions.filter((option) => option.id !== competitorToDelete)
      );
      fetchData();
      onClose();
    } else {
      useErrorbar("Delete competitor failed!");
    }
  };

  const handleRemoveCompetitor = async (competitorId) => {
    setCompetitorToDelete(competitorId);
    setConfirmationModalOpen(true);
  };

  const handleAddCompetitor = useCallback(async () => {
    if (!newDomain.trim())
      return;

    try {
      const response = await api.post("/service/competitors/", {
        domain: newDomain,
      });

      if (response.status !== 201) {
        useErrorbar("Adding competitor failed!");
        console.error("Error adding competitor:", response);
      } else {
        useSnackbar("Added competitor successfully!");
        setNewDomain("");
        fetchData();
        onClose();
      }
    } catch (error) {
      console.error("Error adding competitor:", error);
    }
  }, [newDomain]);

  return (
    <div className="tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      {/* Add Competitor Section */}
      <div className="tw-flex tw-gap-2 tw-mb-4">
        <input
          type="text"
          value={newDomain}
          onChange={(e) => setNewDomain(e.target.value)}
          placeholder="Competitor Domain"
          className="tw-flex-1 tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
        />
        <button
          onClick={handleAddCompetitor}
          className="tw-bg-yellow-500 hover:tw-bg-yellow-600 tw-text-white tw-px-4 tw-rounded-md"
        >
          Add
        </button>
      </div>

      {/* Search Input */}
      <div className="tw-relative tw-mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
        />
        <span className="tw-absolute tw-left-3 tw-top-2 tw-text-gray-400">
          <MagnifyingGlass size={24} />
        </span>
      </div>

      {/* Options List */}
      <div className="tw-h-40 tw-overflow-y-auto">
        {filteredOptions.length > 0 ? (
          filteredOptions.map((item, index) => (
            <label
              key={index}
              className="tw-flex tw-items-center tw-justify-between tw-mb-2 tw-py-1.5 tw-px-2 tw-border-b tw-border-gray-100 hover:tw-bg-gray-50 tw-transition-all"
            >
              <div className="tw-flex tw-items-center">
                <span className="tw-text-sm tw-text-gray-700">{item.domain}</span>
              </div>
              <button
                onClick={() => handleRemoveCompetitor(item.id)}
                className="tw-text-gray-500 hover:tw-text-red-600 tw-transition-colors tw-ml-4 tw-p-2 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center"
                aria-label="Remove domain"
              >
                <CrossIcon />
              </button>
            </label>
          ))
        ) : (
          <div className="tw-text-sm tw-text-center tw-text-gray-700">
            No data available
          </div>
        )}
      </div>

      <CustomModel isOpen={isConfirmationModalOpen}>
        <div className="tw-flex tw-flex-col tw-items-center">
          <h2 className="tw-text-xl tw-font-bold tw-mb-4">Confirm Delete</h2>
          <p className="tw-mb-4">Are you sure you want to delete this competitor?</p>
          <div className="tw-flex tw-gap-4">
            <button
              onClick={confirmDelete}
              className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            >
              Yes
            </button>
            <button
              onClick={() => setConfirmationModalOpen(false)}
              className="tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            >
              No
            </button>
          </div>
        </div>
      </CustomModel>
    </div>
  );
};

export default CompetitorDropdown;
