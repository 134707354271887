import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import ReactApexChart from "react-apexcharts";
import { Tooltip } from "react-tooltip";
import api from 'store/api';
import CustomTable from "../../common/table/table.component";
import {ChartButton, ChartLine, InfoIcon, Setting} from "../../../common/Icons";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import { useOutsideClick } from "hooks/useOutsideClick";
import { aggregateDataPerMonth } from 'utils/global';

function Leak({defaultCompany}) {
  const [view, setView] = useState("chart");
  const [selectedPeriod, setSelectedPeriod] = useState("Last Week");
  const [lineChartData, setLineChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [latestLeaks, setLatestLeaks] = useState([]);
  const [options, setOptions] = useState([
    { name: "Show All Leaks", checked: true },
    { name: "Show Only Leaks Related to Your Company", checked: false },
  ]);
  const [isDropdownOpen, setDropdownOpen] = useState(false); 
  const settingsDropdownRef = useRef(null);
  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));

  const columns = [
    { Header: "Created", accessor: "created" },
    { Header: "Name", accessor: "name" },
    { Header: "Size", accessor: "size" },
  ];

  const toggleDropdown = (event) => {
    event.stopPropagation(); 
    setDropdownOpen((prev) => !prev);
  };

  const handleCheckboxChange = (index) => {
    const newOptions = [...options];

    newOptions[index].checked = !newOptions[index].checked;
    newOptions.forEach((option, i) => {
      if (i !== index) {
        option.checked = !newOptions[index].checked;
      }
    });

    setOptions(newOptions);
    setDropdownOpen(false);
  };

  const handleSelectPeriod = (option) => {
    setSelectedPeriod(option);
  };

  const getPeriodValue = (option) => {
    const index = periods.indexOf(option);
    return period_values[index];
  };

  const getLeakTrendStats = async () => {
    try {
      const value = getPeriodValue(selectedPeriod);
      const checkedOption = options.find((option) => option.checked);
      let result = null;
      if (checkedOption.name === "Show All Leaks") {
        result = await api.get("service/get_total_leak_history/", {
          params: {
            period: value,
          },
        });
      } else {
        result = await api.get("service/get_company_leak_history/", {
          params: {
            period: value,
          },
        });
      }
      const data = result.data.map((item) => ({
        date: item.date,
        count: item.leak_count,
      }));
      setLineChartData(data);
    } catch (error) {
      console.error("Error fetching bubble chart stats:", error);
      throw error;
    }
  }

  const getDatabaseSearch = async () => {
    try {
      const checkedOption = options.find((option) => option.checked);
      let result = null;
      if (checkedOption.name === "Show All Leaks") {
        result = await api.get("service/leak_simple_database_search/", {
          params: {
            sort: 'createdAt,desc',
            query: 'createdAt:[' + moment().subtract(30, 'days').format('YYYY-MM-DD') + ' TO ' + moment((new Date())).format('YYYY-MM-DD') + ']',
          },
        });
      } else {
        result = await api.get("service/leak_simple_database_search/", {
          params: {
            sort: 'createdAt,desc',
            query: 'createdAt:[' + moment().subtract(30, 'days').format('YYYY-MM-DD') + ' TO ' + moment((new Date())).format('YYYY-MM-DD') + '] AND ' + defaultCompany.domain,
          },
        });
      }
      const content = result.data.content.map((item) => ({
        created: moment(item.createdAt).format('YYYY-MM-DD'),
        name: item.leakName,
        size: item.leakSize
      }));
      setLatestLeaks(content.slice(0, 5));
    } catch (error) {
      console.error("Error fetching stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    getLeakTrendStats();
    getDatabaseSearch();
  }, [selectedPeriod, options, defaultCompany]);

  useEffect(() => {
    if (lineChartData.length === 0)
      return;

    const dates = lineChartData.map(item => item.date);
    const counts = lineChartData.map(item => item.count);
    const minDate = moment.min(dates.map(date => moment(date)));
    const maxDate = moment.max(dates.map(date => moment(date)));
    const diffDays = maxDate.diff(minDate, 'days');
    const shouldAggregate = diffDays > 31;

    if (shouldAggregate) {
      const aggregatedData = aggregateDataPerMonth(lineChartData);
      const aggregatedMonths = Object.keys(aggregatedData).sort((a, b) => {
        return moment(a, "MMMM YYYY").toDate() - moment(b, "MMMM YYYY").toDate();
      });
      const aggregatedCounts = aggregatedMonths.map(month => aggregatedData[month]);

      setChartOptions({
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: aggregatedMonths,
          title: {
            text: "Month",
          },
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        fill: {
          type: "solid",
          colors: ["#ECC551"],
        },
        colors: ["#ECC551"],
        grid: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      });

      setChartSeries([
        {
          name: "Leaks Count",
          data: aggregatedCounts,
        },
      ]);
    } else {
      setChartOptions({
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            rotate: -55,
            rotateAlways: true
          },
          tickAmount: 10,
          categories: dates,
          title: {
            text: "Date",
          },
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        fill: {
          type: "solid",
          colors: ["#ECC551"],
        },
        colors: ["#ECC551"],
        grid: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      });

      setChartSeries([
        {
          name: "Leaks Count",
          data: counts,
        },
      ]);
    }
  },  [lineChartData]);

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg">
      <div className="tw-flex  tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-gap-1">
          <h2 className="tw-text-sm lg:tw-text-base tw-font-bold tw-whitespace-nowrap">
            {view === "chart" ? "Leak Trend Statistics" : "Latest Leaks"}
          </h2>
          <div className="my-anchor-elementsa23">
            <InfoIcon />
          </div>
          <Tooltip
            anchorSelect=".my-anchor-elementsa23"
            place="top"
            style={{ backgroundColor: "white", color: "black", width: "550px", zIndex: 20 }}
          >
            The Leak Trend Analysis provides a real-time overview of the most
            recent data leaks discovered and documented by our analysts. You can
            access this information through a graphical view for visual insights
            or a list view for detailed data representation, allowing you to
            monitor emerging threats and assess their relevance to your
            organization
          </Tooltip>
          <div className="tw-relative">
            <div onClick={toggleDropdown} className="tw-cursor-pointer">
              <Setting />
            </div>
            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                className="tw-absolute tw-mt-2 tw-p-4 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <h3 className="tw-text-sm tw-font-medium tw-mb-2">Different Types:</h3>
                <div className="tw-max-h-40 tw-overflow-y-auto">
                  {options.map((option, index) => (
                    <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-p-2">
                      <input
                        type="checkbox"
                        checked={option.checked}
                        onChange={() => handleCheckboxChange(index)}
                        className="tw-mr-2 tw-h-5 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                      />
                      <span className="tw-text-sm tw-text-gray-600">
                        {option.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tw-flex tw-w-full tw-justify-end tw-items-end tw-gap-2">
          <div onClick={() => setView("chart")} className="tw-cursor-pointer">
            {/* Render ChartLine with condition based on `view` */}
            <ChartLine selected={view === "chart"} />
          </div>
          <div onClick={() => setView("table")} className="tw-cursor-pointer">
            {/* Render ChartButton with condition based on `view` */}
            <ChartButton selected={view === "table"} />
          </div>
        </div>
      </div>
      {view === "chart" && (
        <>
          <GenericDropdown
            options={periods}
            defaultText={selectedPeriod}
            onSelect={handleSelectPeriod}
            staticFirstOption={true}
          />

          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={330}
          />
        </>
      )}
      {view === "table" && (
        <div className="tw-mt-12">
          <CustomTable data={latestLeaks} columns={columns} selectable={false} />
        </div>
      )}
    </div>
  );
}

export default Leak;
const periods = ["Last Week", "Last Month", "Last Year"];
const period_values = ['last_week', 'last_month', 'last_year'];
