import React, {useState, useEffect} from "react";
import CustomTable from "../../common/table-no-paginator/table.component";
import Heading from "../../../common/components/heading/Heading";
import api from 'store/api';
 
function TopRisky() {
  const columns = [
    { Header: "Top 3 Risky Users", accessor: "username" },
    { Header: "Results", accessor: "count" },
  ];

  const [chartData, setChartData] = useState([]);

  const topRiskyUserReport = async () => {
    try {
      const result = await api.get("service/top_risky_users/");
      setChartData(result.data.results);
      return result.data.results; // Return the fetched data
    } catch (error) {
      console.error("Error fetching risky users chart stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    topRiskyUserReport();
  }, []);
  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow tw-h-full">
      <Heading size="h2" text="Top Risky Users" />
      <CustomTable data={chartData} columns={columns} selectable={false} height="200px"/>
    </div>
  );
}

export default TopRisky;
