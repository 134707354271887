import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";

const CustomSelect = ({ options, onSelect, defaultText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    defaultText || "Select an option"
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) onSelect(option);
  };

  return (
    <div className="tw-relative tw-w-fit" ref={dropdownRef}>
      <button
        className="tw-bg-[#F9F9F9] tw-relative tw-w-fit tw-rounded-lg tw-px-4 tw-py-2 tw-text-[#5B5D63] tw-text-sm tw-flex tw-gap-2 tw-items-center"
        onClick={toggleDropdown}
      >
        {selectedOption}
        <IoIosArrowDown />
      </button>
      {isOpen && (
        <div className="tw-absolute tw-bg-[#F9F9F9] tw-right-0 tw-left-0 tw-py-[5px] tw-px-3 tw-top-[40px] tw-rounded-lg tw-z-[99]">
          {options.map((option, index) => (
            <button
              key={index}
              className="tw-pb-1 tw-text-[#5B5D63] tw-text-sm tw-cursor-pointer tw-block"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
