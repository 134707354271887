import React, { useRef, useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import api from 'store/api';
import { ArrowDown } from "../../../common/Icons";
import CountryDropdown from "./CountryDropDown";
import CompetitorDropdown from './CompetitorDropDown';
import { useOutsideClick } from "hooks/useOutsideClick";

function BenchmarkChart({
  selectedDomain, selectedCountry,setSelectedCountry, selectedIndustry, setSelectedIndustry
}) {
  //Statistic data of company
  const [statData, setStatData] = useState({
    "leak_count": 0,
    "average_leak_count": 0,
    "median_leak_count": 0
  });
  const [competitorStat, setCompetitorStat] = useState([0]);
  const [competitorList, setCompetitorList] = useState([]);
  const [activeTab, setActiveTab] = useState("country");
  const [defaultSearchButtonTitle, setDefaultSearchButtonTitle] = useState('Select an option');

  const categoriesData = {
    country: ['Mean', 'Median', 'Your organization'],
    industry: ['Mean', 'Median', 'Your organization'],
    competitor: ['Your organization', ...competitorList],
  };
  // Common chart options
  const chartOptions = {
    chart: {
      offsetY: 20,
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      background: "#FFFFFF",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        barHeight: "40%",
        distributed: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    xaxis: {
      categories: categoriesData[activeTab],
    },
    fill: {
      colors: ["#F9EDC9", "#BD9E41", "#ECC551"],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent']
    },
    grid: {
      borderColor: "#ECECEC",
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          const dataPoint = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          if (dataPoint.last_update === null) {
            return "Calculating...";
          }
          return `${value}`;
        }
      },
    },
  };

  const seriesData = {
    country: [
      {
        name: "Leaks Count",
        data: [statData.average_leak_count, statData.median_leak_count, statData.leak_count]
      },
    ],
    industry: [
      {
        name: "Leaks Count",
        data: [statData.average_leak_count, statData.median_leak_count, statData.leak_count]
      },
    ],
    competitor: [
      {
        name: "Total",
        data: competitorStat
      },
    ],  
  };

  useEffect(() => {
    const fetchStat = async () => {
      try {
        const response = await api.get(`/service/get_company_stat/`,
          {
            params: (activeTab === 'country' ?
                {
                  country_name: selectedCountry ? selectedCountry.name : '',
                } :
                {
                  industry_name: selectedIndustry ? selectedIndustry.name : '',
                }
            )
          });
        setStatData(response.data)
      } catch (error) {
        console.error('Error fetching company stat data:', error);
      }
    }
    if (activeTab === 'country' || activeTab === 'industry') fetchStat();

    const fetchCompetitorStat = async () => {
      try {
        const response = await api.get(`/service/competitors/`);
        if (response.status !== 200)
          return console.error('Error fetching competitor data:', response)

        const allCompetitors = response.data.competitors.map(comp => comp.domain);
        setCompetitorList(allCompetitors);

        const competitorObjects = [
          response.data.default_company,
          ...response.data.competitors
        ];
        const data = competitorObjects.map(item => ({
          x: item.domain,
          y: item.leak_count,
          last_update: item.last_update,
        }));
        setCompetitorStat(data);
      } catch (error) {
        console.error('Error fetching company stat data:', error);
      }
    }
    if (activeTab === 'competitor') fetchCompetitorStat();

  }, [activeTab, selectedCountry, selectedIndustry, selectedDomain]);

  useEffect(() => {
    let defaultSearchTitle = '';
    switch (activeTab) {
      case 'country':
        defaultSearchTitle = selectedCountry ? selectedCountry.name : 'Select Country';
        break;
      case 'industry':
        defaultSearchTitle = selectedIndustry ? selectedIndustry.name : 'Select Industry';
        break;
      case 'competitor':
        defaultSearchTitle = 'Add/Delete Competitor';
        break;
      default:
        defaultSearchTitle = 'Select an option';
    }
    setDefaultSearchButtonTitle(defaultSearchTitle);
  }, [activeTab, selectedCountry, selectedIndustry]);

  const [isDropdownOpenSetting, setDropdownOpenSetting] = useState(false);
  const settingsDropdownRefSetting = useRef(null);
  useOutsideClick(settingsDropdownRefSetting, () => setDropdownOpenSetting(false));
  const toggleDropdownSetting = () => {
    setDropdownOpenSetting(!isDropdownOpenSetting);
  };

  return (
    <div className="tw-bg-white tw-border tw-shadow tw-rounded-lg tw-p-4 tw-h-full">
      <h4 className="tw-text-base tw-text-dark tw-font-bold">Benchmarks</h4>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-mt-4">
        <div className=" tw-border-b-4 tw-border-gray-300 tw-justify-start">
          {["country", "industry", "competitor"]?.map((tab) => (
            <button
              key={tab}
              className={`tw-flex-1 tw-pb-4 -tw-mb-2 tw-px-4 tw-text-base tw-font-medium tw-text-center tw-transition tw-duration-150 tw-ease-in-out ${
                activeTab === tab
                  ? "tw-text-black tw-border-b-4 tw-border-yellow-500 "
                  : "tw-text-gray-500 tw-bg-transparent tw-hover:tw-bg-gray-100"
              }`}
              onClick={() => setActiveTab(tab)}
              style={{ textTransform: "capitalize" }}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="tw-flex tw-relative tw-flex-col md:tw-flex-row tw-justify-end md:tw-gap-1 lg:tw-gap-2 tw-mt-2">
          {(activeTab !== 'competitor') && (
            <div>
              <div onClick={toggleDropdownSetting} className="tw-w-full tw-cursor-pointer tw-py-1.5 tw-gap-2 tw-px-2 tw-mr-1 tw-bg-[#F9F9F9] tw-text-gray-700 tw-rounded-md tw-flex tw-items-center tw-justify-center hover:tw-bg-yellow-200]">
                {defaultSearchButtonTitle} <ArrowDown />
              </div>
              {isDropdownOpenSetting && (
                <div
                  ref={settingsDropdownRefSetting}
                  className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
                >
                  <CountryDropdown
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    selectedIndustry={selectedIndustry}
                    setSelectedIndustry={setSelectedIndustry}
                    activeTab={activeTab}/>
                </div>
              )}
            </div>
          )}
          {activeTab === 'competitor' && (
            <div>
              <div onClick={toggleDropdownSetting} className="tw-w-full tw-cursor-pointer tw-py-1.5 tw-gap-2 tw-px-2 tw-mr-1 tw-bg-[#F9F9F9] tw-text-gray-700 tw-rounded-md tw-flex tw-items-center tw-justify-center hover:tw-bg-yellow-200]">
                {defaultSearchButtonTitle} <ArrowDown />
              </div>
              {isDropdownOpenSetting && (
                <div
                  ref={settingsDropdownRefSetting}
                  className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
                >
                  <CompetitorDropdown onClose={toggleDropdownSetting} activeTab={activeTab}
                                      setCompetitorList={setCompetitorList} setCompetitorStat={setCompetitorStat} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <ReactApexChart
        options={chartOptions}
        series={seriesData[activeTab]}
        type="bar"
        height={300}
      />
    </div>
  );
}

export default BenchmarkChart;
