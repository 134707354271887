import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import CustomButton from "../../../common/components/Button/Button";
import InputGroup from "../../../common/components/InputGroup/InputGroup";

const DomainSelector = ({ setDropdownOpen, domains, handleCheckboxChange}) => {
  
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddDomain, setIsAddDomain] = useState(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDomains = domains.filter((domain) =>
    domain.root_domain.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      {!isAddDomain && (
        <>
          <h2 className="tw-text-lg tw-font-medium tw-mb-4">Choose Domains</h2>

          {/* Search Input */}
          <div className="tw-relative tw-mb-4">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
            />
            <span className="tw-absolute tw-left-3 tw-top-2 tw-text-gray-400">
              <MagnifyingGlass size={24} />
            </span>
          </div>

          {/* Add Domain Button */}
          {/* <CustomButton
            text="Add Domain"
            icon={<Plus />}
            onClick={() => setIsAddDomain(true)}
            className="tw-w-full tw-py-2 tw-mb-4  tw-border tw-border-yellow-400 tw-text-yellow-500 tw-font-medium tw-rounded-md tw-flex tw-items-center tw-justify-center hover:tw-bg-[#FCF6E5] hover:tw-border-[#FCF6E5] tw-transition-all tw-duration-200"
          /> */}

          {/* Recent Added List */}

          <h3 className="tw-text-sm tw-font-medium tw-mb-2">Recent Added</h3>
          <div className="tw-h-40 tw-overflow-y-auto">
            {filteredDomains.map((domain, index) => (
              <label key={index} className="tw-flex tw-items-center tw-mb-2">
                <input
                  type="checkbox"
                  checked={domain.active}
                  onChange={() => handleCheckboxChange(index)}
                  className="tw-mr-2 tw-h-5 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                />
                <span className="tw-text-sm tw-text-gray-700">
                  {domain.root_domain}
                </span>
              </label>
            ))}
          </div>

          {/* Apply/Cancel Buttons */}
          <div className="tw-flex tw-mt-4 tw-gap-2">
            <button 
            onClick={() => setDropdownOpen(false)}
            className="tw-bg-black tw-text-white tw-px-4 tw-py-2 tw-rounded-md tw-font-medium hover:tw-bg-gray-800">
              Apply
            </button>
            <button
              onClick={() => setDropdownOpen(false)}
              className="tw-bg-transparent tw-border tw-border-[#DFE0E3] tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-md tw-font-medium hover:tw-bg-gray-300"
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {isAddDomain && (
        <div className="tw-flex tw-flex-col tw-gap-5">
          <span className="tw-text-sm tw-font-medium tw-text-[#0b0b0b]">
            Add Domain
          </span>
          <span className="tw-text-sm tw-font-medium tw-text-black">
            Domain Name
          </span>
          <InputGroup placeholder="olivia@untitledui.com" />
          {/* Apply/Cancel Buttons */}
          <div className="tw-flex tw-mt-4 tw-gap-2">
            <button className="tw-bg-black tw-text-white tw-px-4 tw-py-2 tw-rounded-md tw-font-medium hover:tw-bg-gray-800">
              Save
            </button>
            <button
              onClick={() => setIsAddDomain((prev) => !prev)}
              className="tw-bg-transparent tw-text-gray-700 tw-px-4 tw-py-2 tw-border tw-border-[#DFE0E3] tw-rounded-md tw-font-medium hover:tw-bg-gray-300"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DomainSelector;
