import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { DarknetSearchOptions } from 'common/components/SearchComponent/data';

import Heading from 'common/components/heading/Heading';
import CustomButton from 'common/components/Button/Button';
import ExportButton from 'common/components/Export/ExportButton';
import FunnelFilter from 'common/Icons/FunnelFilter';
import Table from 'components/common/table/table.component';
import NoResult from 'common/components/NoResult/NoResult';
import Loading from 'common/components/Loading/Loading';

import SearchTipFeedback from '../../SearchTipFeedback';

const crawlers = [
  {
    forum_name: 'Example.com',
    status: 'FAILED',
  },
  {
    forum_name: 'Example.com',
    status: 'SUCCESS',
  },
  {
    forum_name: 'Example.com',
    status: 'SUCCESS',
  },
  {
    forum_name: 'Example.com',
    status: 'SUCCESS',
  },
  {
    forum_name: 'Example.com',
    status: 'SUCCESS',
  },
  {
    forum_name: 'Example.com',
    status: 'FAILED',
  },
  {
    forum_name: 'Example.com',
    status: 'FAILED',
  },
];

const DataPastes = ({ isResultView = false, showTip = true }) => {
  const optionIndex = 4;
  const searchOptionLabel = DarknetSearchOptions[optionIndex].label;

  const { query, loading, progress, list, totalElements } = useSelector(
    (state) => state.search.live.dataPaste,
  );
  const size = 10;

  console.log(list[0]);

  const columns = [
    {
      Header: 'Link',
      accessor: 'link',
      render: (value) => (
        <a href={value} target="_blank">
          <span className="tw-text-[#00B6FF] tw-block tw-break-all">{value}</span>
        </a>
      ),
    },
    {
      Header: 'Title',
      accessor: 'title',
      render: (value) => <span className="tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'Content',
      accessor: 'content',
      render: (value) => (
        <span className="tw-block tw-whitespace-normal tw-break-all tw-text-[#44464A]">
          {value}
        </span>
      ),
    },
    {
      Header: 'Date',
      accessor: 'appeared_date',
      render: (value) => <span className="tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'Source',
      accessor: 'source',
      render: (value) => <span className="tw-capitalize tw-text-[#44464A]">{value}</span>,
    },
  ];

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  // Function to match the heights of child divs
  const matchHeights = () => {
    try {
      const section1 = section1Ref.current;
      const section2 = section2Ref.current;

      const height = section1.offsetHeight;
      section2.setAttribute('style', `height:${height}px !important`);
    } catch (error) {
      // resume
    }
  };

  useEffect(() => {
    matchHeights();
    window.addEventListener('resize', matchHeights);

    return () => window.removeEventListener('resize', matchHeights);
  }, []);

  if (loading) {
    return (
      <Loading
        label="Please wait while the search is in progress..."
        size="large"
        showProgress={true}
        progress={progress}
      />
    );
  } else {
    if (!isResultView && list.length === 0) {
      return <NoResult />;
    }
  }

  return (
    <>
      {showTip && (
        <div className="tw-mb-4">
          <SearchTipFeedback optionIndex={optionIndex} />
        </div>
      )}

      <div className="tw-grid tw-grid-rows-1 tw-grid-cols-5 tw-gap-4">
        <div
          className="tw-col-span-5 lg:tw-col-span-4 tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4"
          ref={section1Ref}
        >
          <h1 className="tw-text-lg tw-font-bold">{`${searchOptionLabel} Search Result`}</h1>

          <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-z-20">
            <div className="tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">Search results</span>
                <span className="tw-text-sm tw-font-medium">{totalElements}</span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">Search term</span>
                <span className="tw-text-sm tw-font-medium">{query}</span>
              </div>
            </div>

            <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-1 md:tw-gap-4">
              <CustomButton
                bgColor="tw-bg-white"
                textColor="tw-text-primary"
                icon={<FunnelFilter />}
                text="Filters"
                className="tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border !tw-border-solid tw-border-[#DFE0E3] tw-rounded-md tw-text-sm tw-font-semibold tw-leading-6 tw-text-center hover:tw-bg-[#F5F6F8]"
              />

              <ExportButton disabled />
            </div>
          </div>

          <Table data={list} columns={columns} paginatorLabel="pastes" rowsPerPage={size} />
        </div>

        <div
          className="tw-col-span-5 lg:tw-col-span-1 tw-bg-white tw-p-4 tw-rounded-lg tw-overflow-auto !tw-h-0"
          ref={section2Ref}
        >
          <Heading size="h3" text="Site Status" />

          <div className="tw-mt-4 tw-relative">
            <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-h-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
              <span>Site</span>
              <span className="tw-pr-[14px]">Status</span>
            </div>
            <div className="tw-overflow-y-auto">
              {crawlers.map((crawler, index) => {
                return (
                  <div
                    className="tw-w-full tw-flex tw-flex-row lg:tw-flex-col min-[1366px]:tw-flex-row tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                    key={index}
                  >
                    <span className="tw-text-[#5B5D63] tw-text-sm">{crawler.forum_name}</span>
                    <span
                      className={
                        crawler.status === 'FAILED'
                          ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                          : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                      }
                    >
                      {crawler.status}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataPastes;
